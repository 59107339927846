@media print {
    html {
        font-size: 10px !important;
    }

    nav,
    .site-functions,
    .content-header,
    footer {
        display: none !important;
    }

    header,
    .main-elements {
        background: none !important;
    }
}