.wrapper {
    position: relative;
}

.container {
    width: calc(100vw - 10%);
    margin: 0 5%;
    position: relative;

    @include breakpoint(max) {
        // width: 130rem;
        // margin: 0 auto;
    }
}

// Main container
.main-container {
    transition-property: padding, opacity, margin;
    transition-duration: $trans-m, $trans-l, $trans-l;
    transition-timing-function: $trans-in;
    z-index: map-get($z-index, main-content);
    position: relative;

    // Search is active
    .has-search & {
        padding-top: 8rem;
        transition-duration: $trans-m;
    }

    .has-login-box &,
    .has-mini-cart &,
    .is-loading-ajax &,
    .has-nav-main & {
        //filter: blur(2px);
    }
}