button,
.button,
.btn,
input[type="submit"],
input[type="button"] {
    position: relative;
    display: inline-block;
    background: none;
    padding: 0.6rem 1.4rem;
    line-height: 2rem;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    border-radius: $border-radius;
    letter-spacing: inherit;
    background: $back-highlight;
    border: $border-width solid $back-highlight;
    transition: background $trans-m $trans-in,
                border $trans-m $trans-in;

    &.btn--naked {
        border: 0;
    }

    &.btn--link {
        text-transform: none;
        padding: 0;
        border: 0;
        color: $text-default;
        background: transparent;
        @include type-bold;

        .teaser & {
            color: $text-inverted;
            border-color: $text-inverted;
        }
    }

    &.btn--clean {
        border: none;
        background: none;
        padding: 0;
    }

    &.btn--inverted {
        background: $back-highlight-dark;
        border: $border-width solid transparent;

        .modal-content &,
        .cookie-consent & {
            &:not(.btn--secondary) {
                background: $back-light;
            }
        }
    }

    &.btn--secondary {
        background: transparent;
        border: $border-width solid $back-highlight-3;
        color: $text-default;

        &.btn--inverted {
            border: $border-width solid $text-default;
        }
    }

    &.btn--small {
        font-size: $text-c;
        padding: 0.2rem 1.4rem;
        line-height: 1.6rem;
    }

    &.btn--directions {
        background: url('../images/icons/specs-locality.svg') top center no-repeat;
        background-size: 2.8rem 2.8rem;
        padding-top: 3rem;
    }

    &.btn--full-width {
        width: 100%;
    }

    &.btn--medium {
        padding: 1.1rem 1.2rem;
    }

    &.btn--large {
        padding: 1.5rem;
    }

    &.btn--huge {
        padding: 2rem;
    }

    &.btn--underlined {
        border: 0;
        border-bottom: solid 1px $text-default;
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
        background: none;
        text-transform: inherit;
        padding: 0;
        border-radius: 0;
        line-height: 1.2;
    }

    &.btn--rounded {
        border-radius: $border-width;
    }
}

.btn--large {
    padding: 1rem 1.4rem;
}

// button wrapper
.button-wrapper {
    padding-top: 3rem;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
}
