.site-functions {
    position: absolute;
    top: 50%;
    right: -1rem;
    margin-top: -1rem;
    z-index: map-get($z-index, site-functions);

    ul {
        @extend %pseudo-list;
        margin-top: -0.5rem;
    }

    li {
        padding: 0 1rem;
        cursor: pointer;
    }
}
