.product-image {
    position: relative;

    // Container
    &.product-image--container {
        width: 100%;
        // overflow: hidden;
        padding-bottom: 60%;
    }

    // Lazy + Preview
    &.product-image--preview,
    &.product-image--lazy {
        position: absolute;
        width: 110%;
        height: 110%;
        top: -5%;
        left: -5%;
        overflow: hidden;

        .no-touch .product-overview a &,
        .no-touch .product-list--slider a & {
            transition-property: transform, opacity, margin;
            transition-duration: $trans-l;
            transition-timing-function: $trans-out;
            width: 120%;
            height: 120%;
            top: -10%;
            left: -10%;
            transform: scale(0.91);
        }

        .no-touch .product-overview a:hover & {
            transform: scale(1) translateY(-1%);
        }

        .no-touch .product-list--slider a:hover & {
            transform: scale(1.1) translateY(-1.5%);
        }
    }

    // Lazy load image
    &.product-image--lazy {
        transition-property: transform, opacity;
        transition-duration: $trans-m;
        transition-timing-function: $trans-in;
        opacity: 0;

        &.product-image--loaded {
            opacity: 1;

            // prevent hard image borders due to slight color differences -> Safari
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                pointer-events: none;
                box-shadow: inset 0 0 2rem 1rem $back-light;
            }
        }
    }

    // Set image to fit box
    img {
        width: 100%;
        position: relative;
    }

    &.product-image--empty {
        .ico {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            overflow: hidden;
        }
    }
}