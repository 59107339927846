.content-header {
    background: $back-light;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition-property: height;
        transition-duration: $trans-l;
        transition-timing-function: $trans-in;
        background: $back-default;

        .is-loading-ajax:not(.has-filter) & {
            height: 90%;
        }
    }
}

.main-container .container {
    transition-property: margin;
    transition-duration: $trans-l;
    transition-timing-function: $trans-in;

    .is-loading-ajax:not(.has-filter) & {
        margin-top: 4rem;
        transition-duration: $trans-m;
    }

    .has-filter.is-loading-ajax & {
        pointer-events: none;
    }

}

.loading-overlay {
    transition-property: top, opacity, visibility;
    transition-duration: $trans-m, $trans-m, 0s;
    transition-timing-function: $trans-in;
    transition-delay: 0s, 0s, $trans-m;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $back-light;
    z-index: map-get($z-index, loading-overlay);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    @include breakpoint(mobile) {
        top: 5rem;
    }

    @include breakpoint(tablet) {
        top: 7rem;

        .has-scrolled & {
            top: 5rem;
        }
    }

    // Content is loading
    .is-loading-ajax:not(.has-filter) &,
    .is-loading-ajax-slim & {
        transition-property: top, opacity;
        transition-delay: 0s;
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        cursor: default;

        .loading-animation {
            transition-delay: 3s;
            opacity: 1;

            span {
                animation-play-state: running;
            }
        }
    }

    .is-loading-ajax-slim & {
        opacity: 0.8;
    }

    .loading-animation {
        position: absolute;
        top: 40%;
        left: 50%;
        margin: 0 0 0 -12.5rem;
        opacity: 0;
        transform: scale(0.5);
    }
}

.loading-animation {
    display: inline-block;
    width: 25rem;
    height: 6rem;
    transition-property: opacity;
    transition-duration: $trans-m;
    transition-timing-function: linear;
    transition-delay: 0s;
    word-spacing: -0.25rem;

    span {
        font-size: $head-a;
        @include type-bold;
        position: relative;
        display: inline-block;
        animation-name: fade-pulse;
        animation-duration: 1.4s;

        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        animation-play-state: paused;

        @include animation-delay(0.05s, 0s, 12);
    }

    .loading-animation__letter--light {
        @include type-regular;
    }
}
