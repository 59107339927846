.teaser {
    position: relative;

    button {
        color: $text-inverted;
        border: 2px solid #fff;
        position: absolute;
        bottom: 8rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: map-get($z-index, teaser-button);
        display: block;
        white-space: nowrap;
        text-shadow: 0 0 5em rgba(#090e14, 0.5);
    }

    .img-box {

        img {
            transition-property: transform;
            transition-duration: $trans-m;
            transition-timing-function: $trans-in;
        }
    }

    .no-touch & {
        &:hover {
            .img-box {
                img {
                    transform: translateY(-50%) scale(1.04);
                }
            }
        }
    }

    @include breakpoint(min-tablet) {
        .img-box--port-m {
            button {
                bottom: 14rem;
            }
        }
    }

}
