.filter {
    min-height: 100%;
    background: transparent;
    transition: margin $trans-m $trans-in,
                transform $trans-m $trans-in;
    padding-bottom: 0;
    overflow: visible;
    pointer-events: none; // make over clickable

    .search--no-results & {
        display: none;
    }

    .filter-wrapper {
        @extend %top-line;
        background: $back-light;
        transition: background-color $trans-m $trans-in,
                    margin $trans-m $trans-in,;
        height: 75vh;
        position: relative;
        pointer-events: all;
        padding-bottom: 4rem;
        overflow: hidden;
        margin-top: -6rem;

        .has-filter & {
            overflow-y: auto;
            overflow-x: hidden;
        }

        .is-loading-ajax & {
            cursor: default;

            * {
                pointer-events: none;
            }

        }
    }

    .filter-container-inner {
        position: relative;
        margin-top: 2.2rem;
        margin-bottom: 2rem;
        display: flex;
        justify-content: space-around;
        flex-direction: row-reverse;
        width: 100%;
        z-index: 1;

        @include breakpoint(mobile) {
            flex-wrap: wrap;
            flex-direction: column-reverse;
        }
    }

    ul {

        li li {
            position: relative;

            @include breakpoint(mobile) {
                line-height: 1.5;
            }
        }

        a,
        label,
        .not-available {
            position: relative;
            display: block;
            text-align: center;
        }

        a,
        label {
            cursor: pointer;
        }

        .not-available {
            color: $text-greyed;
        }
    }

    h3 {
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 1.6rem;
    }

    .selected-filter {
        max-height: 4.6rem;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 1.8rem;
        transform: translateX(-50%);
        overflow: hidden;
        width: 40%;

        @include breakpoint(mobile) {
            width: 70%;
            transform: translateX(-30%);
        }

        a,
        li,
        .btn {
            display: inline-block;
        }

        a,
        .btn {
            padding: 0 1.5rem;
            text-transform: none;
        }
    }

    &:after {
        content: "Close filter";
        position: absolute;
        top: 12%;
        left: 0;
        width: 100%;
        transform: translate3d(0, -50%, 0);
        text-align: center;
        opacity: 0;
        transition-property: opacity;
        transition-duration: $trans-s;
        transition-timing-function: $trans-in;
        letter-spacing: 0.15rem;

        .has-active-filter & {
            content: 'Show results';

            html:lang(de) & {
                content: 'Ergebnisse zeigen';
            }
        }

        html:lang(de) & {
            content: 'Filter schließen';
        }

        .is-loading-ajax & {
            content: 'Updating …';

            html:lang(de) & {
                content: 'Lädt …';
            }
        }

    }

    .has-scrolled & {
        .filter-wrapper {
            background: $back-default;
        }

    }

    .has-filter & {
        transform: translate3d(0, 0, 0);

        .filter-wrapper {
            background: $back-default;
            margin-top: 25vh;
        }

        &:after {
            opacity: 1;
            transition-delay: $trans-m;
            transition-duration: $trans-l;
        }
    }

    .has-footer-inview & {
        .filter-wrapper {
            margin-top: 0;
        }
    }

}

body.has-filter {
    overflow-y: hidden;

    header,
    .content,
    footer {
        transform: translate3d(0, -12rem, 0);
    }
}

#side-cover-filter {
    z-index: map-get($z-index, side-cover);

    .has-filter & {
        opacity: 0.8;
        pointer-events: all;
        cursor: pointer;
    }

    .is-loading-ajax.has-filter & {
        opacity: 0.96;
    }

}

button.filter-head {
    padding: 1.8rem 0;
    width: 33%;
    transform: translate3d(100%, 0, 0);
    transition-property: transform;
    transition-duration: $trans-m;
    transition-timing-function: $trans-in;

    .has-filter &,
    .has-active-filter & {
        transform: translate3d(0, 0, 0);
    }

    span {
        transition-property: opacity;
        transition-duration: $trans-m;
        transition-timing-function: $trans-in;
    }

    .filter-btn {
        display: flex;
        justify-content: center;

        svg {
            width: 1.6rem;
            height: 1.6rem;
            margin-right: 0.2rem;
            transform-origin: center center;
        }

        body:not(.has-scrolled):not(.has-filter) .wrapper:not(.has-active-filter) & {
            opacity: 0;
        }

        .has-scrolled & {
            transition-delay: 1.5s;
            opacity: 1;
        }

        .has-scrolled .wrapper:not(.has-active-filter) & {
            animation: fade-in-filter 2.4s 1.5s ease-in-out both;
        }

        .has-filter & {
            transition-duration: 0s;
            transition-delay: 0s;

            .svg-ico-plus {
                transform: rotate(45deg);
            }
        }
    }

    .filter-btn-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 200%;
        word-spacing: 0;
        text-transform: none;
        transition-delay: 0.5s;

        .has-scrolled & {
            transition-delay: 1s;
            opacity: 0;
        }

        .has-active-filter & {
            opacity: 0;
        }

        .has-filter & {
            transition-duration: 0s;
            transition-delay: 0s;
            opacity: 0;
        }
    }
}

a.btn.remove-all {

    .has-active-filter & {
        display: block;
        @include breakpoint(mobile) {
            display: none;
        }
    }

    padding: 1.8rem 0;
    width: 33%;
    position: absolute;
    display: none;
    top: 0;
    left: 66%;
}

.more-brands {
    text-align: center;
    display: block;
    padding: 5vh 0;
    width: 100%;
}

.filter-panel--active {
    position: absolute;
    top: 2.5rem;
    left: 50%;
    transform: translate3d(-50%, -40%, 0);
    width: 50%;

    h3 {
        display: none;
    }

    ul {
        max-height: 4.8rem;
        overflow: hidden;
        text-align: center;
    }

    li {
        display: inline-block;
        margin: 0 2rem;
        line-height: 1.6;
    }

    @include breakpoint(mobile) {
        width: 66%;
        left: 66%;

        li {
            margin: 0 1rem;
        }
    }
}

.chosen {
    .btn:after,
    label:after {
        content: "×";
        display: inline-block;
        padding: 0 0.5rem;
        margin-right: -2rem;
        font-size: $text-b;
        position: relative;

        .facet--manufacturer &,
        .facet--brand & {
            font-size: 0.6em;
            transform: translate3d(0, -0.2rem, 0);
        }

        .facet--Style & {
            transform: translate3d(0, -0.1rem, 0);
        }

        .filter-panel--active & {
            font-size: 0.8em;
            transform: translate3d(0, -0.1rem, 0);
        }
    }
}

.count {
    display: none;
}

.filter-panel {
    margin-bottom: 2rem;
}

.facet--manufacturer,
.facet--brand {
    width: 59%;

    h3 {
        width: 25%;
    }

    ul {
        column-count: 2;
    }
}

.facet--style {
    width: 20%;

    h3 {
        width: 50%;
    }
}

// General filter property
.filter--property {
    position: relative;

    @include breakpoint(mobile) {
        width: 100%;

        h3 {
            width: 100%;
            text-align: left;
        }

        ul {
            column-count: 2;
        }
    }
}

// hide unisex filter
.facet--style {
    ul li:nth-child(3) {
        display: none;
    }
}

.filter-close {
    @extend %top-line;
    position: fixed;
    bottom: 0;
    left: 5%;
    padding: 1.6rem 0;
    width: 90%;
    background: $back-default;
    text-align: center;
    z-index: map-get($z-index, action-panel);
    cursor: pointer;

    transition-property: transform;
    transition-duration: $trans-m;
    transition-timing-function: $trans-in;
    transform: translate3d(0, 200%, 0);

    @include breakpoint(mobile) {
        display: none;
    }

    .has-filter & {
        transform: translate3d(0, 0, 0);
    }
}

// Shopware specific filter stuff
.filter {
    .facet--price,
    .facet--immediate_delivery {
        display: none;
    }
    .filter-panel--checkbox {
        display: none;
    }

    #filter button[type="submit"] {
        display: none;
    }
}
