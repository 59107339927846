.checkout-container--confirm {
    padding-bottom: 6rem;

    // Cart summary
    .confirm-cart-summary {
        .cart-breakdown {
            margin-bottom: 0;
        }

        .cart-footer {
            padding-bottom: 1rem;
        }
    }

    .terms-confirmation {
        padding-bottom: 4rem;
    }

    // Proceed to payment -> "Kostenpflichtig bestellen"
    .main-action {
        width: 100%;
        padding-bottom: 6rem;
    }
}