// keyframes mixin
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@include keyframes(fade-in) {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@include keyframes(fade-out) {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@include keyframes(fade-in-filter) {
    0%, 42%, 82% {
        opacity: 0;
    }

    20%, 60%, 100% {
        opacity: 1;
    }
}

@include keyframes(spin) {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(359deg);
	}
}

@include keyframes(load-pulse) {
    0%, 100% {
        transform: scale(0.7);
    }

    50% {
          transform: scale(1);
    }
}

@include keyframes(fade-pulse) {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}
