.accordion {
    display: block;

    .accordion__body {
        overflow: hidden;
        width: 100%;
    }

    .accordion__body__inner {
        display: block;
        width: 100%;
        padding-top: 2rem;
        padding-bottom: 4rem;
    }

    ul {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        li {
            display: block;
            font-size: $navi-b;

            @include breakpoint(min-tablet) {
                font-size: $text-a;
            }
        }

        &:not(:first-child) {
            margin-top: 2rem;
        }
    }

    // Expanded states
    &[aria-expanded="false"] {
        .accordion__body {
            max-height: 0;
            visibility: hidden;
        }
    }

    &[aria-expanded="true"] {
        .accordion__body {
            max-height: none;
        }
    }
}