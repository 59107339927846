// boxes
.box-container {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    @include breakpoint(mobile-small) {
        display: block;
    }
}

// wrapper for boxes
.box-container--m {
    width: 100%;

    @include breakpoint(tablet) {
        width: 83.333%; // 10 of 12 columns
    }

    @include breakpoint(desktop) {
        width: 83.333%; // 10 of 12 columns
    }

    @include breakpoint(desktop-large) {
        width: 60%; // 12 of 20 columns
    }

    @include breakpoint(max) {
        width: 60%; // 12 of 20 columns
    }
}

.box-container--l {
    width: 100%;

    @include breakpoint(tablet) {
        width: 83.333%; // 10 of 12 columns
    }

    @include breakpoint(desktop) {
        width: 83.333%; // 10 of 12 columns
    }

    @include breakpoint(desktop-large) {
        width: 70%; // 14 of 20 columns
    }

    @include breakpoint(max) {
        width: 70%; // 14 of 20 columns
    }
}

.box-container--centered {
    margin: 0 auto;

    h2, h3, h4, p, li {
        text-align: center;
    }

    .product-overview-hero & {
        display: block;
    }
}

.box-container--col-2 {
    justify-content: space-between;

    .box {
        width: 50%;

        @include breakpoint(mobile-small) {
            width: 100%;
        }
    }
}

// colums
.col-wrapper {
    display: flex;
    justify-content: space-around; // space-between ?
    flex-flow: row wrap;

    > * {
        // background: rgba(black, 0.01);
    }

    img {
        width: 100%;
        height: auto;
    }
}

.col-30 {
    width: 33%;
    padding: 0 1rem;

    @include breakpoint(mobile) {
        width: 100%;
        padding: 0;
    }
}

.col-60 {
    width: 66%;
    padding: 0 1rem;

    @include breakpoint(mobile) {
        width: 100%;
        padding: 0;
    }

    &.line-row {
        margin: 0 1rem;
        width: calc(66% - 2rem);

        @include breakpoint(mobile) {
            margin: 0;
            width: 100%;
        }
    }
}

.col-100 {
    width: 100%
}
