// modal

.modal {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 42rem;
    background: $back-highlight;
    transform: translate3d(50%,0,0);
    transition-property: transform, opacity, visibility;
    transition-duration: $trans-m, $trans-s, 0s;
    transition-timing-function: $trans-in, $trans-in, linear;
    transition-delay: 0s, $trans-s, $trans-l;
    z-index: map-get($z-index, modal);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    a {
        color: inherit;
    }

    .modal-inner {
        transform: translate3d(5%,0,0);
        opacity: 0;
        transition-property: transform, opacity;
        transition-duration: $trans-m;
        transition-delay: 0s;
        min-height: 100%;
    }

    .modal-header {
        @extend %top-fade;
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 2rem); // show scrollbar of body-wrapper
        min-height: 6rem;
        padding: 2.6rem 5rem 0 7rem;
        background: $back-highlight;
        z-index: map-get($z-index, modal-header);

        ul {
            display: block;
            width: 100%;
            height: 3.4rem;
            margin-top: -0.5rem;
            position: relative;
        }

        li {
            cursor: pointer;
            padding: 0 1rem;
            float: left;
        }

        .modal-title {
            position: relative;
            padding: 0 3rem 0 0;
            width: 100%;
            height: 100%;

            h2 {
                font-size: $head-c;
                display: block;
                margin-bottom: 0;
            }
        }

        .close-modal {
            position: absolute;
            top: 0;
            right: 0;
            padding: 0;
        }

        @include breakpoint(mobile-small) {
            .item-count {
                display: none;
            }

            li {
                padding: 0 0.6rem;
            }
        }
    }

    .modal-content {
        display: flex;
        flex-direction: column;
        overflow: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .modal-body {
        padding: 8rem 7rem 2rem 7rem;
        flex: 1 0 auto;
    }

    .modal-footer {
        flex: 0 0 auto;
        position: sticky;
        bottom: 0;
        width: calc(100% - 2rem);
        background: $back-highlight;
        padding: 3rem 5rem 4rem 7rem;

        @extend %bottom-fade;
    }

    @include breakpoint(mobile) {
        width: 85%;

        .modal-header {
            width: calc(100% - 1rem);
            padding: 1.4rem 1rem 0 2rem;
            min-height: 4rem;
        }

        .modal-body {
            padding: 6rem 2rem 2rem 2rem;
        }

        .modal-footer {
            position: relative;
            bottom: auto;
            padding: 2rem 0 2rem 2rem;
        }

    }

    @include breakpoint(tablet) {
        width: 38rem;

        .modal-header {
            width: calc(100% - 2rem);
            padding: 1.9rem 2rem 0 4rem;
        }

        .modal-body {
            padding: 8rem 4rem 2rem 4rem;
        }

        .modal-footer {
            width: calc(100% - 2rem);
            padding: 3rem 2rem 3rem 4rem;
        }
    }

    &.modal-large {
        width: 65%;

        .modal-header {
            padding: 2.6rem calc(6.5vw - 2rem) 0 0;
        }

        .modal-inner {
            margin: 0 0 0 8rem;
        }

        .modal-content {
            padding-right: 6.5vw;
            padding-bottom: 6rem;
        }

        @include breakpoint(mobile) {
            width: 85%;

            .modal-header {
                padding-right: calc(5.2vw - 1rem);
                padding-top: 1.4rem;
            }

            .modal-inner {
                margin: 0 0 0 3rem;
            }

            .modal-content {
                padding-right: 5.2vw;
                padding-bottom: 3rem;
            }
        }

        @include breakpoint(tablet) {
            width: 80%;

            .modal-header {
                padding-right: calc(5.96vw - 2rem);
                padding-top: 1.9rem;
            }

            .modal-inner {
                margin: 0 0 0 6rem;
            }

            .modal-content {
                padding-right: 5.96vw;
                padding-bottom: 5rem;
            }
        }

        @include breakpoint(desktop-large) {
            width: 50%;
        }

        @include breakpoint(max) {
            width: 50%;
        }
    }
}

// show modals
%show-modal {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0,0,0);
    transition-delay: 0s, 0s, 0s;
    pointer-events: all;

    .modal-inner {
        transform: translate3d(0,0,0);
        opacity: 1;
        transition-delay: $trans-s;
    }
}

.nav-main {
    .show-nav-main &,
    .has-nav-main & {
        @extend %show-modal;
    }
}

.login-box {
    .show-login-box &,
    .has-login-box & {
        @extend %show-modal;
    }
}

.mini-cart {
    .show-mini-cart &,
    .has-mini-cart & {
        @extend %show-modal;
    }
}

.modal--localisation {
    h3 {
        line-height: 2.8rem;
    }

    .button-wrapper .btn {
        width: 100%;
    }

    .modal-body {
        transition: opacity $trans-m $trans-in;
        opacity: 1;
    }

    &[aria-busy="true"],
    form[data-state="loading"] {
        .modal-body {
            opacity: 0.2;
        }
    }

    .modal-footer {
        .button-wrapper {
            padding-top: 0;
        }
    }

    @include breakpoint(mobile) {
        .modal-footer {
            @include bottom-fade;
            position: sticky;
            bottom: 0;
        }
    }

    .has-localisation & {
        @extend %show-modal;
    }
}

.modal--frame-notification {
    h3 {
        line-height: 2.8rem;
    }

    .has-frame-notification & {
        @extend %show-modal;
    }
}

.side-cover {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 100vh;
    background: $back-highlight-4;
    z-index: map-get($z-index, side-cover);
    opacity: 0;
    transition-property: opacity;
    transition-duration: $trans-m;
    transition-timing-function: $trans-in;
    pointer-events: none;
}

#side-cover {
    .show-login-box &,
    .has-login-box &,
    .show-mini-cart &,
    .has-mini-cart &,
    .show-nav-main &,
    .has-localisation &,
    .has-frame-notification &,
    .has-nav-main & {
        opacity: 0.8;
        pointer-events: all;
        cursor: pointer;
    }
}

// Disable scrolling, when a modal is opened
.has-login-box,
.has-mini-cart,
.has-nav-main,
.has-localisation,
.has-frame-notification {
    overflow: hidden;
}
