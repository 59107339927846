.product-label {
    position: absolute;
    display: inline-block;
    top: 4rem;
    right: 3rem;
    padding: 0.8rem 1.4rem;
    border-radius: $border-width;
    @include type-bold;
    font-size: 1.2rem;
    font-weight: 100;
    font-variant: normal;
    transition: transform $trans-m $trans-out, opacity $trans-m $trans-out;
    transform: translate3d(0, 0, 0);
    opacity: 1;

    &.product-label--limited,
    &.product-label--must-try,
    &.product-label--on-sale {
        background-color: $back-highlight;
    }

    &.product-label--clip-on {
        background-color: $back-highlight-3;
    }

    .no-touch a:hover & {
        opacity: 0;
        transform: translate3d(0.2rem, -0.6rem, 0);
        transition: transform $trans-s $trans-out,
                    opacity $trans-s $trans-out;
    }

    @include breakpoint(mobile-small) {
        top: 4rem;
        right: 2rem;
    }

    @include breakpoint(mobile-large) {
        top: 2rem;
        right: 1rem;
        font-size: 1.1rem;
    }

    @include breakpoint(tablet) {
        top: 3.6rem;
        right: 3rem;
    }

    @include breakpoint(desktop-large) {
        top: 5.6rem;
        right: 5rem;
    }

    @include breakpoint(max) {
        top: 5rem;
        right: 5rem;
    }

    // Label is inside of a slider
    .product-list--slider & {
        top: 3.6rem;
        right: 2.5rem;
    }
}
