.ico {

    position: relative;

    svg {
        width: 2.8rem;
        height: 2.8rem;
        margin-right: 0.5rem;
        fill: $text-default;
        vertical-align: top;
    }

    span, a {
        display: inline-block;
        line-height: 2.8rem;
        vertical-align: top;
    }

    &.ico--naked {
        svg {
            margin-right: 0;
        }

        span:not(.item-count) {
            text-indent: -999rem;
        }
    }

    &.ico--left-label {
        svg {
            margin-right: 0;
            margin-left: 0.2rem;
        }
    }

    &.ico--dismiss {
        svg {
            width: 1.8rem;
            height: 1.8rem;
        }
    }

    &.close-modal {
        span {
            text-indent: -999rem;
        }
    }

    .logo & {
        svg {
            width: 11.9rem; // 170x30px native
            height: 2.1rem;
        }

        span {
            width: 0;
            overflow: hidden;
            display: inline-block;
            text-indent: -999rem;
        }
    }
}

// Special icon styling
.svg-ico-payment-sofort {
    fill: #eb6f93;
}
