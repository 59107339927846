.header-search {
    transition-property: transform, opacity;
    transition-duration: $trans-m, $trans-s;
    transition-timing-function: $trans-in;
    transition-delay: 0s, $trans-s;
    transform: translate3d(0, 0, 0);

    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    height: 7rem;

    // Search input field
    input[type="search"] {
        position: absolute;
        top: 0;
        left: 0;
        border-top: none;
        border-left: none;
        border-right: none;
        border-color: $line-med;
        border-radius: 0;
        background: $back-default;
        padding-left: 0;
        padding-right: 4rem;
        padding-bottom: 0.5rem;
        z-index: 2;
        width: calc(100% - 4rem);
    }

    .btn[type="submit"] {
        display: none;
    }

    // Button to dismiss the search
    .btn[data-action="dismiss-search"] {
        position: absolute;
        right: 0;
        top: 0;
        height: 4rem;
        width: 4rem;
        padding: 0;
        z-index: 3;

        .svg-ico {
            position: absolute;
            top: 0.6rem;
            left: 1rem;
        }

        span {
            display: inline-block;
            text-indent: -999rem;
        }
    }

    // Hint beaneath search field
    .search-hint {
        position: absolute;
        top: 0.5rem;
        left: 0;
        transition-property: transform, opacity;
        transition-duration: $trans-m, $trans-s;
        transition-timing-function: $trans-in;
        transition-delay: 0s, $trans-s;
        transform: translate3d(0, 0, 0);
        opacity: 0;
        color: $text-greyed;
        z-index: 1;

        kbd {
            font-family: inherit;
            font-size: inherit;
            font-style: inherit;
            border-bottom: solid 1px $text-greyed;
        }
    }

    // Search hint should be shown
    form.has-search-hint .search-hint {
        opacity: 1;
        transform: translate3d(0, 4rem, 0);
    }

    // Search is active
    .has-search & {
        opacity: 1;
        transform: translate3d(0, 8rem, 0);
        pointer-events: all;

        @include breakpoint(tablet) {
            transform: translate3d(0, 7rem, 0);
        }

        @include breakpoint(mobile) {
            transform: translate3d(0, 5rem, 0);
        }
    }

    .has-search.has-scrolled & {
        transform: translate3d(0, 5rem, 0);

        @include breakpoint(tablet) {
            transform: translate3d(0, 5rem, 0);
        }

        @include breakpoint(mobile) {
            transform: translate3d(0, 5rem, 0);
        }
    }
}
