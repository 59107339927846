.cookie-consent {
    position: fixed;
    right: 4rem;
    bottom: 4rem;
    width: 50rem;
    padding: 2rem 3rem;
    background-color: $back-highlight-5;
    z-index: map-get($z-index, cookie-hint);
    transition: transform $trans-in $trans-m,
                opacity $trans-in $trans-m;

    &[aria-hidden="true"] {
        transform: translate3d(0, 2rem, 0);
        opacity: 0;
        pointer-events: none;
    }

    @include breakpoint(mobile) {
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        padding: 1.5rem 2rem 0 2rem;
        max-height: 60vh;
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
        overscroll-behavior: contain;
    }
}

.cookie-consent__intro {
    h3 {
        font-size: $text-b;
    }

    p {
        font-size: $text-c;
        margin-top: 1rem;
    }
}

.cookie-consent__settings {
    margin-top: 3rem;

    ul {
        display: flex;
        flex-direction: column;
    }

    li + li {
        margin-top: 1rem;
    }
}

.cookie-consent__footer {
    margin-top: 3rem;

    ul {
        display: flex;
        justify-content: flex-start;
    }

    @include breakpoint(min-tablet) {
        li + li {
            margin-left: 1.5rem;
        }
    }

    @include breakpoint(mobile) {
        position: sticky;
        bottom: 0;
        background: $back-highlight-5;
        padding: 2rem 0 1.5rem 0;
        @include bottom-fade($back-highlight-5);

        ul {
            flex-direction: column-reverse;
        }

        li:first-child {
            margin-top: 1rem;
        }

        .btn {
            width: 100%;
        }
    }
}