// User account
.account-container {
    padding-bottom: 6rem;
}

// Change address
.address-container {
    padding-bottom: 6rem;
}

// OLD stuff?
.my-account {
    .my-account-wrapper {
        display: flex;
        flex-direction: row;

        h3 {
            margin-bottom: 0.6rem;
        }

        .my-info {
            flex: 1 1;
            text-align: center;

            p {
                margin-bottom: 3rem;
            }
        }
        .my-address {
            flex: 1 1;
            text-align: center;

        }
    }

    > a.btn {
        width: 100%;
        line-height: 5rem;
    }

    a {
        border-bottom: 1px solid $text-default;
        margin-bottom: 3rem;
        display: inline-block;
        font-size: $text-c;

        &.btn {
            text-decoration: none;
        }
    }
}

form.edit-account,
.edit-address form {

    .clear {
        display: none;
    }

    fieldset {
        margin-top: 3rem;
        border: none;
        padding: 0;

        legend {
            margin-bottom: 1rem;
        }
    }

    .edit-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .form-row {
            width: calc(50% - 1rem);
        }
    }

    input[type="submit"] {
        margin-top: 5rem;
        width: 100%;
        line-height: 5rem;
    }
}
