// projects overview

.product {
    width: 100%;

    @include breakpoint(medium) {
        width: 70%;
        margin-left: 25%;
    }

    @include breakpoint(large) {
        width: 60%;
        margin-left: 30%;
    }

    @include breakpoint(max) {
        margin-left: 25%;
    }

    @include breakpoint(one-row) {
        width: 100%;
        margin-left: 0;
    }
}

article.product {
    padding: 0 0 4rem 0;

    .product-image--container,
    .product-description {
        margin: 2rem 0;
    }
}

// Wrap product information on desktop
.product-info-wrapper {
    @include breakpoint(tablet-small-desktop) {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        top: calc(8rem + 6vh);
        min-height: calc(90vh - 8rem);
        left: 0;
        width: 18rem;
        z-index: 1;

        // Use fixed positioning
        .has-extended-height & {
            position: fixed;
            left: 5%;
        }
    }
}

.product-credentials {
    h1 {
        margin-bottom: 2rem;
        width: 140%;
        font-size: $head-c;
    }

    h2 {
        margin-bottom: 0;
        font-size: $head-c;
    }

    .product-price--regular {
        padding-right: 0.4rem;
        display: inline-block;
    }

    @include breakpoint(one-row) {
        position: relative;
        top: auto;
        left: auto;
        width: auto;
        display: flex;
        justify-content: space-between;

        .product-title {
            width: 49%;
        }

        .product-price {
            text-align: right;
            width: 49%;
        }

        h1 {
            margin-bottom: 1.4rem;
            width: auto;
            font-size: $text-a;
        }

        h2 {
            font-size: $text-a;
        }
    }

    @include breakpoint(tablet-small) {
        width: 80%;
        margin: 0 auto;
    }

    ins, del {
        text-decoration: none;
    }

    del .product-price--regular {
        text-decoration: line-through;
    }
}

.tax-notice {
    display: block;
    line-height: 1.5;

    a {
        color: inherit;
        border-bottom: 1px solid rgba($text-default, 0.5);
    }

    @include breakpoint(one-row) {
        margin-top: 1.4rem;
    }
}

.product-form {
    @include breakpoint(tablet-small-desktop) {
        margin-top: 4rem;
    }

    @include breakpoint(one-row) {
        position: relative;
        bottom: auto;
        left: auto;
        width: auto;
    }

    @include breakpoint(tablet-small) {
        width: 80%;
        margin: 0 auto;
    }

    label {
        padding: 0 0 0.4rem 0;
    }

    select {
        width: 100%;
        background: transparent url('../images/icons/specs-dropdown.svg') no-repeat right center;
        background-size: 1rem 1rem;
        padding-right: 2rem;

        &::-ms-expand {
            display: none;
        }
    }

    .product-color {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 2rem;
            width: 3rem;
            height: 3rem;
            background-image: linear-gradient(90deg, rgba($back-light, 0), $back-light);
            pointer-events: none;
        }

    }

    button,
    .notification {
        width: 100%;
        margin: 0 0 4rem 0;

        @include breakpoint(one-row) {
            margin-top: 0.2rem;
            padding: 1.4rem 1.4rem;
            margin-bottom: 3rem;
            width: 20rem;
        }

        @include breakpoint(mobile-small) {
            width: 100%;
            margin-top: 0;
        }
    }

    // TODO {
    & .form-loading button[type="submit"]{
        opacity: 0.5; // tmp
    }
    & .form-loading button[type="submit"]{
        -webkit-transition: opacity 0.5s; // tmp
    }
    // } TODO

    .add-to-cart {
        button {
            margin-bottom: 1.4rem;
        }

        .direct-delivery-hint {
            margin-bottom: 3rem;

            p {
                font-size: $text-c;
            }
        }
    }
}


form {
    .product-color {
        margin-bottom: 1.4rem;

        @include breakpoint(one-row) {
            width: 20rem;
            border-bottom: $border-width solid $text-default;
            padding-bottom: 0.5rem;

            .product-variation .product-type-variable & {
                margin-right: calc(100% - 20rem);
            }
        }

        @include breakpoint(mobile-small) {
            width: 100%;
            margin-bottom: 1rem;
            padding-top: 0.4rem;

            .product-variation article:not(.product-type-variable) & {
                border-bottom: none;

                label {
                    display: block;
                    border-bottom: $border-width solid $text-default;
                    padding-bottom: 0.6rem;
                    margin-bottom: 0.8rem;
                }
            }
        }
    }

    .product-variants {
        border-top: $border-width solid $text-default;
        margin-top: -0.4rem;
        margin-bottom: 1.6rem;

        label {
            margin-top: 1rem;
            margin-bottom: 0.4rem;
            display: block;
        }

        .product-variant-property {
            padding: 0.2rem 0.6rem;
            background: none;
            border: $border-width solid $line-med;
            color: darken($back-light, 30%);
            width: auto;
            margin-bottom: 0.4rem;
            margin-right: 0.4rem;
            display: inline-block;
        }

        .product-variant-property.is-active {
            border: $border-width solid $text-default;
            color: $text-default;
        }

        .product-variant-property.out-of-stock {
            position: relative;
            border: $border-width solid rgba($line-med, 0.5);
            color: rgba(darken($back-light, 30%), 0.5);
            cursor: default;

            &:after {
                content: "out of stock";
                position: absolute;
                top: 50%;
                left: 50%;
                padding: 0.2rem 1rem;
                white-space: nowrap;
                background: $back-default;
                font-size: 0.9rem;
                text-transform: none;
                letter-spacing: 0;
                color: $text-default;
                opacity: 0;
                transform: translate3d(-50%, -50%, 0);
                transition-property: transform, opacity;
                transition-duration: $trans-s;
                transition-timing-function: $trans-out;
                pointer-events: none;
                z-index: 1;

                html:lang(de) & {
                    content: "vergriffen";
                }
            }

            .no-touch & {
                &:hover:after {
                    transition-property: transform, opacity;
                    opacity: 1;
                    transform: rotate(-12deg) translate3d(-46%, -90%, 0);
                }
            }

        }

        @include breakpoint(one-row) {
            width: 20rem;
            border-top: none;
            margin-top: 0;

            label {
                margin-top: 0;
                padding-bottom: 0;
            }
        }

        @include breakpoint(mobile-small) {
            width: 100%;
        }

    }

    @include breakpoint(one-row) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
    }

    @include breakpoint(mobile-small) {
        display: block;
    }
}


.product-variant-property {
    display: none;
}

.product-variant-property.active {
    display: block;
}

.product-features {
    min-height: 6rem;

    label {
        display: none;
    }

    @include breakpoint(one-row) {
        margin-bottom: 4rem;
        min-height: 0;
        width: 100%;

        ul.list--bullet {
            display: flex;
            justify-content: center;
            list-style: none;
            flex-flow: row wrap;

            li {
                padding: 0.5rem 1rem;
                text-align: center;
                white-space: nowrap;

                &:before {
                    padding-right: 0.4rem;
                }
            }

        }
    }
}

.product-text,
.product .img-box.color {

    margin: 0 auto 4rem auto;
    text-align: center;

    @include breakpoint(medium) {
        width: 80%; // 6 of 10 columns
    }

    @include breakpoint(large) {
        width: 83.333%; // 10 of 12 columns
    }

    @include breakpoint(max) {
        width: 66.666%; // 8 of 12 columns
    }

    @include breakpoint(mobile) {
        padding: 0 8%;
    }
}


.product-description {
    position: relative;

    .product-image {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    @include breakpoint(mobile) {
        padding-bottom: 0;

        .product-image {
            position: relative;
            bottom: auto;
            left: auto;
            width: auto;
        }
    }

}

.product-specification {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;

    > div {
        width: 25%;
        text-align: center;
        padding: 0 1rem;
        margin-bottom: 2rem;

        @include breakpoint(mobile) {
            width: 45%;
        }
    }
}

.product-size {
    // Uncomment the following, for frame sizes
    //display: flex;
    //justify-content: center;
    //flex-flow: row wrap;

    display: block;
    padding: 0 10%;
    margin: 4rem 0;

    //  label {
    //      font-size: $text-d;
    //  }

    .size {
        display: none;
        //  width: 20%;
        //  text-align: center;
        //  padding: 0;
        //  margin-bottom: 2rem;
        //
        //  div {
        //      font-size: $text-c;
        //      word-spacing: -0.2rem;
        //  }
    }

    .frame-sketch-container {
        width: 100%;
        position: relative;
        transform: scale(0.8);

        .frame-sketch {
            display: block;
        }

        .frame-sketch-inner {
            position: relative;
        }

        .frame-sketch-svg {
            width: 100%;
            height: 100%;
        }

    }

    .frame-sketch-svg text {
        font-size: 20px;
        letter-spacing: 0.06em;
    }

    @include breakpoint(large) {
        .frame-sketch-container {
            transform: scale(0.7);
        }
    }

    @include breakpoint(min-tablet) {
        .frame-sketch-container {
            padding-bottom: 20%;
        }

        .frame-sketch {
            position: absolute;
            width: 45%;
            height: 100%;
        }

        .frame-sketch--left {
            top: 0;
            left: 0;
        }

        .frame-sketch--right {
            top: 0;
            right: 0;
        }
    }

    @include breakpoint(mobile) {
        margin-bottom: 2rem;

        .frame-sketch-container {
            padding: 0 20%;
            transform: scale(0.9);
        }

        .frame-sketch {
            padding-bottom: 20%;
        }

        .frame-sketch-inner {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    @include breakpoint(mobile-small) {
        padding: 0;
    }
}

.frame-sizes {
    width: 100%;
    padding: 0 10%;
    opacity: 0.8;
    margin-bottom: 1rem;

    svg {
        fill: $text-greyed;
        width: 100%;
        height: 8rem;
    }
}

.product-info {
    margin: 4rem 0 0 0;
    text-align: center;

    p {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        line-height: 1.6;
    }
}

.nav-product {

    ul {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        margin-bottom: 4rem;

        @include breakpoint(mobile-small) {
            flex-direction: column;
        }

        li {
            padding: 0.5rem;
            text-align: center;
        }
    }
}

.product-free-shipping {
    margin-top: 2rem;
    text-align: center;

    p {
        font-size: $text-c;
        margin: 0;
        padding-bottom: 1rem; // Text gets cropped otherwise
    }
}