@charset "UTF-8";
@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fade-in-filter {
  0%, 42%, 82% {
    opacity: 0; }
  20%, 60%, 100% {
    opacity: 1; } }

@keyframes fade-in-filter {
  0%, 42%, 82% {
    opacity: 0; }
  20%, 60%, 100% {
    opacity: 1; } }

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

@-webkit-keyframes load-pulse {
  0%, 100% {
    transform: scale(0.7); }
  50% {
    transform: scale(1); } }

@keyframes load-pulse {
  0%, 100% {
    transform: scale(0.7); }
  50% {
    transform: scale(1); } }

@-webkit-keyframes fade-pulse {
  0%, 100% {
    opacity: 1; }
  50% {
    opacity: 0; } }

@keyframes fade-pulse {
  0%, 100% {
    opacity: 1; }
  50% {
    opacity: 0; } }


.cf:before,
.form-grid label:before,
.form-grid .form-grid-label:before,
.nav-main .nav-main-third:before,
.mini-cart .mini-cart-listing-item:before,
.cf:after,
.form-grid label:after,
.form-grid .form-grid-label:after,
.nav-main .nav-main-third:after,
.mini-cart .mini-cart-listing-item:after {
  display: table;
  line-height: 0;
  content: ""; }


.cf:after,
.form-grid label:after,
.form-grid .form-grid-label:after,
.nav-main .nav-main-third:after,
.mini-cart .mini-cart-listing-item:after {
  clear: both; }

.nav-head > ul, .site-functions ul {
  display: flex;
  justify-content: center; }

.modal .modal-header:after, .country-selection-search:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2rem;
  margin-bottom: -2rem;
  background: linear-gradient(0deg, rgba(148, 75, 62, 0), #944b3e);
  pointer-events: none; }

.modal .modal-footer:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 2rem;
  margin-top: -2rem;
  background: linear-gradient(0deg, #944b3e, rgba(148, 75, 62, 0));
  pointer-events: none; }

.col--main hr:after, .filter .filter-wrapper:after, .filter-close:after {
  content: '';
  position: absolute;
  left: 4%;
  right: 4%;
  top: 0;
  height: 1px;
  background: linear-gradient(90deg, rgba(217, 210, 208, 0), #d9d2d0, rgba(217, 210, 208, 0)); }

*,
*:after,
*:before {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; }

a,
button,
input,
select,
textarea {
  outline: none; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

input[type=text]::-ms-clear,
input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0; }

.clear {
  clear: both; }

.xdebug-var-dump {
  font-size: 12px;
  font-family: Consolas, Lucida Console, monospace;
  line-height: 1.1;
  border: solid 1px #000;
  background-color: whitesmoke;
  border-radius: 10px;
  padding: 10px;
  overflow-x: scroll; }

/* Humans & Machines fontface */
@font-face {
  font-family: 'NeueHaas-Medium';
  src: url("fonts/NeueHaas-Medium.eot");
  src: url("fonts/NeueHaas-Medium.woff2") format("woff2"), url("fonts/NeueHaas-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'NeueHaas-Regular';
  src: url("fonts/NeueHaas-Regular.eot");
  src: url("fonts/NeueHaas-Regular.woff2") format("woff2"), url("fonts/NeueHaas-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

body {
  font-family: 'NeueHaas-Regular', Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  line-height: 1.3;
  color: #0d0d0d;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.06rem; }

a {
  color: inherit;
  text-decoration: none; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 100;
  font-style: normal;
  font-variant: normal;
  margin: 0; }

i {
  font-style: normal; }

button,
input,
textarea,
select {
  font-size: 1.5rem;
  font-family: 'NeueHaas-Regular', Helvetica, Arial, sans-serif;
  font-weight: 100; }

.teaser button, .teaser
.button, .teaser
.btn, .teaser
input[type="submit"], .teaser
input[type="button"], .teaser
.filter:after {
  font-size: 2.2rem; }

button.btn--small,
.button.btn--small,
.btn.btn--small,
input[type="submit"].btn--small,
input[type="button"].btn--small,
.filter:after.btn--small {
  font-size: 1.2rem; }

h1 {
  font-size: 3rem;
  line-height: 1.1; }

h3 {
  font-size: 1.5rem; }

h2 {
  font-size: 3rem;
  margin-bottom: 4rem; }
  .img-box h2,
  .text-box h2 {
    font-size: 2.2rem; }
  .stores h2 {
    font-size: 1.5rem; }
    @media (min-width: 1px) and (max-width: 439px) {
      .stores h2 {
        font-size: 1.2rem; } }
  .product-overview-hero h2 i {
    font-size: 1.5rem; }
  @media (min-width: 1px) and (max-width: 900px) {
    .product-overview-hero h2 {
      font-size: 1.5rem; }
      .product-overview-hero h2 i {
        font-size: 1.2rem; } }
  .login-box h2 {
    font-size: 1.5rem; }

h4 {
  font-size: 1.5rem; }

.col--main h2 {
  font-size: 2.2rem; }

.col--main.page em {
  font-size: 1.5rem; }

.col--main.magazine-post p {
  font-size: 1.8rem; }

.col--main.content--cart h3, .col--main.content--checkout h3, .col--main.registration-container h3, .col--main.payment-shipping-container h3 {
  letter-spacing: 0.06rem;
  font-style: normal;
  font-size: 1.5rem; }

strong, th {
  font-weight: 100; }

::placeholder {
  font-size: 1.5rem; }

::-webkit-input-placeholder {
  font-size: 1.5rem; }

:-moz-placeholder {
  font-size: 1.5rem; }

::-moz-placeholder {
  font-size: 1.5rem; }

:-ms-input-placeholder {
  font-size: 1.5rem; }

.text--b {
  font-size: 1.5rem; }

.text--a {
  font-size: 2.2rem; }
  @media (min-width: 1px) and (max-width: 767px) {
    .text--a {
      font-size: 1.5rem; }
      .mini-contact .text--a {
        font-size: 2.2rem; } }

.text--a2 {
  font-size: 1.8rem; }
  @media (min-width: 1px) and (max-width: 900px) {
    .text--a2 {
      font-size: 2.2rem; } }

.text--c {
  font-size: 1.2rem; }

.opening-hours div {
  font-size: 2.2rem; }
  @media (min-width: 1px) and (max-width: 439px) {
    .opening-hours div {
      font-size: 1.5rem; } }

input[type="checkbox"] + label {
  font-size: 1.5rem; }

.text--c,
.flag,
label {
  font-size: 1.2rem; }

.notice {
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.6; }

ul.main {
  font-size: 4rem; }
  @media (min-width: 1px) and (max-width: 767px) {
    ul.main {
      font-size: 2.6rem; } }

del .product-price--regular {
  font-size: 1.2rem; }

.tax-notice {
  font-size: 1.2rem; }

.cart-quanity,
.cart-color,
.cart-size,
.cart-price,
.cart-remove,
.cart-quantity input {
  font-size: 1.2rem; }

.checkout-breakdown .line-row > :not(.amount),
.cart-totals .line-row > :not(.amount) {
  font-size: 1.2rem; }

.cart-totals .order-total .amount,
.checkout-order-total .amount {
  font-size: 1.5rem; }

.terms input[type="checkbox"] + label {
  font-size: 1.2rem; }

.filter-panel--style a,
.filter-panel--style label,
.filter-panel--style .not-available,
.filter-panel--color a,
.filter-panel--color label,
.filter-panel--color .not-available {
  font-size: 2.2rem; }

.brand-overview-list a,
.brand-overview-list label,
.brand-overview-list h3,
.brand-overview-list .not-available,
.filter-panel a,
.filter-panel label,
.filter-panel h3,
.filter-panel .not-available {
  font-size: 3rem;
  font-family: 'NeueHaas-Medium', Helvetica, Arial, sans-serif; }
  @media (min-width: 768px) and (max-width: 900px) {
    .brand-overview-list a,
    .brand-overview-list label,
    .brand-overview-list h3,
    .brand-overview-list .not-available,
    .filter-panel a,
    .filter-panel label,
    .filter-panel h3,
    .filter-panel .not-available {
      font-size: 2.6rem; } }
  @media (min-width: 1px) and (max-width: 767px) {
    .brand-overview-list a,
    .brand-overview-list label,
    .brand-overview-list h3,
    .brand-overview-list .not-available,
    .filter-panel a,
    .filter-panel label,
    .filter-panel h3,
    .filter-panel .not-available {
      font-size: 2.2rem; } }

.frame-sketch-svg text {
  font-family: 'NeueHaas-Regular', Helvetica, Arial, sans-serif;
  font-weight: 100; }

.payment_description.text--c {
  font-size: 1.2rem; }
  @media (min-width: 1260px) {
    .payment_description.text--c {
      font-size: 1.2rem; } }

html {
  font-size: 1vw;
  margin-top: 0 !important; }
  @media (min-width: 1px) and (max-width: 439px) {
    html {
      font-size: 3vw; } }
  @media (min-width: 440px) and (max-width: 767px) {
    html {
      font-size: 2vw; } }
  @media (min-width: 768px) and (max-width: 900px) {
    html {
      font-size: 1.5vw; } }
  @media (min-width: 1260px) and (max-width: 1899px) {
    html {
      font-size: 0.75vw; } }
  @media (min-width: 1900px) {
    html {
      font-size: 0.6vw; } }

body {
  margin: 0;
  padding: 0;
  min-width: 320px;
  position: relative;
  background: #fff;
  overflow-x: hidden; }
  body.has-footer-almost-inview {
    background: #6a5a5b; }

::selection {
  background: #c8c3c1; }
  footer ::selection {
    background: #6d5c5d; }

.wrapper {
  position: relative; }

.container {
  width: calc(100vw - 10%);
  margin: 0 5%;
  position: relative; }

.main-container {
  transition-property: padding, opacity, margin;
  transition-duration: 0.5s, 1s, 1s;
  transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1);
  z-index: 30;
  position: relative; }
  .has-search .main-container {
    padding-top: 8rem;
    transition-duration: 0.5s; }

.content {
  background: #f0eceb;
  transition-property: transform, filter;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1), linear;
  min-height: calc(100vh + 4rem);
  padding-bottom: 0;
  z-index: 30;
  position: relative; }
  .content > :first-child {
    padding-top: 8rem;
    padding-bottom: 0; }
    .content > :first-child.mce-content-body {
      padding: 1rem !important; }
    @media (min-width: 1px) and (max-width: 767px) {
      .content > :first-child {
        padding-top: 5rem; } }
    @media (min-width: 768px) and (max-width: 1200px) {
      .content > :first-child {
        padding-top: 6rem; } }
  .content.mce-content-body > :first-child {
    padding: 1rem !important; }

section {
  padding-bottom: 6rem; }
  @media (min-width: 1px) and (max-width: 439px) {
    section {
      padding-bottom: 4rem; } }

article {
  padding: 2rem 0 4rem 0; }

p {
  margin: 0 0 2rem 0; }

a[href^="tel"] {
  cursor: default;
  word-spacing: -0.02em; }
  .no-touch a[href^="tel"] {
    pointer-events: none; }

.content-header {
  min-height: 30rem;
  background: #fff;
  position: relative; }
  .content-header > div {
    position: relative; }
  .content-header .headline-wrapper {
    position: absolute;
    top: 40%;
    left: 0;
    text-align: center;
    width: 100%;
    margin-top: 4rem;
    transform: translateY(-50%); }
  @media (min-width: 768px) and (max-width: 900px) {
    .content-header {
      min-height: 26rem; } }
  @media (min-width: 1px) and (max-width: 767px) {
    .content-header {
      min-height: 24rem; } }

.content-header--shrink {
  margin-bottom: -16rem; }
  @media (min-width: 768px) {
    .content-header--shrink {
      margin-bottom: -20rem; } }
  @media (min-width: 768px) and (max-width: 900px) {
    .content-header--shrink {
      margin-bottom: -16rem; } }

.col--main {
  width: 60%;
  margin-left: 20%;
  padding-top: 6rem; }
  .col--main hr {
    border: 0;
    margin-top: 6rem;
    padding-bottom: 6rem;
    position: relative;
    transform: scaleX(1.666); }
  .col--main.content--cart, .col--main.content--checkout, .col--main.registration-container, .col--main.payment-shipping-container, .col--main.col--main--full {
    width: 70%;
    margin-left: 15%; }
    .col--main.content--cart hr, .col--main.content--checkout hr, .col--main.registration-container hr, .col--main.payment-shipping-container hr, .col--main.col--main--full hr {
      transform: scaleX(1.25); }
  .col--main.col--main--reduced {
    width: 60%;
    margin-left: 20%; }
    .col--main.col--main--reduced hr {
      transform: scaleX(1.25); }
  @media (min-width: 1px) and (max-width: 767px) {
    .col--main {
      width: 100%;
      margin-left: 0; }
      .col--main.content--cart, .col--main.content--checkout, .col--main.registration-container, .col--main.payment-shipping-container, .col--main.col--main--full, .col--main.col--main--reduced {
        width: 100%;
        margin-left: 0; }
      .col--main hr {
        margin-top: 4rem;
        padding-bottom: 4rem;
        transform: scaleX(1); } }
  @media (min-width: 768px) and (max-width: 900px) {
    .col--main {
      width: 80%;
      margin-left: 10%; }
      .col--main hr {
        transform: scaleX(1.25); }
      .col--main.content--cart, .col--main.content--checkout, .col--main.registration-container, .col--main.payment-shipping-container, .col--main.col--main--full, .col--main.col--main--reduced {
        width: 100%;
        margin-left: 0; }
        .col--main.content--cart hr, .col--main.content--checkout hr, .col--main.registration-container hr, .col--main.payment-shipping-container hr, .col--main.col--main--full hr, .col--main.col--main--reduced hr {
          transform: scaleX(1); } }
  @media (min-width: 1260px) and (max-width: 1899px) {
    .col--main {
      width: 40%;
      margin-left: 30%; }
      .col--main hr {
        transform: scaleX(2.5); }
      .col--main.content--cart, .col--main.content--checkout, .col--main.registration-container, .col--main.payment-shipping-container, .col--main.col--main--full {
        width: 60%;
        margin-left: 20%; }
        .col--main.content--cart hr, .col--main.content--checkout hr, .col--main.registration-container hr, .col--main.payment-shipping-container hr, .col--main.col--main--full hr {
          transform: scaleX(1.25); }
      .col--main.col--main--reduced {
        width: 40%;
        margin-left: 30%; }
        .col--main.col--main--reduced hr {
          transform: scaleX(1.25); } }
  @media (min-width: 1900px) {
    .col--main {
      width: 36%;
      margin-left: 32%; }
      .col--main hr {
        transform: scaleX(2.7); }
      .col--main.content--cart, .col--main.content--checkout, .col--main.registration-container, .col--main.payment-shipping-container, .col--main.col--main--full {
        width: 60%;
        margin-left: 20%; }
        .col--main.content--cart hr, .col--main.content--checkout hr, .col--main.registration-container hr, .col--main.payment-shipping-container hr, .col--main.col--main--full hr {
          transform: scaleX(1.666); }
      .col--main.col--main--reduced {
        width: 40%;
        margin-left: 30%; }
        .col--main.col--main--reduced hr {
          transform: scaleX(1.666); } }
  .col--main h2, .col--main h3, .col--main h4 {
    text-align: center; }
  .col--main h2 {
    margin-bottom: 2rem; }
  .col--main h3, .col--main h4 {
    margin-bottom: 1rem; }
  .col--main strong {
    text-align: center;
    display: block;
    font-weight: 100; }
  .col--main .btn {
    font-style: normal; }

.page-block-content {
  padding-bottom: 0; }
  .page-block-content:not(:last-child) {
    margin-bottom: 6rem; }
  .page-block-content:last-child {
    padding-bottom: 6rem; }
  .page-block-content.is-unpublished {
    outline: dashed 2px #d66146; }

.legal-text-container {
  text-align: left;
  counter-reset: legal-section; }
  .legal-text-container h2:before {
    counter-increment: legal-section;
    content: counter(legal-section) ". "; }
  .legal-text-container ol {
    list-style-type: none;
    counter-reset: legal-section-sub;
    margin: 0;
    padding-left: 4rem; }
    .legal-text-container ol > li {
      position: relative;
      text-align: left; }
    .legal-text-container ol > li:before {
      position: absolute;
      counter-increment: legal-section-sub;
      left: -4rem;
      top: 0;
      content: counter(legal-section) "." counter(legal-section-sub) "."; }
  .legal-text-container ul {
    margin: 0;
    padding-left: 2rem;
    list-style-type: none; }
    .legal-text-container ul > li {
      position: relative;
      text-align: left; }
      .legal-text-container ul > li:before {
        position: absolute;
        left: -2rem;
        top: -0.2rem;
        content: '•'; }
    .legal-text-container ul > li p:only-child {
      margin: 0; }
  .legal-text-container a {
    text-decoration: underline; }
  .legal-text-container p {
    line-height: 1.3; }

header.main-head {
  transition-property: height, filter, transform;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1), linear;
  background: #fff;
  height: 8rem;
  line-height: 2rem;
  width: 100%;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0; }
  .has-free-shipping-banner header.main-head {
    transform: translate3d(0, 6rem, 0); }
    @media (min-width: 1px) and (max-width: 439px) {
      .has-free-shipping-banner header.main-head {
        transform: translate3d(0, 6rem, 0); } }
    @media (min-width: 1260px) and (max-width: 1899px) {
      .has-free-shipping-banner header.main-head {
        transform: translate3d(0, 4rem, 0); } }
    @media (min-width: 1900px) {
      .has-free-shipping-banner header.main-head {
        transform: translate3d(0, 4rem, 0); } }
  header.main-head .main-elements {
    transition-property: height;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1);
    height: 8rem;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 102; }
  .has-search header.main-head {
    height: 16rem; }
  .has-scrolled header.main-head {
    height: 5rem; }
    .has-scrolled header.main-head .main-elements {
      height: 5rem; }
  .has-search.has-scrolled header.main-head {
    height: 13rem; }
  @media (min-width: 1px) and (max-width: 767px) {
    header.main-head {
      height: 5rem; }
      header.main-head:after {
        display: none; }
      header.main-head .main-elements {
        height: 5rem; }
      .has-search header.main-head,
      .has-search.has-scrolled header.main-head {
        height: 12rem; } }
  @media (min-width: 768px) and (max-width: 1200px) {
    header.main-head {
      height: 7rem; }
      header.main-head .main-elements {
        height: 7rem; }
      .has-search header.main-head {
        height: 14rem; }
      .has-scrolled header.main-head {
        height: 5rem; }
        .has-scrolled header.main-head .main-elements {
          height: 5rem; }
      .has-search.has-scrolled header.main-head {
        height: 12rem; } }

.logo {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  margin-top: 0.4rem;
  z-index: 1; }
  .logo a {
    padding-top: 5rem;
    margin-top: -5rem; }
  .is-ajax-request .product-detail .logo {
    display: none; }

footer {
  transition-property: transform, filter;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1), linear;
  z-index: 20;
  padding: 8rem 0 4rem 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: #944b3e;
  min-height: 44rem;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0; }
  .js body:not(.has-footer-almost-inview) footer {
    display: none; }
  footer a {
    transition-property: color;
    transition-duration: 0.25s;
    transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1); }
  footer .ico svg {
    transition-property: fill;
    transition-duration: 0.25s;
    transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1); }
  @media (min-width: 1px) and (max-width: 900px) {
    footer {
      position: relative;
      left: auto;
      bottom: auto;
      min-height: 1px;
      padding: 10rem 0 3rem 0;
      display: block !important; } }
  @media (min-width: 1px) and (max-width: 439px) {
    footer {
      padding: 10rem 0 6rem 0; } }

.before-footer {
  height: 44rem;
  pointer-events: none;
  background-image: linear-gradient(#944b3e, rgba(148, 75, 62, 0) 10rem);
  z-index: 30;
  position: relative; }
  .wrapper.product-detail .before-footer {
    display: none; }
  @media (min-width: 1px) and (max-width: 900px) {
    .before-footer {
      height: 1px; } }

.mini-contact {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 6rem; }
  .mini-contact > div {
    width: 33%; }
  .mini-contact .as-contact {
    order: 1; }
  .mini-contact .ka-contact {
    order: 3; }
  .mini-contact .service-contact {
    order: 2;
    padding-top: 6rem; }
  .mini-contact .opening-hours {
    height: 6rem;
    padding-bottom: 0; }
  .mini-contact a {
    display: block; }
    .mini-contact a span {
      display: inline-block;
      margin-left: 0.5rem; }
      .mini-contact a span:nth-child(1) {
        margin-left: 0.75rem; }
  @media (min-width: 1900px) {
    .mini-contact > div {
      width: 25%; } }
  @media (min-width: 1px) and (max-width: 900px) {
    .mini-contact > div {
      width: 50%; }
    .mini-contact .as-contact {
      order: 1; }
    .mini-contact .ka-contact {
      order: 2; }
    .mini-contact .service-contact {
      order: 3;
      padding-top: 6rem; } }
  @media (min-width: 1px) and (max-width: 767px) {
    .mini-contact > div {
      width: 100%;
      padding-bottom: 4rem; }
    .mini-contact .ka-contact .opening-hours {
      display: none; }
    .mini-contact .service-contact {
      padding-top: 0; } }

.social-media-panel {
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: center; }
  .social-media-panel .ico {
    padding: 0 0.5rem; }
    .social-media-panel .ico svg {
      width: 2rem;
      height: 2rem; }
  @media (min-width: 1px) and (max-width: 439px) {
    .social-media-panel {
      margin-bottom: 4rem; } }

.footer__tgc {
  padding-bottom: 5rem; }
  @media (min-width: 1px) and (max-width: 439px) {
    .footer__tgc {
      margin-bottom: 4rem; } }

.footer-menu ul {
  display: flex;
  justify-content: center;
  flex-flow: row wrap; }
  @media (min-width: 1px) and (max-width: 439px) {
    .footer-menu ul {
      flex-direction: column; } }
  .footer-menu ul li {
    padding: 0.5rem; }

.box-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap; }
  @media (min-width: 1px) and (max-width: 439px) {
    .box-container {
      display: block; } }

.box-container--m {
  width: 100%; }
  @media (min-width: 768px) and (max-width: 1200px) {
    .box-container--m {
      width: 83.333%; } }
  @media (min-width: 1201px) and (max-width: 1900px) {
    .box-container--m {
      width: 83.333%; } }
  @media (min-width: 1260px) and (max-width: 1899px) {
    .box-container--m {
      width: 60%; } }
  @media (min-width: 1900px) {
    .box-container--m {
      width: 60%; } }

.box-container--l {
  width: 100%; }
  @media (min-width: 768px) and (max-width: 1200px) {
    .box-container--l {
      width: 83.333%; } }
  @media (min-width: 1201px) and (max-width: 1900px) {
    .box-container--l {
      width: 83.333%; } }
  @media (min-width: 1260px) and (max-width: 1899px) {
    .box-container--l {
      width: 70%; } }
  @media (min-width: 1900px) {
    .box-container--l {
      width: 70%; } }

.box-container--centered {
  margin: 0 auto; }
  .box-container--centered h2, .box-container--centered h3, .box-container--centered h4, .box-container--centered p, .box-container--centered li {
    text-align: center; }
  .product-overview-hero .box-container--centered {
    display: block; }

.box-container--col-2 {
  justify-content: space-between; }
  .box-container--col-2 .box {
    width: 50%; }
    @media (min-width: 1px) and (max-width: 439px) {
      .box-container--col-2 .box {
        width: 100%; } }

.col-wrapper {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap; }
  .col-wrapper img {
    width: 100%;
    height: auto; }

.col-30 {
  width: 33%;
  padding: 0 1rem; }
  @media (min-width: 1px) and (max-width: 767px) {
    .col-30 {
      width: 100%;
      padding: 0; } }

.col-60 {
  width: 66%;
  padding: 0 1rem; }
  @media (min-width: 1px) and (max-width: 767px) {
    .col-60 {
      width: 100%;
      padding: 0; } }
  .col-60.line-row {
    margin: 0 1rem;
    width: calc(66% - 2rem); }
    @media (min-width: 1px) and (max-width: 767px) {
      .col-60.line-row {
        margin: 0;
        width: 100%; } }

.col-100 {
  width: 100%; }

@media print {
  html {
    font-size: 10px !important; }
  nav,
  .site-functions,
  .content-header,
  footer {
    display: none !important; }
  header,
  .main-elements {
    background: none !important; } }

ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.list--multi-column {
  column-count: 3; }
  .list--multi-column li {
    padding: 0 0.5rem; }
  .mega-dropdown .list--multi-column {
    column-count: 2; }
  @media (min-width: 768px) and (max-width: 900px) {
    .list--multi-column {
      column-count: 2; } }
  @media (min-width: 1px) and (max-width: 767px) {
    .list--multi-column {
      column-count: 2; } }

.list--large {
  line-height: 1.6; }

.list--bullet {
  line-height: 1.5;
  font-size: 1.2rem;
  list-style-position: inside; }
  .list--bullet li:before {
    content: "•";
    display: inline-block;
    padding-right: 1.6rem; }

.nav-head--main a,
.nav-head--main button,
.teaser a,
.teaser button {
  position: relative; }
  .nav-head--main a:after,
  .nav-head--main button:after,
  .teaser a:after,
  .teaser button:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -0.2rem;
    left: 50%;
    height: 0.15rem;
    width: 0;
    opacity: 0;
    transform: translateX(-50%);
    background: #0d0d0d;
    transition-property: width, opacity;
    transition-duration: 0.25s;
    transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1); }
  .no-touch .nav-head--main a:hover:after, .no-touch
  .nav-head--main button:hover:after, .no-touch
  .teaser a:hover:after, .no-touch
  .teaser button:hover:after {
    width: 100%;
    opacity: 1; }
  .nav-head--main a:active:after, .nav-head--main a:focus:after,
  .nav-head--main button:active:after,
  .nav-head--main button:focus:after,
  .teaser a:active:after,
  .teaser a:focus:after,
  .teaser button:active:after,
  .teaser button:focus:after {
    width: 100%;
    opacity: 1; }

.no-touch .nav-head--main li:hover a:after, .no-touch
.teaser li:hover a:after {
  width: 100%;
  opacity: 1; }

.nav-head--main .active a:after,
.nav-head--main:not(:hover) .is-active a:after,
.teaser .active a:after,
.teaser:not(:hover) .is-active a:after {
  width: 100%;
  opacity: 1; }

.nav-head--main button:after,
.teaser button:after {
  z-index: 40;
  background: #fff;
  bottom: -0.8rem; }

.no-touch .nav-head--main.box:hover button:after, .no-touch
.teaser.box:hover button:after {
  width: 100%;
  opacity: 1; }

.nav-head--main > ul > li:hover > a:after,
.nav-head--main > ul > li:focus > a:after {
  width: 100%;
  opacity: 1; }

form[data-state="loading"] input[type="submit"],
form[data-state="loading"] button[type="submit"] {
  background-color: #afa6a5;
  border-color: #afa6a5; }

input,
textarea,
select {
  transition-property: border;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1);
  border: 0.2rem solid #fff;
  border-radius: 1px;
  background-color: #fff;
  padding: 1.1rem 1.2rem;
  line-height: 1.82rem;
  letter-spacing: inherit;
  color: #0d0d0d; }
  input:focus,
  textarea:focus,
  select:focus {
    background-color: #fff;
    border: 0.2rem solid #c8c3c1; }
  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset; }
  .input--naked input, .input--naked
  textarea, .input--naked
  select {
    border: none;
    background: transparent;
    padding: 0;
    line-height: inherit; }
  .form--dark input, .form--dark
  textarea, .form--dark
  select {
    background-color: rgba(240, 236, 235, 0.5);
    border: solid 2px transparent; }
    .form--dark input:focus, .form--dark
    textarea:focus, .form--dark
    select:focus {
      background-color: rgba(244, 242, 241, 0.6); }
    .form--dark input::placeholder, .form--dark
    textarea::placeholder, .form--dark
    select::placeholder {
      line-height: inherit;
      text-transform: inherit;
      letter-spacing: inherit;
      text-align: left;
      color: rgba(0, 0, 0, 0.5); }
    .form--dark input::-webkit-input-placeholder, .form--dark
    textarea::-webkit-input-placeholder, .form--dark
    select::-webkit-input-placeholder {
      line-height: inherit;
      text-transform: inherit;
      letter-spacing: inherit;
      text-align: left;
      color: rgba(0, 0, 0, 0.5); }
    .form--dark input:-moz-placeholder, .form--dark
    textarea:-moz-placeholder, .form--dark
    select:-moz-placeholder {
      line-height: inherit;
      text-transform: inherit;
      letter-spacing: inherit;
      text-align: left;
      color: rgba(0, 0, 0, 0.5); }
    .form--dark input::-moz-placeholder, .form--dark
    textarea::-moz-placeholder, .form--dark
    select::-moz-placeholder {
      line-height: inherit;
      text-transform: inherit;
      letter-spacing: inherit;
      text-align: left;
      color: rgba(0, 0, 0, 0.5); }
    .form--dark input:-ms-input-placeholder, .form--dark
    textarea:-ms-input-placeholder, .form--dark
    select:-ms-input-placeholder {
      line-height: inherit;
      text-transform: inherit;
      letter-spacing: inherit;
      text-align: left;
      color: rgba(0, 0, 0, 0.5); }
  input[readonly],
  textarea[readonly],
  select[readonly] {
    color: #afa6a5; }
    input[readonly]:focus,
    textarea[readonly]:focus,
    select[readonly]:focus {
      border: 0.2rem solid #f9f7f7;
      background-color: #f9f7f7; }
  .has-error input,
  .validation-error input, input.has-error, .has-error
  textarea,
  .validation-error
  textarea,
  textarea.has-error, .has-error
  select,
  .validation-error
  select,
  select.has-error {
    border: 0.2rem solid #d66146; }

input[type="text"],
input[type="number"],
input[type="date"],
input[type="url"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="search"],
select,
textarea {
  width: 100%; }

input[type="text"],
input[type="number"],
input[type="date"],
input[type="url"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="submit"],
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

label {
  padding: 0.4rem 0;
  display: inline-block; }
  form label {
    padding: 0.4rem 0.2rem; }

input[type="checkbox"] {
  float: left;
  width: 1.6rem;
  height: 1.6rem;
  padding: 0; }

input[type="checkbox"] + label {
  display: block;
  padding: 0;
  cursor: pointer;
  margin-left: 3.2rem; }

input[type="radio"] {
  padding: 0; }

select {
  background: #fff url("../images/icons/specs-dropdown.svg") no-repeat calc(100% - 1.2rem) center;
  background-size: 1rem 1rem; }

.select-naked select,
select.select-naked {
  padding: 0;
  background-color: transparent;
  border: none; }
  .select-naked select:focus,
  select.select-naked:focus {
    background-color: transparent;
    border: none; }

abbr {
  color: #afa6a5; }

address {
  font-style: normal; }

form p {
  margin-bottom: 0; }

::placeholder {
  line-height: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  text-align: left;
  color: #afa6a5; }

::-webkit-input-placeholder {
  line-height: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  text-align: left;
  color: #afa6a5; }

:-moz-placeholder {
  line-height: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  text-align: left;
  color: #afa6a5; }

::-moz-placeholder {
  line-height: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  text-align: left;
  color: #afa6a5; }

:-ms-input-placeholder {
  line-height: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  text-align: left;
  color: #afa6a5; }

.form-grid {
  display: block;
  width: 100%;
  padding-bottom: 6rem;
  transition: padding 0.5s cubic-bezier(0.59, 0.01, 0.28, 1); }
  .form-grid label,
  .form-grid .form-grid-label {
    display: block;
    padding: 0.6rem 0.2rem; }
    .form-grid label.is-required .optional-hint,
    .form-grid .form-grid-label.is-required .optional-hint {
      display: none; }
    .form-grid label.is-optional .optional-hint,
    .form-grid .form-grid-label.is-optional .optional-hint {
      display: inline-block;
      color: #afa6a5;
      font-size: 1.2rem;
      margin-left: 0.2rem; }
      .form-grid label.is-optional .optional-hint:before,
      .form-grid .form-grid-label.is-optional .optional-hint:before {
        content: '– ';
        color: #0d0d0d; }
  .form-grid .form-grid-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: hidden;
    max-height: 24rem;
    opacity: 1;
    transition: max-height 0.5s cubic-bezier(0.59, 0.01, 0.28, 1), opacity 0.5s cubic-bezier(0.59, 0.01, 0.28, 1); }
    .form-grid .form-grid-row.form-grid-headline h2 {
      width: 100%;
      text-align: center; }
    .form-grid .form-grid-row[data-state="hidden"] {
      opacity: 0;
      max-height: 0; }
      .form-grid .form-grid-row[data-state="hidden"] .form-grid-cell {
        margin-bottom: 0; }
  .form-grid .form-grid-cell {
    margin-bottom: 1rem;
    width: 48%;
    transition: margin 0.5s cubic-bezier(0.59, 0.01, 0.28, 1); }
    @media (min-width: 1px) and (max-width: 439px) {
      .form-grid .form-grid-cell {
        width: 100%; } }
    .form-grid .form-grid-cell.is-full-width {
      width: 100%; }
    .form-grid .form-grid-cell.has-border {
      border: 0.2rem solid #0d0d0d;
      padding: 1.6rem 2rem;
      margin: 2rem 0 3.6rem 0; }
      .form-grid .form-grid-cell.has-border.is-error, .form-grid .form-grid-cell.has-border.has-error {
        border-color: #d66146;
        color: #d66146; }
        .form-grid .form-grid-cell.has-border.is-error a,
        .form-grid .form-grid-cell.has-border.is-error span, .form-grid .form-grid-cell.has-border.has-error a,
        .form-grid .form-grid-cell.has-border.has-error span {
          border-color: inherit;
          color: inherit; }
    .form-grid .form-grid-cell.is-centered {
      text-align: center; }
  .form-grid .form-grid-hint {
    padding: 0.6rem 0.2rem;
    line-height: 1.2;
    color: #afa6a5;
    font-size: 1.2rem; }
  .form-grid .form-grid-error {
    line-height: 1.2;
    color: #d66146;
    margin: 0.5rem 0 0 0; }
  .form-grid[data-state="hidden"] {
    padding-bottom: 0; }
    .form-grid[data-state="hidden"] .form-grid-row {
      opacity: 0;
      max-height: 0; }
    .form-grid[data-state="hidden"] .form-grid-cell {
      margin-bottom: 0; }

.input-group {
  display: flex; }
  .input-group .input-group-item {
    display: inline-block; }
    .input-group .input-group-item.input-group-item--zip {
      width: 20rem;
      margin-right: 4%; }
      @media (min-width: 1px) and (max-width: 439px) {
        .input-group .input-group-item.input-group-item--zip {
          width: 10rem; } }
    .input-group .input-group-item.input-group-item--city {
      flex: 1; }

.option-group {
  display: flex;
  padding-bottom: 1rem; }
  .option-group .option-group-item {
    position: relative; }
    .option-group .option-group-item:not(:last-child) {
      margin-right: 2rem; }
  .option-group input[type="radio"] {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px !important;
    padding: 0;
    border: 0;
    left: 50%;
    bottom: 0; }
  .option-group label {
    display: inline-block;
    padding: 0;
    font-size: 1.5rem;
    cursor: pointer; }
    .option-group label:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -0.4rem;
      left: 50%;
      height: 2px;
      width: 0;
      opacity: 0;
      transform: translateX(-50%);
      background: #0d0d0d;
      transition-property: width, opacity;
      transition-duration: 0.25s;
      transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1); }
    .no-touch .option-group label:hover:after {
      width: 100%;
      opacity: 1; }
  .option-group input[type="radio"]:checked + label:after {
    width: 100%;
    opacity: 1; }

.advanced-checkbox {
  display: inline-block;
  position: relative; }
  .advanced-checkbox input[type="checkbox"] {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px !important;
    padding: 0;
    border: 0;
    top: 1rem;
    left: 2rem; }
  .advanced-checkbox label {
    cursor: pointer; }
  .advanced-checkbox input[type="checkbox"] + label {
    position: relative;
    display: inline-block;
    padding: 0 0 0 1rem; }
    .advanced-checkbox input[type="checkbox"] + label:before, .advanced-checkbox input[type="checkbox"] + label:after {
      display: inline-block;
      position: absolute; }
    .advanced-checkbox input[type="checkbox"] + label:before {
      content: '';
      top: 0.1rem;
      left: -2rem;
      width: 1.6rem;
      height: 1.6rem;
      border: solid 2px #0d0d0d;
      border-radius: 1px; }
    .advanced-checkbox input[type="checkbox"] + label:after {
      content: '×';
      opacity: 0;
      top: 0.1rem;
      left: -2rem;
      width: 1.6rem;
      height: 1.6rem;
      text-align: center;
      line-height: 1.6rem;
      transition-property: width, opacity;
      transition-duration: 0.25s;
      transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1); }
  .advanced-checkbox input[type="checkbox"]:checked + label:after {
    opacity: 1; }
  .has-error .advanced-checkbox input[type="checkbox"] + label {
    color: #d66146; }
    .has-error .advanced-checkbox input[type="checkbox"] + label:before {
      border-color: #d66146; }

.advanced-radio {
  display: inline-block;
  position: relative; }
  .advanced-radio input[type="radio"] {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  .advanced-radio label {
    cursor: pointer; }
  .advanced-radio input[type="radio"] + label {
    position: relative;
    display: inline-block;
    padding: 0 0 0 2.5rem; }
    .advanced-radio input[type="radio"] + label:before, .advanced-radio input[type="radio"] + label:after {
      display: inline-block;
      position: absolute; }
    .advanced-radio input[type="radio"] + label:before {
      content: '';
      top: 0.3rem;
      left: 0;
      width: 1.4rem;
      height: 1.4rem;
      border: solid 2px #0d0d0d;
      border-radius: 100%; }
    .advanced-radio input[type="radio"] + label:after {
      content: '';
      opacity: 0;
      top: 0.7rem;
      left: 0.4rem;
      width: 0.6rem;
      height: 0.6rem;
      text-align: center;
      line-height: 1.6rem;
      background-color: #0d0d0d;
      border-radius: 100%;
      transition-property: width, opacity;
      transition-duration: 0.25s;
      transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1); }
  .advanced-radio input[type="radio"]:checked + label:after {
    opacity: 1; }
  .has-error .advanced-radio input[type="radio"] + label {
    color: #d66146; }
    .has-error .advanced-radio input[type="radio"] + label:before {
      border-color: #d66146; }

.toggle-checkbox {
  position: relative; }
  .toggle-checkbox input[type="checkbox"] {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px !important;
    padding: 0;
    border: 0;
    top: 2.2rem;
    left: 1rem; }
  .toggle-checkbox .toggle-checkbox__label {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-left: 11rem;
    font-size: 1.2rem;
    margin: 0; }
    .toggle-checkbox .toggle-checkbox__label span {
      display: block; }
    .toggle-checkbox .toggle-checkbox__label:before, .toggle-checkbox .toggle-checkbox__label:after {
      content: attr(data-checked);
      display: inline-block;
      float: left;
      position: absolute;
      top: 0;
      left: 0;
      width: 4rem;
      text-align: center;
      font-size: 1.2rem;
      text-transform: uppercase;
      border: 2px solid #f0eceb;
      padding: 0.2rem; }
    .toggle-checkbox .toggle-checkbox__label:before {
      content: attr(data-checked);
      border-right: none; }
    .toggle-checkbox .toggle-checkbox__label:after {
      content: attr(data-unchecked);
      left: 4rem;
      border-left: none;
      background: #f0eceb;
      color: #0d0d0d; }
  .toggle-checkbox input[type="checkbox"]:checked + label:before {
    background: #f0eceb;
    color: #0d0d0d; }
  .toggle-checkbox input[type="checkbox"]:checked + label:after {
    background: none;
    color: inherit; }
  .toggle-checkbox input[disabled] + label {
    cursor: default; }
    .toggle-checkbox input[disabled] + label:before {
      border-right: 2px solid #f0eceb; }
    .toggle-checkbox input[disabled] + label:after {
      visibility: hidden; }

.line-row {
  padding: 1.4rem 0;
  border-bottom: 1px solid #0d0d0d;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  line-height: 2rem; }

.line-row--thick {
  border-bottom: 0.2rem solid #0d0d0d; }

.line-row--inverted {
  border-top: 0.2rem solid #0d0d0d; }

.line-row--bottom {
  border-bottom: none; }
  .line-row--bottom.line-row--inverted {
    border-bottom: none; }

.ico {
  position: relative; }
  .ico svg {
    width: 2.8rem;
    height: 2.8rem;
    margin-right: 0.5rem;
    fill: #0d0d0d;
    vertical-align: top; }
  .ico span, .ico a {
    display: inline-block;
    line-height: 2.8rem;
    vertical-align: top; }
  .ico.ico--naked svg {
    margin-right: 0; }
  .ico.ico--naked span:not(.item-count) {
    text-indent: -999rem; }
  .ico.ico--left-label svg {
    margin-right: 0;
    margin-left: 0.2rem; }
  .ico.ico--dismiss svg {
    width: 1.8rem;
    height: 1.8rem; }
  .ico.close-modal span {
    text-indent: -999rem; }
  .logo .ico svg {
    width: 11.9rem;
    height: 2.1rem; }
  .logo .ico span {
    width: 0;
    overflow: hidden;
    display: inline-block;
    text-indent: -999rem; }

.svg-ico-payment-sofort {
  fill: #eb6f93; }

.teaser {
  position: relative; }
  .teaser button {
    color: #fff;
    border: 2px solid #fff;
    position: absolute;
    bottom: 8rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 40;
    display: block;
    white-space: nowrap;
    text-shadow: 0 0 5em rgba(9, 14, 20, 0.5); }
  .teaser .img-box img {
    transition-property: transform;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1); }
  .no-touch .teaser:hover .img-box img {
    transform: translateY(-50%) scale(1.04); }
  @media (min-width: 768px) {
    .teaser .img-box--port-m button {
      bottom: 14rem; } }

.img-box {
  position: relative;
  overflow: hidden;
  padding-bottom: 140%;
  width: 100%; }
  .img-box img {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%); }
    .img-box img.lazy {
      transition-property: opacity, transform;
      transition-duration: 0.5s;
      transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1); }
    .img-box img.lazy--pending {
      opacity: 0; }
    .img-box img.lazy--loaded {
      opacity: 1; }
  .img-box legend {
    position: absolute;
    bottom: 0;
    left: 5%;
    margin-bottom: 2rem;
    z-index: 30;
    width: 90%;
    text-align: center; }
  .img-box h2 {
    position: absolute;
    bottom: 12%;
    left: 5%;
    margin-bottom: 2rem;
    z-index: 30;
    width: 90%;
    text-align: center; }

.img-box--port-s {
  padding-bottom: 110%; }

.img-box--port-m {
  padding-bottom: 133%; }

.img-box--land-m {
  padding-bottom: 75%; }

.img-box--land-l {
  padding-bottom: 66.666%; }

.img-box--land-xl {
  padding-bottom: 60%; }

.img-box--land-xxl {
  padding-bottom: 40%; }
  @media (min-width: 1px) and (max-width: 767px) {
    .img-box--land-xxl {
      padding-bottom: 60%; }
      .img-box--land-xxl img {
        height: 100%;
        width: auto;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0); } }

.text-box--l {
  margin: 0 auto 4rem auto;
  text-align: center; }
  @media (min-width: 1px) and (max-width: 439px) {
    .text-box--l {
      width: 90%; } }
  @media (min-width: 440px) and (max-width: 767px) {
    .text-box--l {
      width: 90%; } }
  @media (min-width: 768px) and (max-width: 1200px) {
    .text-box--l {
      width: 90%; } }
  @media (min-width: 1201px) and (max-width: 1900px) {
    .text-box--l {
      width: 75%; } }
  @media (min-width: 1260px) and (max-width: 1899px) {
    .text-box--l {
      width: 60%; } }
  @media (min-width: 1900px) {
    .text-box--l {
      width: 60%; } }

.text-box--m {
  margin: 0 auto 4rem auto;
  text-align: center; }
  @media (min-width: 440px) and (max-width: 767px) {
    .text-box--m {
      width: 80%; } }
  @media (min-width: 768px) and (max-width: 1200px) {
    .text-box--m {
      width: 80%; } }
  @media (min-width: 1201px) and (max-width: 1900px) {
    .text-box--m {
      width: 66%; } }
  @media (min-width: 1260px) and (max-width: 1899px) {
    .text-box--m {
      width: 50%; } }
  @media (min-width: 1900px) {
    .text-box--m {
      width: 50%; } }

.text-box--s {
  margin: 0 auto 4rem auto;
  text-align: center; }
  @media (min-width: 440px) and (max-width: 767px) {
    .text-box--s {
      width: 80%; } }
  @media (min-width: 768px) and (max-width: 1200px) {
    .text-box--s {
      width: 40%; } }
  @media (min-width: 768px) and (max-width: 900px) {
    .text-box--s {
      width: 50%; } }
  @media (min-width: 1201px) and (max-width: 1900px) {
    .text-box--s {
      width: 40%; } }
  @media (min-width: 1260px) and (max-width: 1899px) {
    .text-box--s {
      width: 25%; } }
  @media (min-width: 1900px) {
    .text-box--s {
      width: 20%; } }

.box--off-1 {
  margin-top: 8rem; }
  @media (min-width: 1px) and (max-width: 439px) {
    .box--off-1 {
      margin-top: 0; } }

.border-box {
  border: 0.2rem solid #0d0d0d;
  padding: 2rem 1.6rem; }

.border-box.validation-error {
  border: 0.2rem solid #d66146; }

.color {
  background-color: #e7e1df; }
  .box:nth-child(even) .color {
    background-color: #ece6e5; }

.modal {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 42rem;
  background: #944b3e;
  transform: translate3d(50%, 0, 0);
  transition-property: transform, opacity, visibility;
  transition-duration: 0.5s, 0.25s, 0s;
  transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1), cubic-bezier(0.59, 0.01, 0.28, 1), linear;
  transition-delay: 0s, 0.25s, 1s;
  z-index: 140;
  opacity: 0;
  visibility: hidden;
  pointer-events: none; }
  .modal a {
    color: inherit; }
  .modal .modal-inner {
    transform: translate3d(5%, 0, 0);
    opacity: 0;
    transition-property: transform, opacity;
    transition-duration: 0.5s;
    transition-delay: 0s;
    min-height: 100%; }
  .modal .modal-header {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 2rem);
    min-height: 6rem;
    padding: 2.6rem 5rem 0 7rem;
    background: #944b3e;
    z-index: 145; }
    .modal .modal-header ul {
      display: block;
      width: 100%;
      height: 3.4rem;
      margin-top: -0.5rem;
      position: relative; }
    .modal .modal-header li {
      cursor: pointer;
      padding: 0 1rem;
      float: left; }
    .modal .modal-header .modal-title {
      position: relative;
      padding: 0 3rem 0 0;
      width: 100%;
      height: 100%; }
      .modal .modal-header .modal-title h2 {
        font-size: 2.6rem;
        display: block;
        margin-bottom: 0; }
    .modal .modal-header .close-modal {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0; }
    @media (min-width: 1px) and (max-width: 439px) {
      .modal .modal-header .item-count {
        display: none; }
      .modal .modal-header li {
        padding: 0 0.6rem; } }
  .modal .modal-content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .modal .modal-body {
    padding: 8rem 7rem 2rem 7rem;
    flex: 1 0 auto; }
  .modal .modal-footer {
    flex: 0 0 auto;
    position: sticky;
    bottom: 0;
    width: calc(100% - 2rem);
    background: #944b3e;
    padding: 3rem 5rem 4rem 7rem; }
  @media (min-width: 1px) and (max-width: 767px) {
    .modal {
      width: 85%; }
      .modal .modal-header {
        width: calc(100% - 1rem);
        padding: 1.4rem 1rem 0 2rem;
        min-height: 4rem; }
      .modal .modal-body {
        padding: 6rem 2rem 2rem 2rem; }
      .modal .modal-footer {
        position: relative;
        bottom: auto;
        padding: 2rem 0 2rem 2rem; } }
  @media (min-width: 768px) and (max-width: 1200px) {
    .modal {
      width: 38rem; }
      .modal .modal-header {
        width: calc(100% - 2rem);
        padding: 1.9rem 2rem 0 4rem; }
      .modal .modal-body {
        padding: 8rem 4rem 2rem 4rem; }
      .modal .modal-footer {
        width: calc(100% - 2rem);
        padding: 3rem 2rem 3rem 4rem; } }
  .modal.modal-large {
    width: 65%; }
    .modal.modal-large .modal-header {
      padding: 2.6rem calc(6.5vw - 2rem) 0 0; }
    .modal.modal-large .modal-inner {
      margin: 0 0 0 8rem; }
    .modal.modal-large .modal-content {
      padding-right: 6.5vw;
      padding-bottom: 6rem; }
    @media (min-width: 1px) and (max-width: 767px) {
      .modal.modal-large {
        width: 85%; }
        .modal.modal-large .modal-header {
          padding-right: calc(5.2vw - 1rem);
          padding-top: 1.4rem; }
        .modal.modal-large .modal-inner {
          margin: 0 0 0 3rem; }
        .modal.modal-large .modal-content {
          padding-right: 5.2vw;
          padding-bottom: 3rem; } }
    @media (min-width: 768px) and (max-width: 1200px) {
      .modal.modal-large {
        width: 80%; }
        .modal.modal-large .modal-header {
          padding-right: calc(5.96vw - 2rem);
          padding-top: 1.9rem; }
        .modal.modal-large .modal-inner {
          margin: 0 0 0 6rem; }
        .modal.modal-large .modal-content {
          padding-right: 5.96vw;
          padding-bottom: 5rem; } }
    @media (min-width: 1260px) and (max-width: 1899px) {
      .modal.modal-large {
        width: 50%; } }
    @media (min-width: 1900px) {
      .modal.modal-large {
        width: 50%; } }

.show-nav-main .nav-main,
.has-nav-main .nav-main, .show-login-box .login-box,
.has-login-box .login-box, .show-mini-cart .mini-cart,
.has-mini-cart .mini-cart, .has-localisation .modal--localisation, .has-frame-notification .modal--frame-notification {
  visibility: visible;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 0s, 0s, 0s;
  pointer-events: all; }
  .show-nav-main .nav-main .modal-inner, .has-nav-main .nav-main .modal-inner, .show-login-box .login-box .modal-inner, .has-login-box .login-box .modal-inner, .show-mini-cart .mini-cart .modal-inner, .has-mini-cart .mini-cart .modal-inner, .has-localisation .modal--localisation .modal-inner, .has-frame-notification .modal--frame-notification .modal-inner {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    transition-delay: 0.25s; }

.modal--localisation h3 {
  line-height: 2.8rem; }

.modal--localisation .button-wrapper .btn {
  width: 100%; }

.modal--localisation .modal-body {
  transition: opacity 0.5s cubic-bezier(0.59, 0.01, 0.28, 1);
  opacity: 1; }

.modal--localisation[aria-busy="true"] .modal-body,
.modal--localisation form[data-state="loading"] .modal-body {
  opacity: 0.2; }

.modal--localisation .modal-footer .button-wrapper {
  padding-top: 0; }

@media (min-width: 1px) and (max-width: 767px) {
  .modal--localisation .modal-footer {
    position: sticky;
    bottom: 0; }
    .modal--localisation .modal-footer:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 2rem;
      margin-top: -2rem;
      background: linear-gradient(0deg, #944b3e, rgba(148, 75, 62, 0));
      pointer-events: none; } }

.modal--frame-notification h3 {
  line-height: 2.8rem; }

.side-cover {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 100vh;
  background: #afa6a5;
  z-index: 110;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1);
  pointer-events: none; }

.show-login-box #side-cover,
.has-login-box #side-cover,
.show-mini-cart #side-cover,
.has-mini-cart #side-cover,
.show-nav-main #side-cover,
.has-localisation #side-cover,
.has-frame-notification #side-cover,
.has-nav-main #side-cover {
  opacity: 0.8;
  pointer-events: all;
  cursor: pointer; }

.has-login-box,
.has-mini-cart,
.has-nav-main,
.has-localisation,
.has-frame-notification {
  overflow: hidden; }

.nav-head {
  position: absolute;
  top: 0;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.8rem; }
  .nav-head.nav-head--product {
    font-size: 1.5rem; }
  .nav-head > ul > li {
    padding: 0 1rem; }
    .nav-head > ul > li > a {
      display: inline-block;
      transition-property: transform;
      transition-duration: 0.5s;
      transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1); }
      .has-scrolled .wrapper:not(.product-detail) .nav-head > ul > li > a,
      .has-scrolled.is-direct-request .nav-head > ul > li > a {
        transform: scale(0.83); }
  .nav-head.nav-head--main > ul, .nav-head.nav-head--product > ul {
    height: 100%; }
    .nav-head.nav-head--main > ul > li, .nav-head.nav-head--product > ul > li {
      height: 100%;
      display: flex;
      align-items: center; }
    .nav-head.nav-head--main > ul .nav-head__book-appointment, .nav-head.nav-head--product > ul .nav-head__book-appointment {
      display: none; }
      @media (min-width: 1201px) and (max-width: 1900px) {
        .nav-head.nav-head--main > ul .nav-head__book-appointment, .nav-head.nav-head--product > ul .nav-head__book-appointment {
          display: flex; } }
      @media (min-width: 1900px) {
        .nav-head.nav-head--main > ul .nav-head__book-appointment, .nav-head.nav-head--product > ul .nav-head__book-appointment {
          display: flex; } }
  .touch .nav-head.nav-head--main > ul > li {
    cursor: pointer; }
  .nav-head.nav-head--main > ul > li:hover {
    z-index: 1;
    padding: 0 3rem;
    margin: 0 -2rem; }
    .nav-head.nav-head--main > ul > li:hover > a {
      z-index: 1; }
  @media (min-width: 768px) and (max-width: 900px) {
    .nav-head.nav-head--main {
      left: 47%; }
      .nav-head.nav-head--main > ul > li {
        font-size: 1.4rem;
        padding: 0 0.5rem; }
      .nav-head.nav-head--main > ul > li:hover {
        padding: 0 2.5rem; } }
  .nav-head.nav-head--others > ul {
    width: 40rem; }
    .nav-head.nav-head--others > ul > li {
      width: 20rem; }
      .nav-head.nav-head--others > ul > li:first-child {
        justify-content: flex-end; }
        .nav-head.nav-head--others > ul > li:first-child a {
          justify-content: flex-end; }
          .nav-head.nav-head--others > ul > li:first-child a .ico svg {
            margin: 0 0 0 0.5rem; }
  @media (min-width: 768px) and (max-width: 900px) {
    .nav-head.nav-head--others > ul {
      width: 36rem; }
      .nav-head.nav-head--others > ul > li {
        width: 18rem;
        padding: 0 0.5rem; } }
  .is-ajax-request .product-detail .nav-head:not(.nav-head--product) {
    opacity: 0;
    pointer-events: none; }
  .is-direct-request .nav-head.nav-head--back, .is-direct-request .nav-head.nav-head--others {
    opacity: 0;
    pointer-events: none; }
  .nav-head.nav-head--back {
    left: 0;
    transform: translateX(0); }
    .nav-head.nav-head--back > ul > li {
      padding: 0;
      margin-left: -0.8rem; }
  .nav-head.nav-head--back a, .nav-head.nav-head--others a {
    display: flex;
    align-items: center; }
    .nav-head.nav-head--back a .ico, .nav-head.nav-head--others a .ico {
      margin-top: -0.15rem; }
    .no-touch .nav-head.nav-head--back a span:not(.ico), .no-touch .nav-head.nav-head--others a span:not(.ico) {
      transition-property: margin, opacity;
      transition-duration: 0.5s;
      transition-timing-function: cubic-bezier(0.17, 0, 0, 1);
      margin: 0; }
    .no-touch .nav-head.nav-head--back a:hover span:not(.ico), .no-touch .nav-head.nav-head--others a:hover span:not(.ico) {
      transition-duration: 0.25s;
      margin: 0 -0.5rem; }
  @media (min-width: 440px) and (max-width: 767px) {
    .nav-head {
      font-size: 1.5rem; } }
  @media (min-width: 768px) and (max-width: 1900px) {
    .nav-head.nav-head--others {
      left: 60%; } }
  @media (min-width: 1260px) {
    .nav-head.nav-head--others {
      left: 60%; } }
  @media (min-width: 1900px) {
    .nav-head.nav-head--others {
      left: 55%; } }
  @media (min-width: 1px) and (max-width: 900px) {
    .nav-head.nav-head--others {
      left: 50%; } }
  @media (min-width: 1px) and (max-width: 767px) {
    .nav-head:not(.nav-head--back) {
      display: none; } }

.nav-main .nav-main-main {
  flex: 3 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 9rem 0 4rem; }

.nav-main ul.main li {
  display: block;
  text-align: center;
  font-family: 'NeueHaas-Medium', Helvetica, Arial, sans-serif; }

.nav-main .nav-main-sec {
  flex: 1 0 auto;
  padding-bottom: 2rem; }

.nav-main .nav-main-third {
  flex: 0 0 auto; }
  .nav-main .nav-main-third .third {
    float: left;
    width: 80%; }
  .nav-main .nav-main-third .language-selection {
    float: left;
    width: 20%; }
    .nav-main .nav-main-third .language-selection .is-active a {
      font-family: 'NeueHaas-Medium', Helvetica, Arial, sans-serif; }
  .nav-main .nav-main-third ul li {
    line-height: 1.5; }

@media (min-width: 1px) and (max-width: 767px) {
  .nav-main .nav-main-main {
    padding: 7rem 0 4rem; }
  .nav-main .nav-main-third .third {
    width: 66%; }
  .nav-main .nav-main-third .language-selection {
    width: 33%; } }

#nav-cover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: #6a5a5b;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1);
  pointer-events: none; }
  .show-nav-main.show-login-box #nav-cover,
  .has-nav-main.has-login-box #nav-cover,
  .show-nav-main.show-mini-cart #nav-cover,
  .has-nav-main.has-mini-cart #nav-cover {
    opacity: 0.95;
    pointer-events: all;
    cursor: pointer; }

.has-login-box,
.has-mini-cart,
.has-nav-main {
  overflow: hidden; }

.site-functions {
  position: absolute;
  top: 50%;
  right: -1rem;
  margin-top: -1rem; }
  .site-functions ul {
    margin-top: -0.5rem; }
  .site-functions li {
    padding: 0 1rem;
    cursor: pointer; }

.action-panel {
  background: #f0eceb;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: visible;
  transform: translate3d(0, 100%, 0);
  z-index: 120; }

.cart-trigger {
  background: #c8c3c1;
  border-radius: 1px; }
  .site-functions .cart-trigger {
    padding: 0 0 0 0.3rem; }
  .modal .modal-header .cart-trigger {
    background: transparent;
    padding: 0 0 0 0.3rem;
    margin-right: 1.5rem;
    transform: translateY(-0.15rem); }
  .cart-trigger span:nth-child(3) {
    display: none; }

.item-count {
  display: inline-block;
  font-size: 1rem;
  margin-right: 1rem;
  transform: translate3d(0, 0.2rem, 0);
  letter-spacing: 0.1rem;
  font-family: 'NeueHaas-Medium', Helvetica, Arial, sans-serif; }

.mini-cart .modal-title {
  position: relative;
  overflow: hidden; }
  .mini-cart .modal-title h3 {
    line-height: 2.8rem; }
  .mini-cart .modal-title .modal-title-wrapper {
    transition-property: transform;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .mini-cart .modal-title .modal-title-wrapper--default {
    transform: translate3d(0, 0, 0); }
  .mini-cart .modal-title .modal-title-wrapper--updating {
    transform: translate3d(0, 100%, 0); }

.mini-cart .modal-content {
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1); }

.mini-cart h3 {
  display: inline-block; }

.mini-cart .mini-cart-actions .btn {
  width: calc(50% - 0.5rem); }

.mini-cart .cart-empty {
  text-align: left; }
  .mini-cart .cart-empty h3 {
    display: block;
    margin-bottom: 0.6rem; }
  .mini-cart .cart-empty .btn {
    width: 100%; }

.mini-cart .product-image {
  margin-bottom: 1rem;
  background: #f0eceb;
  line-height: 0;
  overflow: hidden; }
  .mini-cart .product-image img {
    border-left: 2rem solid #f0eceb;
    border-right: 2rem solid #f0eceb;
    margin: -0.5rem 0; }
    @media (min-width: 1px) and (max-width: 767px) {
      .mini-cart .product-image img {
        border-left: 3rem solid #f0eceb;
        border-right: 3rem solid #f0eceb; } }

.mini-cart .mini-cart-listing-item {
  position: relative; }
  .mini-cart .mini-cart-listing-item .price {
    float: right; }
  .mini-cart .mini-cart-listing-item .brand {
    display: block; }
  .mini-cart .mini-cart-listing-item a {
    display: block;
    transition-property: max-height, opacity, padding;
    transition-duration: 0.25s;
    transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1);
    opacity: 1;
    max-height: 30rem;
    overflow: hidden;
    padding-bottom: 4rem; }
  .mini-cart .mini-cart-listing-item.is-removed a {
    max-height: 0;
    opacity: 0;
    padding-bottom: 0; }

.mini-cart .tax-notice a {
  border-bottom: 1px solid rgba(13, 13, 13, 0.5); }

.mini-cart .button-wrapper {
  padding: 0; }

.mini-cart.is-loading .modal-title .modal-title-wrapper--default {
  transform: translate3d(0, -100%, 0); }

.mini-cart.is-loading .modal-title .modal-title-wrapper--updating {
  transform: translate3d(0, 0, 0); }

.mini-cart.is-loading .modal-content {
  opacity: 0.4;
  pointer-events: none; }
  .mini-cart.is-loading .modal-content * {
    cursor: default; }

.mini-cart.is-loading .cart-empty {
  display: none; }

.login-button {
  display: block; }

.my-account-button {
  display: none; }

.logout-button {
  display: none; }

.is-logged-in .login-button {
  display: none; }

.is-logged-in .my-account-button {
  display: block; }

.is-logged-in .logout-button {
  display: block; }

button,
.button,
.btn,
input[type="submit"],
input[type="button"] {
  position: relative;
  display: inline-block;
  background: none;
  padding: 0.6rem 1.4rem;
  line-height: 2rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 1px;
  letter-spacing: inherit;
  background: #944b3e;
  border: 0.2rem solid #944b3e;
  transition: background 0.5s cubic-bezier(0.59, 0.01, 0.28, 1), border 0.5s cubic-bezier(0.59, 0.01, 0.28, 1); }
  button.btn--naked,
  .button.btn--naked,
  .btn.btn--naked,
  input[type="submit"].btn--naked,
  input[type="button"].btn--naked {
    border: 0; }
  button.btn--link,
  .button.btn--link,
  .btn.btn--link,
  input[type="submit"].btn--link,
  input[type="button"].btn--link {
    text-transform: none;
    padding: 0;
    border: 0;
    color: #0d0d0d;
    background: transparent;
    font-family: 'NeueHaas-Medium', Helvetica, Arial, sans-serif; }
    .teaser button.btn--link, .teaser
    .button.btn--link, .teaser
    .btn.btn--link, .teaser
    input[type="submit"].btn--link, .teaser
    input[type="button"].btn--link {
      color: #fff;
      border-color: #fff; }
  button.btn--clean,
  .button.btn--clean,
  .btn.btn--clean,
  input[type="submit"].btn--clean,
  input[type="button"].btn--clean {
    border: none;
    background: none;
    padding: 0; }
  button.btn--inverted,
  .button.btn--inverted,
  .btn.btn--inverted,
  input[type="submit"].btn--inverted,
  input[type="button"].btn--inverted {
    background: #864438;
    border: 0.2rem solid transparent; }
    .modal-content button.btn--inverted:not(.btn--secondary),
    .cookie-consent button.btn--inverted:not(.btn--secondary), .modal-content
    .button.btn--inverted:not(.btn--secondary),
    .cookie-consent
    .button.btn--inverted:not(.btn--secondary), .modal-content
    .btn.btn--inverted:not(.btn--secondary),
    .cookie-consent
    .btn.btn--inverted:not(.btn--secondary), .modal-content
    input[type="submit"].btn--inverted:not(.btn--secondary),
    .cookie-consent
    input[type="submit"].btn--inverted:not(.btn--secondary), .modal-content
    input[type="button"].btn--inverted:not(.btn--secondary),
    .cookie-consent
    input[type="button"].btn--inverted:not(.btn--secondary) {
      background: #f0eceb; }
  button.btn--secondary,
  .button.btn--secondary,
  .btn.btn--secondary,
  input[type="submit"].btn--secondary,
  input[type="button"].btn--secondary {
    background: transparent;
    border: 0.2rem solid #968b88;
    color: #0d0d0d; }
    button.btn--secondary.btn--inverted,
    .button.btn--secondary.btn--inverted,
    .btn.btn--secondary.btn--inverted,
    input[type="submit"].btn--secondary.btn--inverted,
    input[type="button"].btn--secondary.btn--inverted {
      border: 0.2rem solid #0d0d0d; }
  button.btn--small,
  .button.btn--small,
  .btn.btn--small,
  input[type="submit"].btn--small,
  input[type="button"].btn--small {
    font-size: 1.2rem;
    padding: 0.2rem 1.4rem;
    line-height: 1.6rem; }
  button.btn--directions,
  .button.btn--directions,
  .btn.btn--directions,
  input[type="submit"].btn--directions,
  input[type="button"].btn--directions {
    background: url("../images/icons/specs-locality.svg") top center no-repeat;
    background-size: 2.8rem 2.8rem;
    padding-top: 3rem; }
  button.btn--full-width,
  .button.btn--full-width,
  .btn.btn--full-width,
  input[type="submit"].btn--full-width,
  input[type="button"].btn--full-width {
    width: 100%; }
  button.btn--medium,
  .button.btn--medium,
  .btn.btn--medium,
  input[type="submit"].btn--medium,
  input[type="button"].btn--medium {
    padding: 1.1rem 1.2rem; }
  button.btn--large,
  .button.btn--large,
  .btn.btn--large,
  input[type="submit"].btn--large,
  input[type="button"].btn--large {
    padding: 1.5rem; }
  button.btn--huge,
  .button.btn--huge,
  .btn.btn--huge,
  input[type="submit"].btn--huge,
  input[type="button"].btn--huge {
    padding: 2rem; }
  button.btn--underlined,
  .button.btn--underlined,
  .btn.btn--underlined,
  input[type="submit"].btn--underlined,
  input[type="button"].btn--underlined {
    border: 0;
    border-bottom: solid 1px #0d0d0d;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    background: none;
    text-transform: inherit;
    padding: 0;
    border-radius: 0;
    line-height: 1.2; }
  button.btn--rounded,
  .button.btn--rounded,
  .btn.btn--rounded,
  input[type="submit"].btn--rounded,
  input[type="button"].btn--rounded {
    border-radius: 0.2rem; }

.btn--large {
  padding: 1rem 1.4rem; }

.button-wrapper {
  padding-top: 3rem;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap; }

.filter {
  min-height: 100%;
  background: transparent;
  transition: margin 0.5s cubic-bezier(0.59, 0.01, 0.28, 1), transform 0.5s cubic-bezier(0.59, 0.01, 0.28, 1);
  padding-bottom: 0;
  overflow: visible;
  pointer-events: none; }
  .search--no-results .filter {
    display: none; }
  .filter .filter-wrapper {
    background: #f0eceb;
    transition: background-color 0.5s cubic-bezier(0.59, 0.01, 0.28, 1), margin 0.5s cubic-bezier(0.59, 0.01, 0.28, 1);
    height: 75vh;
    position: relative;
    pointer-events: all;
    padding-bottom: 4rem;
    overflow: hidden;
    margin-top: -6rem; }
    .has-filter .filter .filter-wrapper {
      overflow-y: auto;
      overflow-x: hidden; }
    .is-loading-ajax .filter .filter-wrapper {
      cursor: default; }
      .is-loading-ajax .filter .filter-wrapper * {
        pointer-events: none; }
  .filter .filter-container-inner {
    position: relative;
    margin-top: 2.2rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-around;
    flex-direction: row-reverse;
    width: 100%;
    z-index: 1; }
    @media (min-width: 1px) and (max-width: 767px) {
      .filter .filter-container-inner {
        flex-wrap: wrap;
        flex-direction: column-reverse; } }
  .filter ul li li {
    position: relative; }
    @media (min-width: 1px) and (max-width: 767px) {
      .filter ul li li {
        line-height: 1.5; } }
  .filter ul a,
  .filter ul label,
  .filter ul .not-available {
    position: relative;
    display: block;
    text-align: center; }
  .filter ul a,
  .filter ul label {
    cursor: pointer; }
  .filter ul .not-available {
    color: #afa6a5; }
  .filter h3 {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 1.6rem; }
  .filter .selected-filter {
    max-height: 4.6rem;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 1.8rem;
    transform: translateX(-50%);
    overflow: hidden;
    width: 40%; }
    @media (min-width: 1px) and (max-width: 767px) {
      .filter .selected-filter {
        width: 70%;
        transform: translateX(-30%); } }
    .filter .selected-filter a,
    .filter .selected-filter li,
    .filter .selected-filter .btn {
      display: inline-block; }
    .filter .selected-filter a,
    .filter .selected-filter .btn {
      padding: 0 1.5rem;
      text-transform: none; }
  .filter:after {
    content: "Close filter";
    position: absolute;
    top: 12%;
    left: 0;
    width: 100%;
    transform: translate3d(0, -50%, 0);
    text-align: center;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.25s;
    transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1);
    letter-spacing: 0.15rem; }
    .has-active-filter .filter:after {
      content: 'Show results'; }
      html:lang(de) .has-active-filter .filter:after {
        content: 'Ergebnisse zeigen'; }
    html:lang(de) .filter:after {
      content: 'Filter schließen'; }
    .is-loading-ajax .filter:after {
      content: 'Updating …'; }
      html:lang(de) .is-loading-ajax .filter:after {
        content: 'Lädt …'; }
  .has-scrolled .filter .filter-wrapper {
    background: #fff; }
  .has-filter .filter {
    transform: translate3d(0, 0, 0); }
    .has-filter .filter .filter-wrapper {
      background: #fff;
      margin-top: 25vh; }
    .has-filter .filter:after {
      opacity: 1;
      transition-delay: 0.5s;
      transition-duration: 1s; }
  .has-footer-inview .filter .filter-wrapper {
    margin-top: 0; }

body.has-filter {
  overflow-y: hidden; }
  body.has-filter header,
  body.has-filter .content,
  body.has-filter footer {
    transform: translate3d(0, -12rem, 0); }

#side-cover-filter {
  z-index: 110; }
  .has-filter #side-cover-filter {
    opacity: 0.8;
    pointer-events: all;
    cursor: pointer; }
  .is-loading-ajax.has-filter #side-cover-filter {
    opacity: 0.96; }

button.filter-head {
  padding: 1.8rem 0;
  width: 33%;
  transform: translate3d(100%, 0, 0);
  transition-property: transform;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1); }
  .has-filter button.filter-head,
  .has-active-filter button.filter-head {
    transform: translate3d(0, 0, 0); }
  button.filter-head span {
    transition-property: opacity;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1); }
  button.filter-head .filter-btn {
    display: flex;
    justify-content: center; }
    button.filter-head .filter-btn svg {
      width: 1.6rem;
      height: 1.6rem;
      margin-right: 0.2rem;
      transform-origin: center center; }
    body:not(.has-scrolled):not(.has-filter) .wrapper:not(.has-active-filter) button.filter-head .filter-btn {
      opacity: 0; }
    .has-scrolled button.filter-head .filter-btn {
      transition-delay: 1.5s;
      opacity: 1; }
    .has-scrolled .wrapper:not(.has-active-filter) button.filter-head .filter-btn {
      animation: fade-in-filter 2.4s 1.5s ease-in-out both; }
    .has-filter button.filter-head .filter-btn {
      transition-duration: 0s;
      transition-delay: 0s; }
      .has-filter button.filter-head .filter-btn .svg-ico-plus {
        transform: rotate(45deg); }
  button.filter-head .filter-btn-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 200%;
    word-spacing: 0;
    text-transform: none;
    transition-delay: 0.5s; }
    .has-scrolled button.filter-head .filter-btn-text {
      transition-delay: 1s;
      opacity: 0; }
    .has-active-filter button.filter-head .filter-btn-text {
      opacity: 0; }
    .has-filter button.filter-head .filter-btn-text {
      transition-duration: 0s;
      transition-delay: 0s;
      opacity: 0; }

a.btn.remove-all {
  padding: 1.8rem 0;
  width: 33%;
  position: absolute;
  display: none;
  top: 0;
  left: 66%; }
  .has-active-filter a.btn.remove-all {
    display: block; }
    @media (min-width: 1px) and (max-width: 767px) {
      .has-active-filter a.btn.remove-all {
        display: none; } }

.more-brands {
  text-align: center;
  display: block;
  padding: 5vh 0;
  width: 100%; }

.filter-panel--active {
  position: absolute;
  top: 2.5rem;
  left: 50%;
  transform: translate3d(-50%, -40%, 0);
  width: 50%; }
  .filter-panel--active h3 {
    display: none; }
  .filter-panel--active ul {
    max-height: 4.8rem;
    overflow: hidden;
    text-align: center; }
  .filter-panel--active li {
    display: inline-block;
    margin: 0 2rem;
    line-height: 1.6; }
  @media (min-width: 1px) and (max-width: 767px) {
    .filter-panel--active {
      width: 66%;
      left: 66%; }
      .filter-panel--active li {
        margin: 0 1rem; } }

.chosen .btn:after,
.chosen label:after {
  content: "×";
  display: inline-block;
  padding: 0 0.5rem;
  margin-right: -2rem;
  font-size: 1.5rem;
  position: relative; }
  .facet--manufacturer .chosen .btn:after,
  .facet--brand .chosen .btn:after, .facet--manufacturer
  .chosen label:after,
  .facet--brand
  .chosen label:after {
    font-size: 0.6em;
    transform: translate3d(0, -0.2rem, 0); }
  .facet--Style .chosen .btn:after, .facet--Style
  .chosen label:after {
    transform: translate3d(0, -0.1rem, 0); }
  .filter-panel--active .chosen .btn:after, .filter-panel--active
  .chosen label:after {
    font-size: 0.8em;
    transform: translate3d(0, -0.1rem, 0); }

.count {
  display: none; }

.filter-panel {
  margin-bottom: 2rem; }

.facet--manufacturer,
.facet--brand {
  width: 59%; }
  .facet--manufacturer h3,
  .facet--brand h3 {
    width: 25%; }
  .facet--manufacturer ul,
  .facet--brand ul {
    column-count: 2; }

.facet--style {
  width: 20%; }
  .facet--style h3 {
    width: 50%; }

.filter--property {
  position: relative; }
  @media (min-width: 1px) and (max-width: 767px) {
    .filter--property {
      width: 100%; }
      .filter--property h3 {
        width: 100%;
        text-align: left; }
      .filter--property ul {
        column-count: 2; } }

.facet--style ul li:nth-child(3) {
  display: none; }

.filter-close {
  position: fixed;
  bottom: 0;
  left: 5%;
  padding: 1.6rem 0;
  width: 90%;
  background: #fff;
  text-align: center;
  z-index: 120;
  cursor: pointer;
  transition-property: transform;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1);
  transform: translate3d(0, 200%, 0); }
  @media (min-width: 1px) and (max-width: 767px) {
    .filter-close {
      display: none; } }
  .has-filter .filter-close {
    transform: translate3d(0, 0, 0); }

.filter .facet--price,
.filter .facet--immediate_delivery {
  display: none; }

.filter .filter-panel--checkbox {
  display: none; }

.filter #filter button[type="submit"] {
  display: none; }

.mega-dropdown {
  position: absolute;
  left: 50%;
  top: 4rem;
  width: 100vw;
  transform: translateX(-50%) translateY(2rem);
  background: #fff;
  transition: transform 0.5s cubic-bezier(0.17, 0, 0, 1), opacity 0.5s cubic-bezier(0.17, 0, 0, 1), visibility 0s linear 0.5s;
  visibility: hidden;
  opacity: 0; }
  .has-scrolled .mega-dropdown {
    transform: translateX(-50%); }
  body:not(.is-loading-ajax) li:hover .mega-dropdown {
    opacity: 1;
    visibility: visible;
    transition: transform 0.5s cubic-bezier(0.17, 0, 0, 1), opacity 0.25s cubic-bezier(0.17, 0, 0, 1); }
  @media (min-width: 768px) and (max-width: 1200px) {
    .mega-dropdown {
      top: 3rem; } }

.mega-dropdown__inner {
  display: flex;
  padding: 5rem 0; }
  .mega-dropdown__inner > * {
    width: 50%; }
  .mega-dropdown__inner .teaser {
    width: 25%; }
    .mega-dropdown__inner .teaser button {
      bottom: 2rem;
      font-size: inherit; }
    .mega-dropdown__inner .teaser:after {
      display: none; }
  .mega-dropdown__inner ul {
    text-align: center;
    padding: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start; }
    .mega-dropdown__inner ul a:after {
      display: none; }
  .mega-dropdown__inner li {
    font-family: 'NeueHaas-Regular', Helvetica, Arial, sans-serif;
    width: 50%;
    opacity: 0;
    padding: 0 1rem;
    transform: translateY(3rem) scale(0.8);
    transition: transform 0s cubic-bezier(0.17, 0, 0, 1) 0.5s, opacity 0s cubic-bezier(0.17, 0, 0, 1) 0.5s; }
    body:not(.is-loading-ajax) li:hover .mega-dropdown__inner li {
      transition: transform 0.5s cubic-bezier(0.17, 0, 0, 1), opacity 1s cubic-bezier(0.17, 0, 0, 1);
      opacity: 1;
      transform: none; }
      body:not(.is-loading-ajax) li:hover .mega-dropdown__inner li:nth-child(0) {
        transition-delay: 0s; }
      body:not(.is-loading-ajax) li:hover .mega-dropdown__inner li:nth-child(1) {
        transition-delay: 0.03s; }
      body:not(.is-loading-ajax) li:hover .mega-dropdown__inner li:nth-child(2) {
        transition-delay: 0.06s; }
      body:not(.is-loading-ajax) li:hover .mega-dropdown__inner li:nth-child(3) {
        transition-delay: 0.09s; }
      body:not(.is-loading-ajax) li:hover .mega-dropdown__inner li:nth-child(4) {
        transition-delay: 0.12s; }
      body:not(.is-loading-ajax) li:hover .mega-dropdown__inner li:nth-child(5) {
        transition-delay: 0.15s; }
      body:not(.is-loading-ajax) li:hover .mega-dropdown__inner li:nth-child(6) {
        transition-delay: 0.18s; }
      body:not(.is-loading-ajax) li:hover .mega-dropdown__inner li:nth-child(7) {
        transition-delay: 0.21s; }
      body:not(.is-loading-ajax) li:hover .mega-dropdown__inner li:nth-child(8) {
        transition-delay: 0.24s; }
      body:not(.is-loading-ajax) li:hover .mega-dropdown__inner li:nth-child(9) {
        transition-delay: 0.27s; }
      body:not(.is-loading-ajax) li:hover .mega-dropdown__inner li:nth-child(10) {
        transition-delay: 0.3s; }
      body:not(.is-loading-ajax) li:hover .mega-dropdown__inner li:nth-child(11) {
        transition-delay: 0.33s; }
      body:not(.is-loading-ajax) li:hover .mega-dropdown__inner li:nth-child(12) {
        transition-delay: 0.36s; }
      body:not(.is-loading-ajax) li:hover .mega-dropdown__inner li:nth-child(13) {
        transition-delay: 0.39s; }
      body:not(.is-loading-ajax) li:hover .mega-dropdown__inner li:nth-child(14) {
        transition-delay: 0.42s; }
      body:not(.is-loading-ajax) li:hover .mega-dropdown__inner li:nth-child(15) {
        transition-delay: 0.45s; }
      body:not(.is-loading-ajax) li:hover .mega-dropdown__inner li:nth-child(16) {
        transition-delay: 0.48s; }
      body:not(.is-loading-ajax) li:hover .mega-dropdown__inner li:nth-child(17) {
        transition-delay: 0.51s; }
      body:not(.is-loading-ajax) li:hover .mega-dropdown__inner li:nth-child(18) {
        transition-delay: 0.54s; }
      body:not(.is-loading-ajax) li:hover .mega-dropdown__inner li:nth-child(19) {
        transition-delay: 0.57s; }
      body:not(.is-loading-ajax) li:hover .mega-dropdown__inner li:nth-child(20) {
        transition-delay: 0.6s; }
  .mega-dropdown__inner .btn--link {
    font-family: 'NeueHaas-Regular', Helvetica, Arial, sans-serif; }
  .mega-dropdown__inner .mega-dropdown__nav li {
    line-height: 1.5; }
    .mega-dropdown__inner .mega-dropdown__nav li:nth-child(1), .mega-dropdown__inner .mega-dropdown__nav li:nth-child(3) {
      order: -1; }
  .mega-dropdown__inner .teaser {
    opacity: 0;
    transform: scale(1, 0.9);
    transition: transform 0s cubic-bezier(0.17, 0, 0, 1) 0.5s, opacity 0s cubic-bezier(0.17, 0, 0, 1) 0.5s; }
    body:not(.is-loading-ajax) li:hover .mega-dropdown__inner .teaser {
      transition: transform 0.5s cubic-bezier(0.17, 0, 0, 1), opacity 1s cubic-bezier(0.17, 0, 0, 1);
      opacity: 1;
      transform: none; }
      body:not(.is-loading-ajax) li:hover .mega-dropdown__inner .teaser:first-child {
        transition-delay: 0.1s; }

.brand-listing {
  display: flex;
  flex-direction: column; }
  .brand-listing ul {
    flex: 1 1 auto;
    padding-top: 0;
    line-height: 1.5; }
  .brand-listing h4 {
    flex: 0 0 auto;
    text-align: center;
    opacity: 0; }
    body:not(.is-loading-ajax) li:hover .brand-listing h4 {
      transition: opacity 1s cubic-bezier(0.17, 0, 0, 1) 0.5s;
      opacity: 1; }

.store-brands li span {
  opacity: 0.3; }

.teaser--inverted button.btn--link {
  color: #0d0d0d; }
  .teaser--inverted button.btn--link:after {
    background: #0d0d0d; }

.product-image {
  position: relative; }
  .product-image.product-image--container {
    width: 100%;
    padding-bottom: 60%; }
  .product-image.product-image--preview, .product-image.product-image--lazy {
    position: absolute;
    width: 110%;
    height: 110%;
    top: -5%;
    left: -5%;
    overflow: hidden; }
    .no-touch .product-overview a .product-image.product-image--preview,
    .no-touch .product-list--slider a .product-image.product-image--preview, .no-touch .product-overview a .product-image.product-image--lazy,
    .no-touch .product-list--slider a .product-image.product-image--lazy {
      transition-property: transform, opacity, margin;
      transition-duration: 1s;
      transition-timing-function: cubic-bezier(0.17, 0, 0, 1);
      width: 120%;
      height: 120%;
      top: -10%;
      left: -10%;
      transform: scale(0.91); }
    .no-touch .product-overview a:hover .product-image.product-image--preview, .no-touch .product-overview a:hover .product-image.product-image--lazy {
      transform: scale(1) translateY(-1%); }
    .no-touch .product-list--slider a:hover .product-image.product-image--preview, .no-touch .product-list--slider a:hover .product-image.product-image--lazy {
      transform: scale(1.1) translateY(-1.5%); }
  .product-image.product-image--lazy {
    transition-property: transform, opacity;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1);
    opacity: 0; }
    .product-image.product-image--lazy.product-image--loaded {
      opacity: 1; }
      .product-image.product-image--lazy.product-image--loaded:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        box-shadow: inset 0 0 2rem 1rem #f0eceb; }
  .product-image img {
    width: 100%;
    position: relative; }
  .product-image.product-image--empty .ico {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden; }

.header-search {
  transition-property: transform, opacity;
  transition-duration: 0.5s, 0.25s;
  transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1);
  transition-delay: 0s, 0.25s;
  transform: translate3d(0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  height: 7rem; }
  .header-search input[type="search"] {
    position: absolute;
    top: 0;
    left: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    border-color: #c8c3c1;
    border-radius: 0;
    background: #fff;
    padding-left: 0;
    padding-right: 4rem;
    padding-bottom: 0.5rem;
    z-index: 2;
    width: calc(100% - 4rem); }
  .header-search .btn[type="submit"] {
    display: none; }
  .header-search .btn[data-action="dismiss-search"] {
    position: absolute;
    right: 0;
    top: 0;
    height: 4rem;
    width: 4rem;
    padding: 0;
    z-index: 3; }
    .header-search .btn[data-action="dismiss-search"] .svg-ico {
      position: absolute;
      top: 0.6rem;
      left: 1rem; }
    .header-search .btn[data-action="dismiss-search"] span {
      display: inline-block;
      text-indent: -999rem; }
  .header-search .search-hint {
    position: absolute;
    top: 0.5rem;
    left: 0;
    transition-property: transform, opacity;
    transition-duration: 0.5s, 0.25s;
    transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1);
    transition-delay: 0s, 0.25s;
    transform: translate3d(0, 0, 0);
    opacity: 0;
    color: #afa6a5;
    z-index: 1; }
    .header-search .search-hint kbd {
      font-family: inherit;
      font-size: inherit;
      font-style: inherit;
      border-bottom: solid 1px #afa6a5; }
  .header-search form.has-search-hint .search-hint {
    opacity: 1;
    transform: translate3d(0, 4rem, 0); }
  .has-search .header-search {
    opacity: 1;
    transform: translate3d(0, 8rem, 0);
    pointer-events: all; }
    @media (min-width: 768px) and (max-width: 1200px) {
      .has-search .header-search {
        transform: translate3d(0, 7rem, 0); } }
    @media (min-width: 1px) and (max-width: 767px) {
      .has-search .header-search {
        transform: translate3d(0, 5rem, 0); } }
  .has-search.has-scrolled .header-search {
    transform: translate3d(0, 5rem, 0); }
    @media (min-width: 768px) and (max-width: 1200px) {
      .has-search.has-scrolled .header-search {
        transform: translate3d(0, 5rem, 0); } }
    @media (min-width: 1px) and (max-width: 767px) {
      .has-search.has-scrolled .header-search {
        transform: translate3d(0, 5rem, 0); } }

.content-header {
  background: #f0eceb; }
  .content-header:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition-property: height;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1);
    background: #fff; }
    .is-loading-ajax:not(.has-filter) .content-header:before {
      height: 90%; }

.main-container .container {
  transition-property: margin;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1); }
  .is-loading-ajax:not(.has-filter) .main-container .container {
    margin-top: 4rem;
    transition-duration: 0.5s; }
  .has-filter.is-loading-ajax .main-container .container {
    pointer-events: none; }

.loading-overlay {
  transition-property: top, opacity, visibility;
  transition-duration: 0.5s, 0.5s, 0s;
  transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1);
  transition-delay: 0s, 0s, 0.5s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f0eceb;
  z-index: 90;
  opacity: 0;
  visibility: hidden;
  pointer-events: none; }
  @media (min-width: 1px) and (max-width: 767px) {
    .loading-overlay {
      top: 5rem; } }
  @media (min-width: 768px) and (max-width: 1200px) {
    .loading-overlay {
      top: 7rem; }
      .has-scrolled .loading-overlay {
        top: 5rem; } }
  .is-loading-ajax:not(.has-filter) .loading-overlay,
  .is-loading-ajax-slim .loading-overlay {
    transition-property: top, opacity;
    transition-delay: 0s;
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    cursor: default; }
    .is-loading-ajax:not(.has-filter) .loading-overlay .loading-animation,
    .is-loading-ajax-slim .loading-overlay .loading-animation {
      transition-delay: 3s;
      opacity: 1; }
      .is-loading-ajax:not(.has-filter) .loading-overlay .loading-animation span,
      .is-loading-ajax-slim .loading-overlay .loading-animation span {
        animation-play-state: running; }
  .is-loading-ajax-slim .loading-overlay {
    opacity: 0.8; }
  .loading-overlay .loading-animation {
    position: absolute;
    top: 40%;
    left: 50%;
    margin: 0 0 0 -12.5rem;
    opacity: 0;
    transform: scale(0.5); }

.loading-animation {
  display: inline-block;
  width: 25rem;
  height: 6rem;
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-timing-function: linear;
  transition-delay: 0s;
  word-spacing: -0.25rem; }
  .loading-animation span {
    font-size: 4rem;
    font-family: 'NeueHaas-Medium', Helvetica, Arial, sans-serif;
    position: relative;
    display: inline-block;
    animation-name: fade-pulse;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-play-state: paused; }
    .loading-animation span:nth-child(0) {
      animation-delay: 0s; }
    .loading-animation span:nth-child(1) {
      animation-delay: 0.05s; }
    .loading-animation span:nth-child(2) {
      animation-delay: 0.1s; }
    .loading-animation span:nth-child(3) {
      animation-delay: 0.15s; }
    .loading-animation span:nth-child(4) {
      animation-delay: 0.2s; }
    .loading-animation span:nth-child(5) {
      animation-delay: 0.25s; }
    .loading-animation span:nth-child(6) {
      animation-delay: 0.3s; }
    .loading-animation span:nth-child(7) {
      animation-delay: 0.35s; }
    .loading-animation span:nth-child(8) {
      animation-delay: 0.4s; }
    .loading-animation span:nth-child(9) {
      animation-delay: 0.45s; }
    .loading-animation span:nth-child(10) {
      animation-delay: 0.5s; }
    .loading-animation span:nth-child(11) {
      animation-delay: 0.55s; }
    .loading-animation span:nth-child(12) {
      animation-delay: 0.6s; }
  .loading-animation .loading-animation__letter--light {
    font-family: 'NeueHaas-Regular', Helvetica, Arial, sans-serif; }

.notification {
  border: 0.2rem solid #0d0d0d;
  padding: 1rem 1.4rem;
  text-align: center;
  line-height: 2rem;
  margin: 0 0 6rem 0; }
  .notification p {
    margin-bottom: 0; }
  .notification .btn.btn--underlined {
    font-size: inherit;
    color: inherit;
    border-color: currentColor; }
  .notification.notification--large {
    font-style: normal;
    font-size: 1.5rem;
    line-height: 1.3;
    letter-spacing: 0.06rem;
    padding: 3rem 1.5rem;
    text-align: center; }
  .notification.notification--error {
    border-color: #d66146;
    color: #d66146; }
  .notification.notification--hideable {
    max-height: 0;
    opacity: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: max-height 0.5s cubic-bezier(0.59, 0.01, 0.28, 1), opacity 0.5s cubic-bezier(0.59, 0.01, 0.28, 1), margin 0.5s cubic-bezier(0.59, 0.01, 0.28, 1), padding 0.5s cubic-bezier(0.59, 0.01, 0.28, 1); }
    .notification.notification--hideable.is-visible {
      max-height: 32rem;
      opacity: 1;
      margin-bottom: 6rem;
      padding-top: 3rem;
      padding-bottom: 3rem; }

.brand-overview {
  padding-bottom: 16rem; }
  .brand-overview h4 {
    padding-bottom: 6rem;
    max-width: 34rem;
    margin: 0 auto; }
    @media (min-width: 1px) and (max-width: 900px) {
      .brand-overview h4 {
        padding-bottom: 4rem;
        max-width: 20rem; } }
  .brand-overview .brand-overview-list {
    padding-bottom: 8rem; }
    .brand-overview .brand-overview-list li {
      transition-property: opacity;
      transition-duration: 0.25s;
      transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1);
      opacity: 1; }
      .brand-overview .brand-overview-list li.inactive {
        opacity: 0.25; }
    @media (min-width: 1px) and (max-width: 900px) {
      .brand-overview .brand-overview-list {
        padding-bottom: 4rem; } }
  .brand-overview .brand-overview-list h3 {
    cursor: default; }
  .brand-overview .brand-overview-filter {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 2rem; }
    .brand-overview .brand-overview-filter li {
      margin: 0 2rem; }
    .brand-overview .brand-overview-filter button {
      position: relative; }
      .brand-overview .brand-overview-filter button:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        height: 0.1rem;
        width: 0;
        opacity: 0;
        transform: translateX(-50%);
        background: #0d0d0d;
        transition-property: width, opacity;
        transition-duration: 0.25s;
        transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1); }
    .brand-overview .brand-overview-filter .active button:after,
    .brand-overview .brand-overview-filter .active a:after {
      width: 100%;
      opacity: 1; }
    @media (min-width: 1px) and (max-width: 900px) {
      .brand-overview .brand-overview-filter {
        display: block; }
        .brand-overview .brand-overview-filter li {
          margin-bottom: 0.4rem;
          text-align: center; } }
  .brand-overview .brand-overview-hint {
    text-align: center;
    max-width: 34rem;
    margin: 0 auto; }
    @media (min-width: 1px) and (max-width: 900px) {
      .brand-overview .brand-overview-hint {
        max-width: 20rem; } }
  .brands .brand-overview {
    margin-top: 8rem; }
  @media (min-width: 1px) and (max-width: 900px) {
    .brand-overview {
      padding-bottom: 8rem; } }

.cookie-hint {
  transform: translate3d(0, 100%, 0);
  transition-property: transform;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #944b3e;
  z-index: 200; }
  .cookie-hint .container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.8rem 0 1.2rem 0; }
  .cookie-hint p,
  .cookie-hint .cookie-hint-actions {
    display: inline-block; }
  .cookie-hint p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center; }
  .is-loaded .cookie-hint {
    transform: translate3d(0, 0, 0); }
  .cookie-hint.is-dismissed {
    transform: translate3d(0, 100%, 0); }
  .cookie-hint button {
    margin-left: 0.6rem; }
  @media (min-width: 1px) and (max-width: 439px) {
    .cookie-hint .cookie-hint-actions {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap; }
    .cookie-hint button {
      margin-left: 0;
      margin-top: 0.6rem; } }

.product-label {
  position: absolute;
  display: inline-block;
  top: 4rem;
  right: 3rem;
  padding: 0.8rem 1.4rem;
  border-radius: 0.2rem;
  font-family: 'NeueHaas-Medium', Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  font-weight: 100;
  font-variant: normal;
  transition: transform 0.5s cubic-bezier(0.17, 0, 0, 1), opacity 0.5s cubic-bezier(0.17, 0, 0, 1);
  transform: translate3d(0, 0, 0);
  opacity: 1; }
  .product-label.product-label--limited, .product-label.product-label--must-try, .product-label.product-label--on-sale {
    background-color: #944b3e; }
  .product-label.product-label--clip-on {
    background-color: #968b88; }
  .no-touch a:hover .product-label {
    opacity: 0;
    transform: translate3d(0.2rem, -0.6rem, 0);
    transition: transform 0.25s cubic-bezier(0.17, 0, 0, 1), opacity 0.25s cubic-bezier(0.17, 0, 0, 1); }
  @media (min-width: 1px) and (max-width: 439px) {
    .product-label {
      top: 4rem;
      right: 2rem; } }
  @media (min-width: 440px) and (max-width: 767px) {
    .product-label {
      top: 2rem;
      right: 1rem;
      font-size: 1.1rem; } }
  @media (min-width: 768px) and (max-width: 1200px) {
    .product-label {
      top: 3.6rem;
      right: 3rem; } }
  @media (min-width: 1260px) and (max-width: 1899px) {
    .product-label {
      top: 5.6rem;
      right: 5rem; } }
  @media (min-width: 1900px) {
    .product-label {
      top: 5rem;
      right: 5rem; } }
  .product-list--slider .product-label {
    top: 3.6rem;
    right: 2.5rem; }

.ri-debug {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1000;
  display: inline-block;
  transform: translateX(-50%);
  border: solid 1px #000000;
  background-color: rgba(255, 255, 255, 0.8); }
  .ri-debug li {
    position: relative;
    display: inline-block;
    font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace;
    font-size: 12px;
    font-weight: normal;
    color: #000000;
    padding: 2px; }
    .ri-debug li:not(:first-child) {
      margin-left: 5px; }
    .ri-debug li.width:before {
      content: '￩￫'; }
    .ri-debug li.height {
      padding-left: 10px; }
      .ri-debug li.height:before {
        position: absolute;
        left: 0;
        top: 0;
        content: '￪'; }
      .ri-debug li.height:after {
        position: absolute;
        left: 0;
        bottom: 0;
        content: '￬'; }

.sw-sidebar {
  display: none; }

.checkout-steps {
  display: inline-block;
  position: absolute;
  top: 9rem;
  left: 0;
  text-align: center;
  width: 100%; }

.checkout-steps-list {
  display: flex;
  list-style-type: none;
  margin: 0;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center; }

.checkout-steps-item {
  position: relative;
  white-space: nowrap;
  margin: 0 1rem; }
  .checkout-steps-item:after {
    content: ''; }
  .checkout-steps-item.is-active:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -0.4rem;
    left: 50%;
    width: 100%;
    height: 0.1rem;
    opacity: 1;
    transform: translateX(-50%);
    background: #0d0d0d; }
  @media (min-width: 1px) and (max-width: 439px) {
    .checkout-steps-item:not(.is-active) .checkout-steps-name {
      display: none; } }

.checkout-steps-number,
.checkout-steps-name {
  display: inline-block; }

.checkout-steps-number {
  margin-right: 0.5rem; }

.checkout-steps-link {
  display: block;
  text-decoration: none; }

.mce-content-body {
  padding: 2rem;
  background-color: #f0eceb; }
  .mce-content-body.col--main {
    width: 100%;
    margin: 0; }

.info-panel {
  margin-bottom: 5rem; }
  .info-panel .info-panel-headline {
    text-align: center; }
    .info-panel .info-panel-headline h2 {
      margin-bottom: 2rem; }
  .info-panel .info-panel-body {
    padding-bottom: 2rem;
    text-align: center; }
    .info-panel .info-panel-body p {
      margin-bottom: 0; }
  .info-panel .info-panel-action {
    text-align: center; }
  .info-panel.info-panel--box .info-panel-body {
    padding: 2rem;
    background-color: #fff;
    text-align: left; }
    .info-panel.info-panel--box .info-panel-body h3 {
      text-align: left; }
    .info-panel.info-panel--box .info-panel-body.info-panel-body--centered {
      text-align: center; }
  .info-panel.info-panel--box .info-panel-action {
    padding: 0 2rem 2rem 2rem;
    background-color: #fff; }
  @media (min-width: 1px) and (max-width: 767px) {
    .info-panel.info-panel--box .info-panel-body.info-panel-body--centered {
      text-align: left; } }

.info-panel-group {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap; }
  .info-panel-group .info-panel {
    display: flex;
    flex-direction: column;
    width: calc(50% - 1rem); }
    .info-panel-group .info-panel .info-panel-body {
      flex: 1; }
  @media (min-width: 1px) and (max-width: 767px) {
    .info-panel-group {
      display: block; }
      .info-panel-group .info-panel {
        display: block;
        width: 100%; } }

.accordion {
  display: block; }
  .accordion .accordion__body {
    overflow: hidden;
    width: 100%; }
  .accordion .accordion__body__inner {
    display: block;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 4rem; }
  .accordion ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; }
    .accordion ul li {
      display: block;
      font-size: 1.8rem; }
      @media (min-width: 768px) {
        .accordion ul li {
          font-size: 2.2rem; } }
    .accordion ul:not(:first-child) {
      margin-top: 2rem; }
  .accordion[aria-expanded="false"] .accordion__body {
    max-height: 0;
    visibility: hidden; }
  .accordion[aria-expanded="true"] .accordion__body {
    max-height: none; }

.gtm-tracking-tag,
.fb-tracking-tag {
  display: none; }

.country-selection-search {
  position: fixed;
  top: 4rem;
  left: 7rem;
  right: 7rem;
  padding-top: 4rem;
  padding-bottom: 1rem;
  background: #944b3e; }
  @media (min-width: 1px) and (max-width: 767px) {
    .country-selection-search {
      left: 2rem;
      right: 2rem;
      top: 2rem; } }

.country-selection-search__input {
  background: none; }

.country-selection-list {
  list-style: none;
  padding: 0;
  margin: 6rem 0 0 0; }

.country-selection-list__country-list {
  list-style: none;
  padding: 0;
  margin: 0 -1rem 0 -1rem; }

.country-selection-list__group {
  display: block;
  margin-top: 1rem;
  padding: 0 1rem; }
  .country-selection-list__group h3 {
    color: #afa6a5;
    font-size: 1.2rem;
    height: 2.5rem;
    line-height: 2.5rem; }
  .country-selection-list__group[aria-hidden="true"] {
    display: none; }

.country-selection-list__country {
  height: 2.5rem;
  line-height: 2.5rem;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 1.5rem;
  padding: 0 4rem 0 1rem;
  margin-top: 1px; }
  .country-selection-list__country:hover {
    background: rgba(255, 255, 255, 0.1); }
  .country-selection-list__country[aria-selected="true"] {
    background: rgba(255, 255, 255, 0.2); }
  .country-selection-list__country.has-store {
    background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="5 -5 34 34"><path d="M29.3 21.7H14.7l-2.5-15h19.6l-2.5 15zm-12.9-2h11.2l1.9-11H14.6l1.8 11z"/><path d="M24.6 11.8c-.6 0-1-.4-1-1V2.9c0-.5-.5-.5-.7-.5h-1.8c-.7 0-.7.4-.7.5v7.9c0 .6-.4 1-1 1s-1-.4-1-1V2.9C18.4 1 20 .4 21.1.4h1.8c2 0 2.7 1.5 2.7 2.5v7.9c0 .5-.5 1-1 1z"/></svg>');
    background-position: 98% 50%;
    background-size: 2.2rem 2.2rem;
    background-repeat: no-repeat; }
  .country-selection-list__country[aria-hidden="true"] {
    display: none; }

.shipping-selector {
  padding-bottom: 2rem;
  text-align: left; }
  .shipping-selector h3 {
    font-size: 1.8rem;
    text-align: left;
    margin: 1.5rem 0 0.75rem 0; }
    .shipping-selector h3 small {
      font-size: 1.5rem;
      color: #757a88; }
  .shipping-selector strong {
    display: inline;
    text-align: left;
    font-weight: bold; }
  .shipping-selector p {
    margin: 0 0 0.25rem 0; }
  .shipping-selector ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .shipping-selector li {
    display: block; }
  .shipping-selector .shipping-selector__result {
    margin-top: 1rem;
    transition: transform 0.5s cubic-bezier(0.59, 0.01, 0.28, 1), opacity 0.5s cubic-bezier(0.59, 0.01, 0.28, 1); }
  .shipping-selector[aria-busy="true"] .shipping-selector__result {
    opacity: 0;
    transform: translate3d(0, 0.5rem, 0); }

.frame-notification {
  display: block;
  width: 100%;
  transition: opacity 0.25s cubic-bezier(0.59, 0.01, 0.28, 1), padding 0.25s cubic-bezier(0.59, 0.01, 0.28, 1); }
  .frame-notification .form-grid-row {
    transition: opacity 0.25s cubic-bezier(0.59, 0.01, 0.28, 1), max-height 0.25s cubic-bezier(0.59, 0.01, 0.28, 1);
    max-height: 64rem; }
  .frame-notification .form-grid-row + .form-grid-row {
    margin-top: 1rem; }
  .frame-notification .advanced-checkbox label {
    font-size: 1.2rem;
    margin-left: 2rem; }
  .frame-notification .btn {
    transition: opacity 0.25s cubic-bezier(0.59, 0.01, 0.28, 1); }
  .frame-notification.is-success .form-grid-row {
    height: 0;
    opacity: 0; }
  .frame-notification.is-success .btn {
    opacity: 0;
    pointer-events: none; }

.frame-notification__intro {
  overflow: hidden;
  max-height: 8rem;
  transition: max-height 0.25s cubic-bezier(0.59, 0.01, 0.28, 1), opacity 0.25s cubic-bezier(0.59, 0.01, 0.28, 1); }
  .frame-notification__intro h3 {
    font-size: 1.2rem; }

.frame-notification__response {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.25s cubic-bezier(0.59, 0.01, 0.28, 1), opacity 0.25s cubic-bezier(0.59, 0.01, 0.28, 1), color 0.25s cubic-bezier(0.59, 0.01, 0.28, 1); }
  .frame-notification__response p {
    font-size: 1.5rem; }
  .is-success .frame-notification__response,
  .is-error .frame-notification__response {
    max-height: 10rem;
    opacity: 1; }
  .is-error .frame-notification__response {
    color: #fff; }
  .is-success .frame-notification__response {
    background-color: #fff;
    padding: 1.5rem; }
    .is-success .frame-notification__response p {
      text-align: center; }

.frame-notification__fieldset {
  margin-top: 1rem; }

.frame-notification-overview {
  padding-bottom: 4rem; }
  .frame-notification-overview h2 {
    margin-top: 4rem; }
  .frame-notification-overview table {
    width: 100%; }
  .frame-notification-overview th {
    text-align: left; }
  .frame-notification-overview th,
  .frame-notification-overview td {
    margin: 1px;
    padding: 0.5rem;
    font-size: 1.5rem; }
  .frame-notification-overview th {
    background: #944b3e;
    color: #fff; }
  .frame-notification-overview tr:nth-child(even) td {
    background: #fff; }
  .frame-notification-overview tr:nth-child(odd) td {
    background: rgba(200, 195, 193, 0.5); }

.free-shipping-banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 6rem;
  background-color: #944b3e;
  transition: transform 0.5s cubic-bezier(0.59, 0.01, 0.28, 1);
  transform: translate3d(0, -100%, 0);
  z-index: 35; }
  .has-free-shipping-banner .free-shipping-banner {
    transform: translate3d(0, 0, 0); }
  @media (min-width: 1px) and (max-width: 439px) {
    .free-shipping-banner {
      height: 6rem; } }
  @media (min-width: 1260px) and (max-width: 1899px) {
    .free-shipping-banner {
      height: 4rem; } }
  @media (min-width: 1900px) {
    .free-shipping-banner {
      height: 4rem; } }

.free-shipping-banner__container {
  display: flex;
  height: 100%;
  align-items: center; }
  .free-shipping-banner__container p {
    flex: 1 1 auto;
    text-align: center;
    margin: 0;
    font-size: 1.5rem; }
    @media (min-width: 1px) and (max-width: 439px) {
      .free-shipping-banner__container p {
        font-size: 1.2rem; } }

.cookie-consent {
  position: fixed;
  right: 4rem;
  bottom: 4rem;
  width: 50rem;
  padding: 2rem 3rem;
  background-color: #c8c3c1;
  z-index: 200;
  transition: transform cubic-bezier(0.59, 0.01, 0.28, 1) 0.5s, opacity cubic-bezier(0.59, 0.01, 0.28, 1) 0.5s; }
  .cookie-consent[aria-hidden="true"] {
    transform: translate3d(0, 2rem, 0);
    opacity: 0;
    pointer-events: none; }
  @media (min-width: 1px) and (max-width: 767px) {
    .cookie-consent {
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      padding: 1.5rem 2rem 0 2rem;
      max-height: 60vh;
      overflow-y: scroll;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      overflow-scrolling: touch;
      overscroll-behavior: contain; } }

.cookie-consent__intro h3 {
  font-size: 1.5rem; }

.cookie-consent__intro p {
  font-size: 1.2rem;
  margin-top: 1rem; }

.cookie-consent__settings {
  margin-top: 3rem; }
  .cookie-consent__settings ul {
    display: flex;
    flex-direction: column; }
  .cookie-consent__settings li + li {
    margin-top: 1rem; }

.cookie-consent__footer {
  margin-top: 3rem; }
  .cookie-consent__footer ul {
    display: flex;
    justify-content: flex-start; }
  @media (min-width: 768px) {
    .cookie-consent__footer li + li {
      margin-left: 1.5rem; } }
  @media (min-width: 1px) and (max-width: 767px) {
    .cookie-consent__footer {
      position: sticky;
      bottom: 0;
      background: #c8c3c1;
      padding: 2rem 0 1.5rem 0; }
      .cookie-consent__footer:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 2rem;
        margin-top: -2rem;
        background: linear-gradient(0deg, #c8c3c1, rgba(200, 195, 193, 0));
        pointer-events: none; }
      .cookie-consent__footer ul {
        flex-direction: column-reverse; }
      .cookie-consent__footer li:first-child {
        margin-top: 1rem; }
      .cookie-consent__footer .btn {
        width: 100%; } }

.modal-window {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 150;
  background: rgba(175, 166, 165, 0.8);
  transition: opacity 0.5s cubic-bezier(0.59, 0.01, 0.28, 1);
  display: flex;
  justify-content: center;
  align-items: center; }
  .modal-window[aria-hidden="true"] {
    pointer-events: none;
    opacity: 0; }
  .modal-window[aria-hidden="false"] {
    cursor: pointer;
    opacity: 1; }
  .ios .modal-window {
    display: none; }

.modal-window__container {
  position: relative;
  width: 80rem;
  height: 60rem;
  max-width: 90vw;
  max-height: 80vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem;
  opacity: 0;
  transform: translate3d(0, 2rem, 0);
  transition: opacity 0.5s cubic-bezier(0.59, 0.01, 0.28, 1), transform 0.5s cubic-bezier(0.59, 0.01, 0.28, 1); }
  [aria-hidden="false"] .modal-window__container {
    transform: none;
    opacity: 1; }
  @media (min-width: 1px) and (max-width: 767px) {
    .modal-window__container {
      padding: 1.5rem; } }

.modal-window__header {
  padding-right: 3rem; }

.modal-window__content {
  flex: 1 1 auto;
  margin-top: 1rem;
  position: relative; }
  .ios .modal-window__content {
    overflow: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch; }

.modal-window__iframe {
  border: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.modal-window__close {
  position: absolute;
  top: 2rem;
  right: 3rem;
  width: auto; }
  .modal-window__close span {
    display: inline-block;
    text-indent: -999rem; }
  .modal-window__close .svg-ico {
    width: 2.8rem;
    height: 2.8rem; }
  @media (min-width: 1px) and (max-width: 767px) {
    .modal-window__close {
      top: 1rem;
      right: 1.5rem; } }

.has-modal-window {
  overflow: hidden; }

.content-header--home {
  margin-bottom: -16rem; }
  @media (min-width: 1px) and (max-width: 900px) {
    .content-header--home {
      min-height: 40rem;
      margin-bottom: -30rem; } }

.shop-teaser .box {
  order: 1; }

.shop-teaser .box--welcome {
  margin-top: -12rem;
  width: 36%;
  order: 2; }
  .shop-teaser .box--welcome h4 {
    margin-bottom: 1rem; }
  .shop-teaser .box--welcome .specs-book-appointment {
    margin-top: 2rem;
    text-align: center; }
  @media (min-width: 1px) and (max-width: 900px) {
    .shop-teaser .box--welcome {
      order: 1;
      width: 60%;
      margin: 0 0 6rem 20%; } }
  @media (min-width: 1px) and (max-width: 439px) {
    .shop-teaser .box--welcome {
      width: 90%;
      margin: 0 0 4rem 5%; } }
  @media (min-width: 1260px) and (max-width: 1899px) {
    .shop-teaser .box--welcome {
      margin-top: -10rem;
      width: 34%;
      margin-left: 2%; } }
  @media (min-width: 1900px) {
    .shop-teaser .box--welcome {
      margin-top: -10rem;
      width: 34%;
      margin-left: -2%; } }
  @media (min-width: 1201px) and (max-width: 1900px) {
    .shop-teaser .box--welcome .specs-book-appointment {
      display: none; } }
  @media (min-width: 1900px) {
    .shop-teaser .box--welcome .specs-book-appointment {
      display: none; } }

.store-teaser {
  padding-bottom: 16rem; }
  .store-teaser h3 {
    margin-bottom: 0.4rem; }
  .store-teaser .opening-hours {
    padding-bottom: 8rem; }
  .store-teaser .box-container .box:nth-child(2) {
    width: 33%; }
  .store-teaser .text-box {
    padding-top: 4rem;
    text-align: center; }
    .store-teaser .text-box h2 {
      margin-bottom: 1rem; }
    .store-teaser .text-box > div {
      margin-bottom: 4rem; }
  @media (min-width: 1px) and (max-width: 900px) {
    .store-teaser {
      padding-bottom: 8rem; } }

.login-box h2:first-of-type {
  margin-top: 0rem; }

.login-box h2 {
  margin-top: 5rem;
  margin-bottom: 1.4rem; }

.login-box p, .login-box ul {
  opacity: 0.7;
  line-height: 2rem;
  margin-bottom: 1rem; }

.login-box p + ul {
  margin-top: -0.8rem; }

.login-box ul.list-cirlce {
  list-style: disc;
  list-style-position: inside;
  margin-bottom: 2rem; }

.login-box input.input-error,
.login-box .has-error {
  border: 0.2rem solid #BC0B0B; }

.login-box button,
.login-box input {
  margin-bottom: 1rem; }

.login-box button[type="submit"] {
  width: 100%; }

.login-box button.light {
  opacity: 0.5; }

.login-box form.is-loading button {
  opacity: 0.7;
  pointer-events: none; }

.login-box .error-message {
  color: #BC0B0B; }

.login-box [name="login-form"],
.login-box [name="forgot-password-form"],
.login-box [name="signup-form"] {
  margin-bottom: 5rem; }

.login-box [data-action="login"] .signup-form-area {
  display: none; }

.login-box [data-action="login"] .login-form-area {
  display: block; }

.login-box [data-action="login"] .forgot-password-form-area {
  display: none; }

.login-box [data-action="signup"] .signup-form-area {
  display: block; }

.login-box [data-action="signup"] .login-form-area {
  display: none; }

.login-box [data-action="signup"] .forgot-password-form-area {
  display: none; }

.login-box [data-action="forgot-password"] .signup-form-area {
  display: none; }

.login-box [data-action="forgot-password"] .login-form-area {
  display: none; }

.login-box [data-action="forgot-password"] .forgot-password-form-area {
  display: block; }

.login-box.form--dark .error-message {
  color: #fff; }

.login-box.form--dark input.input-error,
.login-box.form--dark .has-error {
  border-color: #fff; }

.product-list {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  padding-top: 4rem; }
  .product-list.product-list--slider {
    display: block;
    height: 26rem;
    margin: 6rem -5vw 8rem -5vw;
    padding: 0;
    width: 100vw;
    overflow: hidden; }
    .product-list.product-list--slider .product-list-slider-wrapper {
      display: block;
      white-space: nowrap;
      overflow: visible;
      position: relative; }
    .product-list.product-list--slider article {
      display: inline-block;
      width: 30rem;
      padding-bottom: 0; }
      .product-list.product-list--slider article:first-child {
        margin-left: 5vw; }
      .product-list.product-list--slider article:last-child {
        margin-right: 5vw; }

.mobile .product-list--slider {
  height: 24rem;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch; }

.product-overview-hero {
  padding-bottom: 0; }
  .product-overview-hero .img-box h2 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    width: 40%;
    bottom: 25%;
    left: 30%;
    text-align: center; }
    .product-overview-hero .img-box h2 i {
      display: block; }
    .product-overview-hero .img-box h2 span {
      width: 100%;
      border: 0.2rem solid #0d0d0d;
      margin-top: 1rem;
      padding: 2rem 2rem; }
    @media (min-width: 1px) and (max-width: 900px) {
      .product-overview-hero .img-box h2 {
        width: 50%;
        left: 25%; } }
    @media (min-width: 1px) and (max-width: 439px) {
      .product-overview-hero .img-box h2 {
        width: 80%;
        left: 10%;
        bottom: 1rem; } }
  .product-overview-hero.product-overview-hero--inverted h2 {
    color: #fff; }
    .product-overview-hero.product-overview-hero--inverted h2 span {
      border-color: #fff; }

.product-teaser {
  position: relative;
  width: 33%;
  padding: 0 2rem 6rem 2rem;
  background: #f0eceb; }
  .product-teaser img {
    width: 100%; }
  .product-teaser h3 {
    text-align: center;
    position: relative; }
  .product-teaser h4 {
    text-align: center;
    margin-top: -1.5rem;
    position: relative; }
  @media (min-width: 1px) and (max-width: 439px) {
    .product-teaser {
      width: 100%;
      padding: 0 0 6rem 0; } }
  @media (min-width: 440px) and (max-width: 767px) {
    .product-teaser {
      width: 50%;
      padding: 0 1rem 6rem 1rem; } }
  @media (min-width: 768px) and (max-width: 900px) {
    .product-teaser {
      width: 50%;
      padding: 0 1.5rem 6rem 1.5rem; } }
  @media (min-width: 1900px) {
    .product-teaser {
      width: 25%;
      padding: 0 2rem 6rem 2rem; } }

.promotion-teaser {
  position: relative;
  width: 33%;
  padding: 0 2rem 6rem 2rem; }

.product {
  width: 100%; }
  @media (min-width: 768px) and (max-width: 1900px) {
    .product {
      width: 70%;
      margin-left: 25%; } }
  @media (min-width: 1260px) {
    .product {
      width: 60%;
      margin-left: 30%; } }
  @media (min-width: 1900px) {
    .product {
      margin-left: 25%; } }
  @media (min-width: 1px) and (max-width: 900px) {
    .product {
      width: 100%;
      margin-left: 0; } }

article.product {
  padding: 0 0 4rem 0; }
  article.product .product-image--container,
  article.product .product-description {
    margin: 2rem 0; }

@media (min-width: 900px) {
  .product-info-wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: calc(8rem + 6vh);
    min-height: calc(90vh - 8rem);
    left: 0;
    width: 18rem;
    z-index: 1; }
    .has-extended-height .product-info-wrapper {
      position: fixed;
      left: 5%; } }

.product-credentials h1 {
  margin-bottom: 2rem;
  width: 140%;
  font-size: 2.6rem; }

.product-credentials h2 {
  margin-bottom: 0;
  font-size: 2.6rem; }

.product-credentials .product-price--regular {
  padding-right: 0.4rem;
  display: inline-block; }

@media (min-width: 1px) and (max-width: 900px) {
  .product-credentials {
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    display: flex;
    justify-content: space-between; }
    .product-credentials .product-title {
      width: 49%; }
    .product-credentials .product-price {
      text-align: right;
      width: 49%; }
    .product-credentials h1 {
      margin-bottom: 1.4rem;
      width: auto;
      font-size: 2.2rem; }
    .product-credentials h2 {
      font-size: 2.2rem; } }

@media (min-width: 768px) and (max-width: 900px) {
  .product-credentials {
    width: 80%;
    margin: 0 auto; } }

.product-credentials ins, .product-credentials del {
  text-decoration: none; }

.product-credentials del .product-price--regular {
  text-decoration: line-through; }

.tax-notice {
  display: block;
  line-height: 1.5; }
  .tax-notice a {
    color: inherit;
    border-bottom: 1px solid rgba(13, 13, 13, 0.5); }
  @media (min-width: 1px) and (max-width: 900px) {
    .tax-notice {
      margin-top: 1.4rem; } }

@media (min-width: 900px) {
  .product-form {
    margin-top: 4rem; } }

@media (min-width: 1px) and (max-width: 900px) {
  .product-form {
    position: relative;
    bottom: auto;
    left: auto;
    width: auto; } }

@media (min-width: 768px) and (max-width: 900px) {
  .product-form {
    width: 80%;
    margin: 0 auto; } }

.product-form label {
  padding: 0 0 0.4rem 0; }

.product-form select {
  width: 100%;
  background: transparent url("../images/icons/specs-dropdown.svg") no-repeat right center;
  background-size: 1rem 1rem;
  padding-right: 2rem; }
  .product-form select::-ms-expand {
    display: none; }

.product-form .product-color {
  position: relative; }
  .product-form .product-color:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 2rem;
    width: 3rem;
    height: 3rem;
    background-image: linear-gradient(90deg, rgba(240, 236, 235, 0), #f0eceb);
    pointer-events: none; }

.product-form button,
.product-form .notification {
  width: 100%;
  margin: 0 0 4rem 0; }
  @media (min-width: 1px) and (max-width: 900px) {
    .product-form button,
    .product-form .notification {
      margin-top: 0.2rem;
      padding: 1.4rem 1.4rem;
      margin-bottom: 3rem;
      width: 20rem; } }
  @media (min-width: 1px) and (max-width: 439px) {
    .product-form button,
    .product-form .notification {
      width: 100%;
      margin-top: 0; } }

.product-form .form-loading button[type="submit"] {
  opacity: 0.5; }

.product-form .form-loading button[type="submit"] {
  -webkit-transition: opacity 0.5s; }

.product-form .add-to-cart button {
  margin-bottom: 1.4rem; }

.product-form .add-to-cart .direct-delivery-hint {
  margin-bottom: 3rem; }
  .product-form .add-to-cart .direct-delivery-hint p {
    font-size: 1.2rem; }

form .product-color {
  margin-bottom: 1.4rem; }
  @media (min-width: 1px) and (max-width: 900px) {
    form .product-color {
      width: 20rem;
      border-bottom: 0.2rem solid #0d0d0d;
      padding-bottom: 0.5rem; }
      .product-variation .product-type-variable form .product-color {
        margin-right: calc(100% - 20rem); } }
  @media (min-width: 1px) and (max-width: 439px) {
    form .product-color {
      width: 100%;
      margin-bottom: 1rem;
      padding-top: 0.4rem; }
      .product-variation article:not(.product-type-variable) form .product-color {
        border-bottom: none; }
        .product-variation article:not(.product-type-variable) form .product-color label {
          display: block;
          border-bottom: 0.2rem solid #0d0d0d;
          padding-bottom: 0.6rem;
          margin-bottom: 0.8rem; } }

form .product-variants {
  border-top: 0.2rem solid #0d0d0d;
  margin-top: -0.4rem;
  margin-bottom: 1.6rem; }
  form .product-variants label {
    margin-top: 1rem;
    margin-bottom: 0.4rem;
    display: block; }
  form .product-variants .product-variant-property {
    padding: 0.2rem 0.6rem;
    background: none;
    border: 0.2rem solid #c8c3c1;
    color: #ae9994;
    width: auto;
    margin-bottom: 0.4rem;
    margin-right: 0.4rem;
    display: inline-block; }
  form .product-variants .product-variant-property.is-active {
    border: 0.2rem solid #0d0d0d;
    color: #0d0d0d; }
  form .product-variants .product-variant-property.out-of-stock {
    position: relative;
    border: 0.2rem solid rgba(200, 195, 193, 0.5);
    color: rgba(174, 153, 148, 0.5);
    cursor: default; }
    form .product-variants .product-variant-property.out-of-stock:after {
      content: "out of stock";
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 0.2rem 1rem;
      white-space: nowrap;
      background: #fff;
      font-size: 0.9rem;
      text-transform: none;
      letter-spacing: 0;
      color: #0d0d0d;
      opacity: 0;
      transform: translate3d(-50%, -50%, 0);
      transition-property: transform, opacity;
      transition-duration: 0.25s;
      transition-timing-function: cubic-bezier(0.17, 0, 0, 1);
      pointer-events: none;
      z-index: 1; }
      html:lang(de) form .product-variants .product-variant-property.out-of-stock:after {
        content: "vergriffen"; }
    .no-touch form .product-variants .product-variant-property.out-of-stock:hover:after {
      transition-property: transform, opacity;
      opacity: 1;
      transform: rotate(-12deg) translate3d(-46%, -90%, 0); }
  @media (min-width: 1px) and (max-width: 900px) {
    form .product-variants {
      width: 20rem;
      border-top: none;
      margin-top: 0; }
      form .product-variants label {
        margin-top: 0;
        padding-bottom: 0; } }
  @media (min-width: 1px) and (max-width: 439px) {
    form .product-variants {
      width: 100%; } }

@media (min-width: 1px) and (max-width: 900px) {
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start; } }

@media (min-width: 1px) and (max-width: 439px) {
  form {
    display: block; } }

.product-variant-property {
  display: none; }

.product-variant-property.active {
  display: block; }

.product-features {
  min-height: 6rem; }
  .product-features label {
    display: none; }
  @media (min-width: 1px) and (max-width: 900px) {
    .product-features {
      margin-bottom: 4rem;
      min-height: 0;
      width: 100%; }
      .product-features ul.list--bullet {
        display: flex;
        justify-content: center;
        list-style: none;
        flex-flow: row wrap; }
        .product-features ul.list--bullet li {
          padding: 0.5rem 1rem;
          text-align: center;
          white-space: nowrap; }
          .product-features ul.list--bullet li:before {
            padding-right: 0.4rem; } }

.product-text,
.product .img-box.color {
  margin: 0 auto 4rem auto;
  text-align: center; }
  @media (min-width: 768px) and (max-width: 1900px) {
    .product-text,
    .product .img-box.color {
      width: 80%; } }
  @media (min-width: 1260px) {
    .product-text,
    .product .img-box.color {
      width: 83.333%; } }
  @media (min-width: 1900px) {
    .product-text,
    .product .img-box.color {
      width: 66.666%; } }
  @media (min-width: 1px) and (max-width: 767px) {
    .product-text,
    .product .img-box.color {
      padding: 0 8%; } }

.product-description {
  position: relative; }
  .product-description .product-image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; }
  @media (min-width: 1px) and (max-width: 767px) {
    .product-description {
      padding-bottom: 0; }
      .product-description .product-image {
        position: relative;
        bottom: auto;
        left: auto;
        width: auto; } }

.product-specification {
  display: flex;
  justify-content: center;
  flex-flow: row wrap; }
  .product-specification > div {
    width: 25%;
    text-align: center;
    padding: 0 1rem;
    margin-bottom: 2rem; }
    @media (min-width: 1px) and (max-width: 767px) {
      .product-specification > div {
        width: 45%; } }

.product-size {
  display: block;
  padding: 0 10%;
  margin: 4rem 0; }
  .product-size .size {
    display: none; }
  .product-size .frame-sketch-container {
    width: 100%;
    position: relative;
    transform: scale(0.8); }
    .product-size .frame-sketch-container .frame-sketch {
      display: block; }
    .product-size .frame-sketch-container .frame-sketch-inner {
      position: relative; }
    .product-size .frame-sketch-container .frame-sketch-svg {
      width: 100%;
      height: 100%; }
  .product-size .frame-sketch-svg text {
    font-size: 20px;
    letter-spacing: 0.06em; }
  @media (min-width: 1260px) {
    .product-size .frame-sketch-container {
      transform: scale(0.7); } }
  @media (min-width: 768px) {
    .product-size .frame-sketch-container {
      padding-bottom: 20%; }
    .product-size .frame-sketch {
      position: absolute;
      width: 45%;
      height: 100%; }
    .product-size .frame-sketch--left {
      top: 0;
      left: 0; }
    .product-size .frame-sketch--right {
      top: 0;
      right: 0; } }
  @media (min-width: 1px) and (max-width: 767px) {
    .product-size {
      margin-bottom: 2rem; }
      .product-size .frame-sketch-container {
        padding: 0 20%;
        transform: scale(0.9); }
      .product-size .frame-sketch {
        padding-bottom: 20%; }
      .product-size .frame-sketch-inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; } }
  @media (min-width: 1px) and (max-width: 439px) {
    .product-size {
      padding: 0; } }

.frame-sizes {
  width: 100%;
  padding: 0 10%;
  opacity: 0.8;
  margin-bottom: 1rem; }
  .frame-sizes svg {
    fill: #afa6a5;
    width: 100%;
    height: 8rem; }

.product-info {
  margin: 4rem 0 0 0;
  text-align: center; }
  .product-info p {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    line-height: 1.6; }

.nav-product ul {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  margin-bottom: 4rem; }
  @media (min-width: 1px) and (max-width: 439px) {
    .nav-product ul {
      flex-direction: column; } }
  .nav-product ul li {
    padding: 0.5rem;
    text-align: center; }

.product-free-shipping {
  margin-top: 2rem;
  text-align: center; }
  .product-free-shipping p {
    font-size: 1.2rem;
    margin: 0;
    padding-bottom: 1rem; }

.content-header--stores {
  margin-bottom: -16rem; }
  .content-header--stores .hero-text {
    margin-top: 6rem; }
  @media (min-width: 1px) and (max-width: 900px) {
    .content-header--stores {
      margin-bottom: -12rem; } }
  @media (min-width: 1px) and (max-width: 439px) {
    .content-header--stores .hero-text {
      margin-top: 2rem; } }

.opening-hours {
  display: flex;
  text-align: center;
  justify-content: center;
  padding-bottom: 30rem; }
  .opening-hours > div {
    width: 12%; }
    .opening-hours > div div {
      margin-bottom: 2rem; }
  .opening-hours h4 {
    margin-bottom: 0.6rem; }
  @media (min-width: 1px) and (max-width: 900px) {
    .opening-hours {
      padding-bottom: 22rem; }
      .opening-hours > div {
        width: 20%; } }
  @media (min-width: 1px) and (max-width: 439px) {
    .opening-hours {
      padding-bottom: 8rem; }
      .opening-hours > div {
        width: 27%; } }

.store-credentials {
  justify-content: space-between; }
  .store-credentials > div {
    width: 50%;
    text-align: center; }
    .store-credentials > div a {
      display: block; }
    .store-credentials > div .img-box {
      margin-bottom: 6rem; }
    .store-credentials > div:nth-child(1) {
      margin-top: 8rem; }
    .store-credentials > div:nth-child(2) {
      width: 33%; }
    .store-credentials > div:nth-child(3) {
      width: 33%;
      margin-left: 67%;
      margin-top: -16rem; }
  .store-credentials .box {
    margin-bottom: 4rem; }
  .store-credentials .store-address {
    margin-bottom: 4rem; }
  .store-credentials .service-contact {
    width: 100%; }
  .store-credentials address > div,
  .store-credentials address > a {
    margin-bottom: 0.2rem; }
  .store-credentials .address--city {
    margin-bottom: 0.4rem; }
  .store-credentials .address--mail {
    margin-bottom: 3rem; }
  .store-credentials .service--head {
    margin-bottom: 0.4rem; }
  .store-credentials .service--info {
    margin-top: 0.4rem; }
    .store-credentials .service--info span {
      display: inline-block;
      padding: 0 0.5rem; }
  @media (min-width: 1px) and (max-width: 900px) {
    .store-credentials {
      padding-bottom: 6rem; }
      .store-credentials > div:nth-child(1) {
        margin-top: 6rem; }
      .store-credentials > div:nth-child(2) {
        width: 40%; }
      .store-credentials > div:nth-child(3) {
        width: 40%;
        margin-left: 60%;
        margin-top: -6rem; } }
  @media (min-width: 1px) and (max-width: 439px) {
    .store-credentials .box {
      margin-bottom: 6rem; }
    .store-credentials > div .img-box {
      margin-bottom: 4rem; }
    .store-credentials > div:nth-child(2) {
      width: 100%; }
    .store-credentials > div:nth-child(3) {
      width: 100%;
      margin-left: 0;
      margin-top: 0; }
    .store-credentials .address--mail {
      margin-bottom: 2rem; } }

.store-info {
  padding-bottom: 12rem; }
  .store-info .text-box--m {
    margin-bottom: 8rem; }
  @media (min-width: 1px) and (max-width: 900px) {
    .store-info {
      padding-bottom: 8rem; } }

.store-awards {
  padding-bottom: 12rem; }
  .store-awards .text-box--s {
    margin-bottom: 2rem; }
  @media (min-width: 1px) and (max-width: 900px) {
    .store-awards {
      padding-bottom: 8rem; } }

.award-logos {
  display: flex;
  justify-content: center;
  margin-bottom: 4rem; }
  .award-logos .svg-ico {
    width: 5rem;
    height: 10rem;
    margin: 0.4rem; }

.award-credits {
  text-align: center; }

.store-book-appointment {
  margin-top: 2rem;
  text-align: center; }
  .store-book-appointment .btn {
    display: inline-block;
    width: auto; }

.cart-empty,
.return-to-shop {
  text-align: center; }

.content--cart,
.content--einkaufswagen {
  padding-bottom: 6rem; }

.cart-table {
  width: 100%; }
  .cart-table .cart-item {
    position: relative;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem; }
  .cart-table .cart-thumbnail {
    width: 30%;
    line-height: 0; }
    .cart-table .cart-thumbnail img {
      width: 100%;
      height: auto; }
    .cart-table .cart-thumbnail.is-removed {
      opacity: 0.5; }
  .cart-table .cart-info {
    order: -1;
    width: 30%;
    text-align: center;
    line-height: 2rem;
    padding: 1rem; }
    .cart-table .cart-info h3 {
      margin-bottom: 0; }
  .cart-table .cart-color,
  .cart-table .cart-size {
    color: #afa6a5; }
  .cart-table .cart-prices {
    width: 40%;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .cart-table .cart-quantity {
    position: relative; }
    .cart-table .cart-quantity .text--c {
      position: absolute;
      top: 50%;
      margin-top: -0.8rem; }
    .cart-table .cart-quantity .quantity {
      margin-left: 2rem; }
    .cart-table .cart-quantity input {
      text-align: right;
      padding: 1.2rem 0.8rem 1.2rem 0.6rem;
      background: #f0eceb;
      max-width: 4.4rem; }
      .cart-table .cart-quantity input:focus {
        outline: 0.2rem solid #cfc2bf; }
  .cart-table .cart-subtotal {
    text-align: right; }
  @media (min-width: 1px) and (max-width: 767px) {
    .cart-table .cart-item {
      flex-wrap: wrap;
      padding: 1rem 1rem 1.4rem 1rem; }
    .cart-table .cart-thumbnail {
      width: 48%; }
    .cart-table .cart-info {
      width: 52%; }
    .cart-table .cart-prices {
      width: 100%;
      padding: 0 1rem;
      justify-content: flex-end; }
    .cart-table .cart-subtotal {
      width: 38%; }
    .cart-table .cart-price {
      width: 50%;
      text-align: center; }
    .cart-table .cart-quantity input {
      padding: 0.8rem 0rem 0.8rem 1rem;
      max-width: 3.4rem; } }

.cart-remove,
.btn.btn--cart-remove {
  transition-property: opacity;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0.59, 0.01, 0.28, 1);
  position: absolute;
  top: 50%;
  left: -5.4rem;
  color: #0d0d0d;
  background: none;
  border: 0.2rem solid #0d0d0d;
  border-radius: 1px;
  width: 4rem;
  line-height: 3.6rem;
  text-align: center;
  margin-top: -4rem;
  padding: 0;
  word-spacing: 0;
  letter-spacing: 0;
  opacity: 1;
  cursor: pointer;
  font-weight: bold; }
  .mini-cart .cart-remove, .mini-cart
  .btn.btn--cart-remove {
    top: 2rem;
    left: -5.4rem;
    border: 0.2rem solid #864438;
    margin-top: 0; }
    @media (min-width: 768px) and (max-width: 1200px) {
      .mini-cart .cart-remove, .mini-cart
      .btn.btn--cart-remove {
        left: -3rem;
        width: 2rem;
        line-height: 2rem;
        bottom: auto; } }
    @media (min-width: 1px) and (max-width: 767px) {
      .mini-cart .cart-remove, .mini-cart
      .btn.btn--cart-remove {
        left: auto;
        top: 0.5rem;
        right: 0.5rem;
        bottom: auto;
        color: #0d0d0d;
        background: #fff;
        border: none;
        width: 4rem;
        line-height: 4rem; } }
  @media (min-width: 768px) and (max-width: 900px) {
    .cart-remove,
    .btn.btn--cart-remove {
      left: -1.3rem;
      width: 2.6rem;
      line-height: 2.2rem; } }
  @media (min-width: 1px) and (max-width: 767px) {
    .cart-remove,
    .btn.btn--cart-remove {
      left: 1rem;
      top: auto;
      right: 0;
      bottom: 1.7rem;
      width: 2.4rem;
      line-height: 2.2rem;
      font-weight: normal;
      border: 2px solid #0d0d0d; } }

.is-removed .cart-remove,
.is-removed .btn.btn--cart-remove {
  opacity: 0;
  pointer-events: none; }

.update-cart input {
  width: 100%;
  padding: 2rem; }

.cart-totals h2 {
  display: none; }

.cart-breakdown {
  margin-bottom: 4rem; }

.order-total small {
  display: none; }

.proceed-to-checkout .button {
  width: 100%;
  padding: 2rem; }

.checkout-breakdown,
.cart-breakdown {
  background: #fff;
  padding: 2rem;
  display: flex;
  align-items: flex-end;
  transition: 0.25s;
  position: relative; }
  .checkout-totals .checkout-breakdown, .checkout-totals
  .cart-breakdown {
    margin-bottom: 10rem; }
  .checkout-breakdown > div,
  .cart-breakdown > div {
    width: 50%; }
  .checkout-breakdown .order-hint,
  .cart-breakdown .order-hint {
    padding-right: 15%;
    margin-bottom: 1.4rem;
    color: #afa6a5; }
  @media (min-width: 1px) and (max-width: 439px) {
    .checkout-breakdown,
    .cart-breakdown {
      display: block; }
      .checkout-breakdown > div,
      .cart-breakdown > div {
        width: 100%; }
      .checkout-breakdown .order-hint,
      .cart-breakdown .order-hint {
        padding-right: 0;
        margin-bottom: 2rem; } }
  .priceChanging .checkout-breakdown, .priceChanging
  .cart-breakdown {
    opacity: 0.1; }
  .checkout-breakdown .edit-cart-link,
  .cart-breakdown .edit-cart-link {
    position: absolute;
    top: 3rem;
    left: 2rem; }
    @media (min-width: 1px) and (max-width: 439px) {
      .checkout-breakdown .edit-cart-link,
      .cart-breakdown .edit-cart-link {
        position: relative;
        top: auto;
        left: auto;
        margin-top: 2rem;
        text-align: center; } }

.store-indicator {
  margin-bottom: 4rem; }
  .store-indicator .btn {
    margin-left: 2rem; }
  @media (min-width: 1px) and (max-width: 767px) {
    .store-indicator .btn {
      margin-top: 0.5rem;
      margin-left: 0; } }

.methods-panel {
  padding-bottom: 2rem; }
  .methods-panel .methods-panel-listing {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style-type: none;
    margin: 0;
    padding: 0; }
  .methods-panel .methods-panel-listing-item {
    display: inline-block;
    padding: 2rem;
    position: relative;
    width: calc(50% - 1rem);
    text-align: left;
    background-color: #fff;
    margin-bottom: 2rem; }
  .methods-panel .methods-panel-listing-name {
    font-size: 1.5rem; }
  .methods-panel .methods-panel-listing-description {
    padding-left: 2.5rem;
    font-size: 1.2rem; }
  .methods-panel.methods-panel--shipping .methods-panel-listing-description {
    display: flex;
    flex-direction: column;
    min-height: 11rem; }
    .methods-panel.methods-panel--shipping .methods-panel-listing-description p {
      padding-right: 2.5rem; }
    .methods-panel.methods-panel--shipping .methods-panel-listing-description .nobr {
      white-space: nowrap; }
  .methods-panel.methods-panel--shipping .shipping-method-icon-listing {
    margin-top: auto; }
  @media (min-width: 1px) and (max-width: 767px) {
    .methods-panel .methods-panel-listing-item {
      width: 100%; }
      .methods-panel .methods-panel-listing-item:not(:last-child) {
        margin-bottom: 2rem; } }

.account-panel--billing .form-grid-cell.has-border,
.registration-panel--billing .form-grid-cell.has-border {
  margin-bottom: 0; }

.registration-panel--shipping {
  padding-top: 2rem; }

.payment-method-icon-listing,
.shipping-method-icon-listing {
  list-style-type: none;
  display: inline-block;
  margin: 1rem 0 0 0;
  padding: 0;
  text-align: left; }
  .payment-method-icon-listing .payment-method-icon,
  .payment-method-icon-listing .shipping-method-icon,
  .shipping-method-icon-listing .payment-method-icon,
  .shipping-method-icon-listing .shipping-method-icon {
    display: inline-block; }
    .payment-method-icon-listing .payment-method-icon:not(:last-child),
    .payment-method-icon-listing .shipping-method-icon:not(:last-child),
    .shipping-method-icon-listing .payment-method-icon:not(:last-child),
    .shipping-method-icon-listing .shipping-method-icon:not(:last-child) {
      margin-right: 1rem; }
    .payment-method-icon-listing .payment-method-icon img,
    .payment-method-icon-listing .shipping-method-icon img,
    .shipping-method-icon-listing .payment-method-icon img,
    .shipping-method-icon-listing .shipping-method-icon img {
      width: 6rem; }
    .payment-method-icon-listing .payment-method-icon svg,
    .payment-method-icon-listing .shipping-method-icon svg,
    .shipping-method-icon-listing .payment-method-icon svg,
    .shipping-method-icon-listing .shipping-method-icon svg {
      width: 6rem;
      height: 4.5rem; }
    .payment-method-icon-listing .payment-method-icon.payment-method-icon--small img, .payment-method-icon-listing .payment-method-icon.shipping-method-icon--small img,
    .payment-method-icon-listing .shipping-method-icon.payment-method-icon--small img,
    .payment-method-icon-listing .shipping-method-icon.shipping-method-icon--small img,
    .shipping-method-icon-listing .payment-method-icon.payment-method-icon--small img,
    .shipping-method-icon-listing .payment-method-icon.shipping-method-icon--small img,
    .shipping-method-icon-listing .shipping-method-icon.payment-method-icon--small img,
    .shipping-method-icon-listing .shipping-method-icon.shipping-method-icon--small img {
      width: 6rem; }
    .payment-method-icon-listing .payment-method-icon.payment-method-icon--small svg, .payment-method-icon-listing .payment-method-icon.shipping-method-icon--small svg,
    .payment-method-icon-listing .shipping-method-icon.payment-method-icon--small svg,
    .payment-method-icon-listing .shipping-method-icon.shipping-method-icon--small svg,
    .shipping-method-icon-listing .payment-method-icon.payment-method-icon--small svg,
    .shipping-method-icon-listing .payment-method-icon.shipping-method-icon--small svg,
    .shipping-method-icon-listing .shipping-method-icon.payment-method-icon--small svg,
    .shipping-method-icon-listing .shipping-method-icon.shipping-method-icon--small svg {
      width: 6rem;
      height: 5.5rem; }

.checkout-container--confirm {
  padding-bottom: 6rem; }
  .checkout-container--confirm .confirm-cart-summary .cart-breakdown {
    margin-bottom: 0; }
  .checkout-container--confirm .confirm-cart-summary .cart-footer {
    padding-bottom: 1rem; }
  .checkout-container--confirm .terms-confirmation {
    padding-bottom: 4rem; }
  .checkout-container--confirm .main-action {
    width: 100%;
    padding-bottom: 6rem; }

.checkout-container--finish {
  padding-bottom: 6rem; }

.checkout-finish-summary {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  justify-content: space-around;
  padding: 3rem 2rem;
  margin-bottom: 6rem; }
  .checkout-finish-summary address {
    margin-bottom: 2rem; }
  .checkout-finish-summary .checkout-finish-summary-item {
    width: 30%;
    text-align: center;
    padding: 0 1rem;
    margin-bottom: 1rem; }
    .checkout-finish-summary .checkout-finish-summary-item:nth-child(4), .checkout-finish-summary .checkout-finish-summary-item:nth-child(5), .checkout-finish-summary .checkout-finish-summary-item:nth-child(6) {
      margin-top: 1rem; }
    .checkout-finish-summary .checkout-finish-summary-item.checkout-finish-summary-item--transaction-number p {
      word-break: break-all; }
    @media (min-width: 1px) and (max-width: 767px) {
      .checkout-finish-summary .checkout-finish-summary-item {
        width: 100%; }
        .checkout-finish-summary .checkout-finish-summary-item:nth-child(4), .checkout-finish-summary .checkout-finish-summary-item:nth-child(5), .checkout-finish-summary .checkout-finish-summary-item:nth-child(6) {
          margin-top: 0; } }

.account-container {
  padding-bottom: 6rem; }

.address-container {
  padding-bottom: 6rem; }

.my-account .my-account-wrapper {
  display: flex;
  flex-direction: row; }
  .my-account .my-account-wrapper h3 {
    margin-bottom: 0.6rem; }
  .my-account .my-account-wrapper .my-info {
    flex: 1 1;
    text-align: center; }
    .my-account .my-account-wrapper .my-info p {
      margin-bottom: 3rem; }
  .my-account .my-account-wrapper .my-address {
    flex: 1 1;
    text-align: center; }

.my-account > a.btn {
  width: 100%;
  line-height: 5rem; }

.my-account a {
  border-bottom: 1px solid #0d0d0d;
  margin-bottom: 3rem;
  display: inline-block;
  font-size: 1.2rem; }
  .my-account a.btn {
    text-decoration: none; }

form.edit-account .clear,
.edit-address form .clear {
  display: none; }

form.edit-account fieldset,
.edit-address form fieldset {
  margin-top: 3rem;
  border: none;
  padding: 0; }
  form.edit-account fieldset legend,
  .edit-address form fieldset legend {
    margin-bottom: 1rem; }

form.edit-account .edit-wrapper,
.edit-address form .edit-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }
  form.edit-account .edit-wrapper .form-row,
  .edit-address form .edit-wrapper .form-row {
    width: calc(50% - 1rem); }

form.edit-account input[type="submit"],
.edit-address form input[type="submit"] {
  margin-top: 5rem;
  width: 100%;
  line-height: 5rem; }

.heidelpay-redirect {
  text-align: center;
  padding: 4rem 0; }
  .heidelpay-redirect .loading-animation {
    position: relative;
    opacity: 1;
    margin-top: 2rem;
    transform: scale(0.75); }
    .heidelpay-redirect .loading-animation .stripe {
      animation-play-state: running; }
  .heidelpay-redirect .payment-logo-redirect {
    margin-bottom: 4rem; }
    .heidelpay-redirect .payment-logo-redirect .payment-logo {
      width: 20rem; }

.hp-gateway-form .heidelpay-payment-headline {
  text-align: center;
  margin-bottom: 4rem; }
  .hp-gateway-form .heidelpay-payment-headline h2 {
    margin-bottom: 1rem; }
  .hp-gateway-form .heidelpay-payment-headline .payment-method-icon-listing {
    margin-top: 0;
    display: inline-block; }

.heidelpay-payment-wrapper {
  display: block;
  width: 100%; }

.heidelpay-iframe-wrapper {
  display: block;
  width: 100%;
  height: 520px; }
  .heidelpay-iframe-wrapper iframe {
    width: 100%;
    height: 100%;
    border: 0; }

.heidelpay-errors p,
.heidelpay-errors h3 {
  display: none; }
  .heidelpay-errors p.is-visible,
  .heidelpay-errors h3.is-visible {
    display: block; }

.hp-payment-sue[data-state="noiban"] .form-grid-row.form-grid-row--iban {
  opacity: 0;
  max-height: 0; }
  .hp-payment-sue[data-state="noiban"] .form-grid-row.form-grid-row--iban .form-grid-cell {
    margin-bottom: 0; }

.hp-payment-sue[data-state="iban"] .form-grid-row.form-grid-row--noiban {
  opacity: 0;
  max-height: 0; }
  .hp-payment-sue[data-state="iban"] .form-grid-row.form-grid-row--noiban .form-grid-cell {
    margin-bottom: 0; }
