header.main-head {
    transition-property: height, filter, transform;
    transition-duration: $trans-m;
    transition-timing-function: $trans-in, linear;
    background: $back-default;
    height: 8rem;
    line-height: 2rem;
    width: 100%;
    z-index: map-get($z-index, header);
    position: fixed;
    top: 0;
    left: 0;
    // overflow: hidden; // destroys megadropdown

    .has-free-shipping-banner & {
        transform: translate3d(0, $free-shipping-banner-height--desktop, 0);

        @include breakpoint(mobile-small) {
            transform: translate3d(0, $free-shipping-banner-height--mobile, 0);
        }

        @include breakpoint(desktop-large) {
            transform: translate3d(0, $free-shipping-banner-height--desktop-large, 0);
        }

        @include breakpoint(max) {
            transform: translate3d(0, $free-shipping-banner-height--desktop-large, 0);
        }
    }

    .main-elements {
        transition-property: height;
        transition-duration: $trans-m;
        transition-timing-function: $trans-in;
        height: 8rem;
        position: absolute;
        top: 0;
        left: 0;
        background: $back-default;
        z-index: map-get($z-index, header-main);
    }

    // Search is active
    .has-search & {
        height: 16rem;
    }

    // header small after scroll
    .has-scrolled & {
        height: 5rem;

        .main-elements {
            height: 5rem;
        }
    }

    // search and scrolled
    .has-search.has-scrolled & {
        height: 13rem;
    }

    @include breakpoint(mobile) {
        height: 5rem;

        &:after {
            display: none;
        }

        .main-elements {
            height: 5rem;
        }

        .has-search &,
        .has-search.has-scrolled & {
            height: 12rem;
        }
    }

    @include breakpoint(tablet) {
        height: 7rem;

        .main-elements {
            height: 7rem;
        }

        .has-search & {
            height: 14rem;
        }

        .has-scrolled & {
            height: 5rem;

            .main-elements {
                height: 5rem;
            }
        }

        .has-search.has-scrolled & {
            height: 12rem;
        }

    }

    // Ajax loading or modal active
    .has-login-box &,
    .has-mini-cart &,
    .has-nav-main &,
    .is-loading-ajax &,
    .has-filter & {
        //filter: blur(2px);
    }
}

.logo {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin-top: 0.4rem;
    z-index: 1; // stay on top of megadropdowns

    // make logo clickable the whole way
    a {
        padding-top: 5rem;
        margin-top: -5rem;
    }

    .is-ajax-request .product-detail & {
        display: none;
    }
}
