.img-box {
    position: relative;
    overflow: hidden;
    padding-bottom: 140%;
    width: 100%;

    img {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);

        &.lazy {
            transition-property: opacity, transform;
            transition-duration: $trans-m;
            transition-timing-function: $trans-in;
        }

        &.lazy--pending {
            opacity: 0;
        }

        &.lazy--loaded {
            opacity: 1;
        }

    }

    legend {
        position: absolute;
        bottom: 0;
        left: 5%;
        margin-bottom: 2rem;
        z-index: map-get($z-index, main-content);
        width: 90%;
        text-align: center;
    }

    h2 {
        position: absolute;
        bottom: 12%;
        left: 5%;
        margin-bottom: 2rem;
        z-index: map-get($z-index, main-content);
        width: 90%;
        text-align: center;
    }
}


// image box formats
.img-box--port-s {
    padding-bottom: 110%; // close to cube
}

.img-box--port-m {
    padding-bottom: 133%; // 4:3
}

.img-box--land-m {
    padding-bottom: 75%; // 3:4
}

.img-box--land-l {
    padding-bottom: 66.666%; // 3:4
}

.img-box--land-xl {
    padding-bottom: 60%; // 2:5
}

.img-box--land-xxl {
    padding-bottom: 40%;

    @include breakpoint(mobile) {
        padding-bottom: 60%;

        img {
            height: 100%;
            width: auto;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
        }
    }
}

// text-boxes
.text-box--l {

    margin: 0 auto 4rem auto;
    text-align: center;

    @include breakpoint(mobile-small) {
        width: 90%;
    }

    @include breakpoint(mobile-large) {
        width: 90%;
    }

    @include breakpoint(tablet) {
        width: 90%;
    }

    @include breakpoint(desktop) {
        width: 75%;
    }

    @include breakpoint(desktop-large) {
        width: 60%;
    }

    @include breakpoint(max) {
        width: 60%;
    }

}

.text-box--m {

    margin: 0 auto 4rem auto;
    text-align: center;

    @include breakpoint(mobile-large) {
        width: 80%;
    }

    @include breakpoint(tablet) {
        width: 80%;
    }

    @include breakpoint(desktop) {
        width: 66%;
    }

    @include breakpoint(desktop-large) {
        width: 50%;
    }

    @include breakpoint(max) {
        width: 50%;
    }

}

.text-box--s {
    margin: 0 auto 4rem auto;
    text-align: center;

    @include breakpoint(mobile-large) {
        width: 80%;
    }

    @include breakpoint(tablet) {
        width: 40%;
    }

    @include breakpoint(tablet-small) {
        width: 50%;
    }

    @include breakpoint(desktop) {
        width: 40%;
    }

    @include breakpoint(desktop-large) {
        width: 25%;
    }

    @include breakpoint(max) {
        width: 20%;
    }

}

// vertical offsets for layout purposes
.box--off-1 {
    margin-top: 8rem;

    @include breakpoint(mobile-small) {
        margin-top: 0;
    }
}

// border box
.border-box {
    border: $border-width solid $text-default;
    padding: 2rem 1.6rem;
}
.border-box.validation-error {
    border: $border-width solid $error;
}

// colors
.color {
    background-color: darken($back-light, 4);

    .box:nth-child(even) & {
        background-color: darken($back-light, 2);
    }

    // &:after {
    //     content: "";
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     background-image: linear-gradient(rgba(#fff, 0), #fff);
    //     transition-property: opacity;
    //     transition-duration: $trans-m;
    //     transition-timing-function: $trans-in;
    //     // filter: blur(4rem);
    //     opacity: 0.8;
    // }
    //
    // &:hover {
    //     &:after {
    //         opacity: 0.4;
    //     }
    // }
}
