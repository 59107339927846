.content {
    background: $back-light;
    transition-property: transform, filter;
    transition-duration: $trans-m;
    transition-timing-function: $trans-in, linear;
    min-height: calc(100vh + 4rem);
    padding-bottom: 0;
    z-index: map-get($z-index, main-content);
    position: relative;

    .has-filter & {
        //filter: blur(2px);
    }

    > :first-child { // make room for header
        padding-top: 8rem;
        padding-bottom: 0;

        // editor styles
        &.mce-content-body {
            padding: 1rem !important;
        }

        @include breakpoint(mobile) {
            padding-top: 5rem;
        }

        @include breakpoint(tablet) {
            padding-top: 6rem;
        }

    }

    &.mce-content-body > :first-child { // make room for header
        padding: 1rem !important;
    }
}

section {
    padding-bottom: 6rem;

    @include breakpoint(mobile-small) {
        padding-bottom: 4rem;
    }
}

article {
    padding: 2rem 0 4rem 0;
}

p {
    margin: 0 0 2rem 0;
}

a[href^="tel"] {
    cursor: default;
    word-spacing: -0.02em;

    .no-touch & {
        pointer-events: none;
    }
}

// white background part for specific pages
.content-header {
    min-height: 30rem;
    background: $back-default;
    position: relative;

    > div {
        position: relative; // make headline stick to top
    }

    .headline-wrapper {
        position: absolute;
        top: 40%;
        left: 0;
        text-align: center;
        width: 100%;
        margin-top: 4rem;
        transform: translateY(-50%);
    }

    @include breakpoint(tablet-small) {
        min-height: 26rem;
    }

    @include breakpoint(mobile) {
        min-height: 24rem;
    }
}

.content-header--shrink {
    margin-bottom: -16rem;

    @include breakpoint(min-tablet) {
        margin-bottom: -20rem;
    }

    @include breakpoint(tablet-small) {
        margin-bottom: -16rem;
    }
}

// content container
.col--main {
    width: 60%;
    margin-left: 20%;
    padding-top: 6rem;

    hr {
        border: 0;
        margin-top: 6rem;
        padding-bottom: 6rem;
        position: relative;
        @extend %top-line;
        transform: scaleX(1.666);
    }

    &.content--cart,
    &.content--checkout,
    &.registration-container,
    &.payment-shipping-container,
    &.col--main--full {
        width: 70%;
        margin-left: 15%;

        hr {
            transform: scaleX(1.25);
        }
    }

    &.col--main--reduced {
        width: 60%;
        margin-left: 20%;

        hr {
            transform: scaleX(1.25);
        }
    }

    @include breakpoint(mobile) {
        width: 100%;
        margin-left: 0;

        &.content--cart,
        &.content--checkout,
        &.registration-container,
        &.payment-shipping-container,
        &.col--main--full,
        &.col--main--reduced {
            width: 100%;
            margin-left: 0;
        }

        hr {
            margin-top: 4rem;
            padding-bottom: 4rem;
            transform: scaleX(1);
        }
    }

    @include breakpoint(tablet-small) {
        width: 80%;
        margin-left: 10%;

        hr {
            transform: scaleX(1.25);
        }

        &.content--cart,
        &.content--checkout,
        &.registration-container,
        &.payment-shipping-container,
        &.col--main--full,
        &.col--main--reduced {
            width: 100%;
            margin-left: 0;

            hr {
                transform: scaleX(1);
            }
        }
    }

    @include breakpoint(desktop-large) {
        width: 40%;
        margin-left: 30%;

        hr {
            transform: scaleX(2.5);
        }

        &.content--cart,
        &.content--checkout,
        &.registration-container,
        &.payment-shipping-container,
        &.col--main--full {
            width: 60%;
            margin-left: 20%;

            hr {
                transform: scaleX(1.25);
            }
        }

        &.col--main--reduced {
            width: 40%;
            margin-left: 30%;

            hr {
                transform: scaleX(1.25);
            }
        }
    }

    @include breakpoint(max) {
        width: 36%;
        margin-left: 32%;

        hr {
            transform: scaleX(2.7);
        }

        &.content--cart,
        &.content--checkout,
        &.registration-container,
        &.payment-shipping-container,
        &.col--main--full {
            width: 60%;
            margin-left: 20%;

            hr {
                transform: scaleX(1.666);
            }
        }

        &.col--main--reduced {
            width: 40%;
            margin-left: 30%;

            hr {
                transform: scaleX(1.666);
            }
        }
    }

    h2, h3, h4 {
        text-align: center;
    }

    h2 {
        margin-bottom: 2rem;
    }

    h3, h4 {
        margin-bottom: 1rem;
    }

    strong {
        text-align: center;
        display: block;
        font-weight: 100;
    }

    .btn {
        font-style: normal;

    }
}

// Page block contents
.page-block-content {
    padding-bottom: 0;

    &:not(:last-child) {
        margin-bottom: 6rem;
    }

    &:last-child {
        padding-bottom: 6rem;
    }

    &.is-unpublished {
        outline: dashed 2px $error;
    }
}

// Legal text content
.legal-text-container {
    $legal-ol-indent: 4rem;
    $legal-li-indent: 2rem;

    text-align: left;
    counter-reset: legal-section;

    h2 {
        //text-align: left;

        &:before {
            counter-increment: legal-section;
            content: counter(legal-section) ". ";
        }
    }

    ol {
        list-style-type: none;
        counter-reset: legal-section-sub;
        margin: 0;
        padding-left: $legal-ol-indent;

        & > li {
            position: relative;
            text-align: left;
        }

        & > li:before {
            position: absolute;
            counter-increment: legal-section-sub;
            left: $legal-ol-indent * (-1);
            top: 0;
            content: counter(legal-section) "." counter(legal-section-sub) ".";
        }
    }

    ul {
        margin: 0;
        padding-left: $legal-li-indent;
        list-style-type: none;

        & > li {
            position: relative;
            text-align: left;

            &:before {
                position: absolute;
                left: $legal-li-indent * (-1);
                top: -0.2rem;
                content: '•';
            }
        }

        & > li p:only-child {
            margin: 0;
        }
    }

    a {
        text-decoration: underline;
    }

    p {
        line-height: 1.3;
    }
}