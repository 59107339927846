.mega-dropdown {
    position: absolute;
    left: 50%;
    top: 4rem;
    width: 100vw;
    transform: translateX(-50%) translateY(2rem);
    background: $back-default;
    transition: transform $trans-m $trans-out,
                opacity $trans-m $trans-out,
                visibility 0s linear $trans-m;
    visibility: hidden;
    opacity: 0;

    .has-scrolled & {
        transform: translateX(-50%) ;
    }

    body:not(.is-loading-ajax) li:hover & {
        opacity: 1;
        visibility: visible;
        transition: transform $trans-m $trans-out,
                    opacity $trans-s $trans-out;
    }

    @include breakpoint(tablet) {
        top: 3rem;
    }
}

.mega-dropdown__inner {
    display: flex;
    padding: 5rem 0;

    > * {
        width: 50%;
    }

    .teaser {
        width: 25%;

        button {
            bottom: 2rem;
            font-size: inherit;
        }

        &:after {
            display: none; //remove rollover
        }
    }

    ul {
        text-align: center;
        padding: 2rem 0;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;

        a {
            &:after {
                display: none; //remove rollover
            }
        }
    }

    li {
        @include type-regular;
        width: 50%;
        opacity: 0;
        padding: 0 1rem;
        transform: translateY(3rem) scale(0.8);
        transition: transform 0s $trans-out $trans-m,
                    opacity 0s $trans-out $trans-m;


        body:not(.is-loading-ajax) li:hover & {
            transition: transform $trans-m $trans-out,
                        opacity $trans-l $trans-out;
            opacity: 1;
            transform: none;

            @for $i from 0 through 20 {
                &:nth-child(#{$i}) {
                    transition-delay: $i * 0.03s;
                }
            }
        }
    }

    .btn--link {
        @include type-regular;
    }

    .mega-dropdown__nav {
        li {
            line-height: 1.5;

            // reposition all frames link
            &:last-child {
                // margin-top: 1rem; // mm, maybe not
            }

            // pull up limited editions
            &:nth-child(1),
            &:nth-child(3) {
                order: -1;
            }
        }
    }

    .teaser {
        opacity: 0;
        transform: scale(1, 0.9);
        transition: transform 0s $trans-out $trans-m,
        opacity 0s $trans-out $trans-m;

        body:not(.is-loading-ajax) li:hover & {
            transition: transform $trans-m $trans-out,
            opacity $trans-l $trans-out;
            opacity: 1;
            transform: none;

            &:first-child {
                transition-delay: 0.1s;
            }
        }
    }
}

// brand listing
.brand-listing {
    display: flex;
    flex-direction: column;

    ul {
        flex: 1 1 auto;
        padding-top: 0;
        line-height: 1.5;
    }

    h4 {
        flex: 0 0 auto;
        text-align: center;
        opacity: 0;

        body:not(.is-loading-ajax) li:hover & {
            transition: opacity $trans-l $trans-out $trans-m;
            opacity: 1;
        }
    }
}

.store-brands {
    li span {
        opacity: 0.3;
    }
}

// specific styles
.teaser--inverted {
    button.btn--link {
        color: $text-default;

        &:after {
            background: $text-default;
        }
    }
}



