// Info panel
.info-panel {
    margin-bottom: 5rem;

    .info-panel-headline {
        text-align: center;

        h2 {
            margin-bottom: 2rem;
        }
    }

    .info-panel-body {
        padding-bottom: 2rem;
        text-align: center;

        p {
            margin-bottom: 0;
        }
    }

    .info-panel-action {
        text-align: center;
    }

    &.info-panel--box {
        .info-panel-body {
            padding: 2rem;
            background-color: $back-default;
            text-align: left;

            h3 {
                text-align: left;
            }

            &.info-panel-body--centered {
                text-align: center;
            }
        }

        .info-panel-action {
            padding: 0 2rem 2rem 2rem;
            background-color: $back-default;
        }

        @include breakpoint(mobile) {
            .info-panel-body {
                &.info-panel-body--centered {
                    text-align: left;
                }
            }
        }
    }
}

// Grouped info panels
.info-panel-group {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;

    .info-panel {
        display: flex;
        flex-direction: column;
        width: calc(50% - 1rem);

        .info-panel-body {
            flex: 1;
        }
    }

    @include breakpoint(mobile) {
        display: block;

        .info-panel {
            display: block;
            width: 100%;
        }
    }
}