.notification {
    border: $border-width solid $text-default;
    padding: 1rem 1.4rem;
    text-align: center;
    line-height: 2rem;
    margin: 0 0 6rem 0;

    p {
        margin-bottom: 0;
    }

    .btn.btn--underlined {
        font-size: inherit;
        color: inherit;
        border-color: currentColor;
    }

    &.notification--large {
        font-style: normal;
        font-size: $text-b;
        line-height: 1.3;
        letter-spacing: $letter-spacing--default;
        padding: 3rem 1.5rem;
        text-align: center;
    }

    &.notification--error {
        border-color: $error;
        color: $error;
    }

    // Hideable notifications
    &.notification--hideable {
        max-height: 0;
        opacity: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        transition: max-height $trans-m $trans-in,
                    opacity $trans-m $trans-in,
                    margin $trans-m $trans-in,
                    padding $trans-m $trans-in;

        &.is-visible {
            max-height: 32rem;
            opacity: 1;
            margin-bottom: 6rem;
            padding-top: 3rem;
            padding-bottom: 3rem;
        }
    }
}
