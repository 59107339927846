// colors
$text-default: #0d0d0d;
$text-inverted: #fff; /// #fff
$text-inverted-2: #757a88; // 50% $text-default
$text-greyed: #afa6a5; // 409 U 75%

$back-default: #fff;
$back-light: #f0eceb;
$back-highlight: #944b3e; // rot 484 U
$back-highlight-dark: darken($back-highlight, 4);
$back-highlight-2: #6a5a5b; // rotgrau Black 5 U
$back-highlight-3: #968b88; // 409 U
$back-highlight-4: #afa6a5; // 409 U 75%
$back-highlight-5: #c8c3c1; // 409 U 50%

$line-default: #d9d2d0;
$line-med: $back-highlight-5;
$line-dark: $text-default;

$error: #d66146;

// layout
$letter-spacing: 0.06rem;
$letter-spacing--default: $letter-spacing;
$letter-spacing--italic: 0.15rem;

// borders
$border-width: 0.2rem;
$border-radius: 1px;

// animations
$trans-in: cubic-bezier(.59,.01,.28,1);
$trans-out: cubic-bezier(.17,0,0,1);

$trans-s: 0.25s;
$trans-m: 0.5s;
$trans-l: 1s;

$free-shipping-banner-height--desktop: 6rem;
$free-shipping-banner-height--desktop-large: 4rem;
$free-shipping-banner-height--mobile: 6rem;

// z-indexing
$z-index: (
    cookie-consent  : 200,
    cookie-hint     : 200,
    modal-window    : 150,
    modal-header    : 145,
    modal           : 140,
    action-panel    : 120,
    side-cover      : 110,
    header-main     : 102,
    header-search   : 101,
    header          : 100,
    loading-overlay :  90,
    teaser-button   :  40,
    free-shipping-banner:  35,
    main-content    :  30,
    footer          :  20,
);
