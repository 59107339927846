.login-button {
    display: block;
}

.my-account-button {
    display: none;
}

.logout-button {
    display: none;
}

.is-logged-in {
    .login-button {
        display: none;
    }
    
    .my-account-button {
        display: block;
    }
    
    .logout-button {
        display: block;
    }
}