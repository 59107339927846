// brand overview

// brand overview module
.brand-overview {
    // padding-top: 4rem;
    padding-bottom: 16rem;

    h4 {
        padding-bottom: 6rem;
        max-width: 34rem;
        margin: 0 auto;

        @include breakpoint(one-row) {
            padding-bottom: 4rem;
            max-width: 20rem;
        }
    }

    .brand-overview-list {
        padding-bottom: 8rem;

        li {
            transition-property: opacity;
            transition-duration: $trans-s;
            transition-timing-function: $trans-in;
            opacity: 1;

            &.inactive {
                opacity: 0.25;
            }
        }

        @include breakpoint(one-row) {
            padding-bottom: 4rem;
        }
    }

    .brand-overview-list h3 {
        cursor: default;
    }

    .brand-overview-filter {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding-bottom: 2rem;

        li {
            margin: 0 2rem;
        }

        button {
            position: relative;

            &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 50%;
                height: 0.1rem;
                width: 0;
                opacity: 0;
                transform: translateX(-50%);
                background: $text-default;
                transition-property: width, opacity;
                transition-duration: $trans-s;
                transition-timing-function: $trans-in;
            }
        }

        .active button:after,
        .active a:after {
            width: 100%;
            opacity: 1;
        }

        @include breakpoint(one-row) {
            display: block;

            li {
                margin-bottom: 0.4rem;
                text-align: center;
            }
        }
    }

    .brand-overview-hint {
        text-align: center;
        max-width: 34rem;
        margin: 0 auto;

        @include breakpoint(one-row) {
            max-width: 20rem;
        }
    }

    // brand overview page
    .brands & {
        margin-top: 8rem;
    }

    @include breakpoint(one-row) {
        padding-bottom: 8rem;
    }
}
