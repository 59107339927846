.nav-head {
    position: absolute;
    top: 0;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    font-size: $navi-b;

    &.nav-head--product {
        font-size: $text-b;
    }

    > ul {
        @extend %pseudo-list;

        > li {
            padding: 0 1rem;

            > a {
                display: inline-block;
                transition-property: transform;
                transition-duration: $trans-m;
                transition-timing-function: $trans-in;

                .has-scrolled .wrapper:not(.product-detail) &,
                .has-scrolled.is-direct-request & {
                    transform: scale(0.83);
                }
            }
        }
    }

    &.nav-head--main,
    &.nav-head--product {
        > ul {
            height: 100%;

            > li {
                height: 100%;
                display: flex;
                align-items: center;
            }

            .nav-head__book-appointment {
                display: none;

                @include breakpoint(desktop) {
                    display: flex;
                }

                @include breakpoint(max) {
                    display: flex;
                }
            }
        }
    }

    // Add pointer class
    .touch & {
        &.nav-head--main > ul > li {
            cursor: pointer;
        }
    }

    &.nav-head--main {
        // increase magedropdown rollover usability
        > ul > li:hover {
            z-index: 1;
            padding: 0 3rem;
            margin: 0 -2rem;

            > a {
                z-index: 1;
            }
        }

        // take care of extended menu entries
        @include breakpoint(tablet-small) {
            left: 47%;

            > ul > li {
                font-size: 1.4rem;
                padding: 0 0.5rem;
            }

            > ul > li:hover {
                padding: 0 2.5rem;
            }
        }
    }

    &.nav-head--others {
        > ul {
            width: 40rem;

            > li {
                width: 20rem;

                &:first-child {
                    justify-content: flex-end;

                    a {
                        justify-content: flex-end;

                        .ico svg {
                            margin: 0 0 0 0.5rem;
                        }
                    }
                }
            }
        }

        @include breakpoint(tablet-small) {
            > ul {
                width: 36rem;

                > li {
                    width: 18rem;
                    padding: 0 0.5rem;
                }
            }
        }
    }

    // dont show main navigation if the user got here from an overview page
    .is-ajax-request .product-detail & {
        &:not(.nav-head--product) {
            opacity: 0;
            pointer-events: none;
        }
    }

    // dont show product navigation if the user came from somewhere else
    .is-direct-request & {
        &.nav-head--back,
        &.nav-head--others {
            opacity: 0;
            pointer-events: none;
        }
    }

    &.nav-head--back {
        left: 0;
        transform: translateX(0);

        > ul > li {
            padding: 0;
            margin-left: -0.8rem;
        }
    }

    &.nav-head--back,
    &.nav-head--others {
        a {
            display: flex;
            align-items: center;

            .ico {
                margin-top: -0.15rem; // center with text
            }

            .no-touch & {

                span:not(.ico) {
                    transition-property: margin, opacity;
                    transition-duration: $trans-m;
                    transition-timing-function: $trans-out;
                    // opacity: 0;
                    margin: 0;
                }

                &:hover {
                    span:not(.ico) {
                        // opacity: 1;
                        transition-duration: $trans-s;
                        margin: 0 -0.5rem;
                    }
                }
            }
        }
    }

    @include breakpoint(mobile-large) {
        font-size: $text-b;
    }

    @include breakpoint(medium) {
        &.nav-head--others {
            left: 60%;
        }
    }

    @include breakpoint(large) {
        &.nav-head--others {
            left: 60%;
        }
    }

    @include breakpoint(max) {
        &.nav-head--others {
            left: 55%;
        }
    }

    @include breakpoint(one-row) {
        &.nav-head--others {
            left: 50%;
        }
    }

    @include breakpoint(mobile) {
        &:not(.nav-head--back) {
            display: none;
        }
    }
}
