// General stuff
// ================================================================

// Clear floats
@mixin cf {
    &:before,
    &:after {
        display: table;
        line-height: 0;
        content: "";
    }

    &:after {
        clear: both;
    }
}

%clear-floats,
%cf {
    @include cf;
}

// Pseudolists
%pseudo-list {
    display: flex;
    justify-content: center;
}

@mixin type-bold {
    font-family: 'NeueHaas-Medium', Helvetica, Arial, sans-serif;
}

@mixin type-regular {
    font-family: 'NeueHaas-Regular', Helvetica, Arial, sans-serif;
}

// center vertically
%center-vertically {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
}

%reset-center {
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    transform: translateY(0);
}

// fading elements
%top-fade {
    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 2rem;
        margin-bottom: -2rem;
        background: linear-gradient(0deg, rgba($back-highlight, 0), rgba($back-highlight, 1));
        pointer-events: none;
    }
}

%bottom-fade {
    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 2rem;
        margin-top: -2rem;
        background: linear-gradient(0deg, rgba($back-highlight, 1), rgba($back-highlight, 0));
        pointer-events: none;
    }
}

%top-line {
    &:after {
        content: '';
        position: absolute;
        left: 4%;
        right: 4%;
        top: 0;
        height: 1px;
        background: linear-gradient(90deg,  rgba($line-default, 0), rgba($line-default, 1), rgba($line-default, 0));
    }
}
