// Nice styles for stores


.content-header--stores {
    margin-bottom: -16rem;

    .hero-text {
        margin-top: 6rem;
    }

    @include breakpoint(one-row) {
        margin-bottom: -12rem;
    }

    @include breakpoint(mobile-small) {
        .hero-text {
            margin-top: 2rem;
        }
    }
}

.opening-hours {
    display: flex;
    text-align: center;
    justify-content: center;
    padding-bottom: 30rem;

    > div {
        width: 12%;

        div {
            margin-bottom: 2rem;
        }

    }

    h4 {
        margin-bottom: 0.6rem;
    }

    @include breakpoint(one-row) {
        padding-bottom: 22rem;

        > div {
            width: 20%;
        }
    }

    @include breakpoint(mobile-small) {
        padding-bottom: 8rem;

        > div {
            width: 27%;
        }
    }
}

.store-credentials {
    justify-content: space-between;

    > div {
        width: 50%;
        text-align: center;

        a {
            display: block;
        }

        .img-box {
            margin-bottom: 6rem;
        }

        // schönhauser
        &:nth-child(1) {
            margin-top: 8rem;
        }

        // kastanien
        &:nth-child(2) {
            width: 33%;
        }

        // online inquiries
        &:nth-child(3) {
            width: 33%;
            margin-left: 67%;
            margin-top: -16rem;
        }

    }

    .box {
        margin-bottom: 4rem;
    }

    .store-address {
        margin-bottom: 4rem;
    }

    .service-contact {
        width: 100%;
    }

    address {
        > div,
        > a {
            margin-bottom: 0.2rem;
        }
    }

    .address--city {
        margin-bottom: 0.4rem;
    }

    .address--mail {
        margin-bottom: 3rem;
    }

    .service--head {
        margin-bottom: 0.4rem;
    }

    .service--info {
        margin-top: 0.4rem;

        span {
            display: inline-block;
            padding: 0 0.5rem;
        }
    }

    @include breakpoint(one-row) {
        padding-bottom: 6rem;

        > div {

            // schönhauser
            &:nth-child(1) {
                margin-top: 6rem;
            }

            // kastanien
            &:nth-child(2) {
                width: 40%;
            }

            // online inquiries
            &:nth-child(3) {
                width: 40%;
                margin-left: 60%;
                margin-top: -6rem;
            }
        }
    }

    @include breakpoint(mobile-small) {

        .box {
            margin-bottom: 6rem;
        }

        > div {

            .img-box {
                margin-bottom: 4rem;
            }

            // kastanien
            &:nth-child(2) {
                width: 100%;
            }

            // online inquiries
            &:nth-child(3) {
                width: 100%;
                margin-left: 0;
                margin-top: 0;
            }
        }

        .address--mail {
            margin-bottom: 2rem;
        }
    }

}

.store-info {
    padding-bottom: 12rem;

    .text-box--m {
        margin-bottom: 8rem;
    }

    @include breakpoint(one-row) {
        padding-bottom: 8rem;
    }
}

.store-awards {
    padding-bottom: 12rem;

    .text-box--s {
        margin-bottom: 2rem;
    }

    @include breakpoint(one-row) {
        padding-bottom: 8rem;
    }
}

.award-logos {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;

    .svg-ico {
        width: 5rem;
        height: 10rem;
        margin: 0.4rem;
    }
}

.award-credits {
    text-align: center;
}

.store-book-appointment {
    margin-top: 2rem;
    text-align: center;

    .btn {
        display: inline-block;
        width: auto;
    }
}