.free-shipping-banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $free-shipping-banner-height--desktop;
    background-color: $back-highlight;
    transition: transform $trans-m $trans-in;
    transform: translate3d(0, -100%, 0);
    z-index: map-get($z-index, free-shipping-banner);

    .has-free-shipping-banner & {
        transform: translate3d(0, 0, 0);
    }

    @include breakpoint(mobile-small) {
        height: $free-shipping-banner-height--mobile;
    }

    @include breakpoint(desktop-large) {
        height: $free-shipping-banner-height--desktop-large;
    }

    @include breakpoint(max) {
        height: $free-shipping-banner-height--desktop-large;
    }
}

.free-shipping-banner__container {
    display: flex;
    height: 100%;
    align-items: center;


    p {
        flex: 1 1 auto;
        text-align: center;
        margin: 0;
        font-size: $text-b;

        @include breakpoint(mobile-small) {
            font-size: $text-c;
        }
    }
}