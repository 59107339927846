//  html,
//  body {
//      height: 100%; // prevent scrollbar
//  }

html {
    font-size: 1vw;
    margin-top: 0 !important; // overwriting some wired wordpress setting

    @include breakpoint(mobile-small) {
        font-size: 3vw;
    }

    @include breakpoint(mobile-large) {
        font-size: 2vw;
    }

    @include breakpoint(tablet-small) {
        font-size: 1.5vw;
    }

    @include breakpoint(desktop-large) {
        font-size: 0.75vw;
    }

    @include breakpoint(max) {
        font-size: 0.6vw;
    }

}

body {
    margin: 0;
    padding: 0;
    min-width: 320px;
    position: relative;
    background: $back-default;
    overflow-x: hidden;

    &.has-footer-almost-inview {
        background: $back-highlight-2;
    }
}

// Set proper selection color
::selection {
    background: $back-highlight-5;

    footer & {
        background: lighten($back-highlight-2, 1%);
    }
}
