@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}

// mixin for input placeholder styling
@mixin placeholder {
    ::placeholder {@content}
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}
}

@mixin placeholderNested {
    &::placeholder {@content}
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}
}

/// Hide Elements Visually without loosing accessebility
/// Ref: https://css-tricks.com/places-its-tempting-to-use-display-none-but-dont/
///
/// @group humans-machines
@mixin hide {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

/// Nice mixins to have a delay cascade
///
/// @group humans-machines
@mixin animation-delay($delay: 0.1s, $global-delay: 0s, $items: 5) {
    @for $i from 0 through $items {
        &:nth-child(#{$i}) {
            animation-delay: $i * $delay + $global-delay;
        }
    }
}

/// Hide Elements Visually without loosing accessebility
/// Ref: https://css-tricks.com/places-its-tempting-to-use-display-none-but-dont/
///
/// @group humans-machines
@mixin hide {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px !important;
    padding: 0;
    border: 0;
}

@mixin bottom-fade($color: $back-highlight) {
    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 2rem;
        margin-top: -2rem;
        background: linear-gradient(0deg, rgba($color, 1), rgba($color, 0));
        pointer-events: none;
    }
}