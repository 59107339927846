.country-selection-search {
    position: fixed;
    top: 4rem;
    left: 7rem;
    right: 7rem;
    padding-top: 4rem;
    padding-bottom: 1rem;
    background: $back-highlight;
    @extend %top-fade;

    @include breakpoint(mobile) {
        left: 2rem;
        right: 2rem;
        top: 2rem;
    }
}

.country-selection-search__input {
    background: none;
}

.country-selection-list {
    list-style: none;
    padding: 0;
    margin: 6rem 0 0 0;
}

.country-selection-list__country-list {
    list-style: none;
    padding: 0;
    margin: 0 -1rem 0 -1rem;
}

.country-selection-list__group {
    display: block;
    margin-top: 1rem;
    padding: 0 1rem;

    h3 {
        color: $text-greyed;
        font-size: $text-c;
        height: 2.5rem;
        line-height: 2.5rem;
    }

    &[aria-hidden="true"] {
        display: none;
    }
}

.country-selection-list__country {
    height: 2.5rem;
    line-height: 2.5rem;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: $text-b;
    padding: 0 4rem 0 1rem;
    margin-top: 1px;

    &:hover {
        background: rgba(255, 255, 255, 0.1);
    }

    &[aria-selected="true"] {
        background: rgba(255, 255, 255, 0.2);
    }

    &.has-store {
        background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="5 -5 34 34"><path d="M29.3 21.7H14.7l-2.5-15h19.6l-2.5 15zm-12.9-2h11.2l1.9-11H14.6l1.8 11z"/><path d="M24.6 11.8c-.6 0-1-.4-1-1V2.9c0-.5-.5-.5-.7-.5h-1.8c-.7 0-.7.4-.7.5v7.9c0 .6-.4 1-1 1s-1-.4-1-1V2.9C18.4 1 20 .4 21.1.4h1.8c2 0 2.7 1.5 2.7 2.5v7.9c0 .5-.5 1-1 1z"/></svg>');
        background-position: 98% 50%;
        background-size: 2.2rem 2.2rem;
        background-repeat: no-repeat;
    }

    &[aria-hidden="true"] {
        display: none;
    }
}