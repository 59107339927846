$text-a: 2.2rem;        // brot groß
$text-a2: 1.8rem;        // brot groß
$text-b: 1.5rem;        // brot normal
$text-c: 1.2rem;        // brot klein

$head-a: 4rem;          // head groß
$head-b: 3rem;        // head mittel
$head-c: 2.6rem;          // head klein

$navi-a: $head-a;       // nav-main
$navi-b: 1.8rem;       // nav-head
$navi-c: 2.6rem;       // responsive $navi-a

// greater contrast
// in bigger viewports
//  $text-a-2: 1.7rem;        // brot groß
//  $text-c-2: 1.2rem;        // brot klein


body {
    font-family: 'NeueHaas-Regular', Helvetica, Arial, sans-serif;
    font-size: $text-b;
    line-height: 1.3;
    color: $text-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: $letter-spacing;
}

a {
    color: inherit;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 100;
    font-style: normal;
    font-variant: normal;
    margin: 0;
}

i {
    font-style: normal;
}

button,
input,
textarea,
select {
    font-size: $text-b;
    font-family: 'NeueHaas-Regular', Helvetica, Arial, sans-serif;
    font-weight: 100;
}

button,
.button,
.btn,
input[type="submit"],
input[type="button"],
.filter:after {
    .teaser & {
        font-size: $text-a;
    }

    &.btn--small {
        font-size: $text-c;
    }
}

h1 {
    font-size: $head-b;
    line-height: 1.1;
}

h3 {
    font-size: $text-b;
}

h2 {
    font-size: $head-b;
    margin-bottom: 4rem;

    .img-box &,
    .text-box & {
        font-size: $text-a;
    }

    .stores & {
        font-size: $text-b;

        @include breakpoint(mobile-small) {
            font-size: $text-c;
        }
    }

    .product-overview-hero & {
        i {
            font-size: $text-b;
        }

        @include breakpoint(one-row) {
            font-size: $text-b;

            i {
                font-size: $text-c;
            }
        }
    }

    .login-box & {
        font-size: $text-b;
    }
}

h4 {
    font-size: $text-b;
}

.col--main {

    h2 {
        font-size: $text-a;
    }

    &.page {
        em {
            font-size: $text-b;
        }
    }

    &.magazine-post {
        p {
            font-size: $text-a2;
        }
    }

    &.content--cart,
    &.content--checkout,
    &.registration-container,
    &.payment-shipping-container {

        // h2 {
        //     font-size: $head-c;
        // }

        h3 {
            letter-spacing: $letter-spacing;
            font-style: normal;
            font-size: $text-b;
        }
    }
}

strong, th {
    font-weight: 100;
}

@include placeholder {
    font-size: $text-b;
}

.text--b {
    font-size: $text-b;
}

.text--a {
    font-size: $text-a;

    @include breakpoint(mobile) {
        font-size: $text-b;

        .mini-contact & {
            font-size: $text-a;
        }
    }
}

.text--a2 {
    font-size: $text-a2;

    @include breakpoint(one-row) {
        font-size: $text-a;
    }
}

.text--c {
    font-size: $text-c;
}

.opening-hours div {
    font-size: $text-a;

    @include breakpoint(mobile-small) {
        font-size: $text-b;
    }
}

input[type="checkbox"] + label {
    font-size: $text-b;
}

.text--c,
.flag,
label {
    font-size: $text-c;
}

.notice {
    font-size: $text-c;
    text-align: center;
    line-height: 1.6;
}

ul.main {
    font-size: $navi-a;

    @include breakpoint(mobile) {
        font-size: $navi-c;
    }

}

del .product-price--regular {
    font-size: $text-c;
}

.tax-notice {
    font-size: $text-c;
}

// cart & checkout

.cart-quanity,
.cart-color,
.cart-size,
.cart-price,
.cart-remove,
.cart-quantity input {
    font-size: $text-c;
}

.checkout-breakdown,
.cart-totals {
    .line-row {
        >:not(.amount) {
            font-size: $text-c;
        }
    }
}

.cart-totals .order-total .amount,
.checkout-order-total .amount {
    font-size: $text-b;
}

.terms input[type="checkbox"] + label {
    font-size: $text-c;
}

// filter
.filter-panel--style,
.filter-panel--color {
    a,
    label,
    .not-available {
        font-size: $text-a;
    }
}

.brand-overview-list,
.filter-panel {
    a,
    label,
    h3,
    .not-available {
        font-size: $head-b;
        @include type-bold;

        @include breakpoint(tablet-small) {
            font-size: $head-c;
        }

        @include breakpoint(mobile) {
            font-size: $text-a;
        }
    }
}

// Sizes in frame skateches
.frame-sketch-svg text {
    font-family: 'NeueHaas-Regular', Helvetica, Arial, sans-serif;
    font-weight: 100;
}

.payment_description.text--c {
    font-size: $text-c;

    @include breakpoint(large) {
        font-size: $text-c;
    }
}
