.login-box {

    h2:first-of-type {
        margin-top: 0rem;
    }

    h2 {
        margin-top: 5rem;
        margin-bottom: 1.4rem;
    }

    p, ul {
        opacity: 0.7;
        line-height: 2rem;
        margin-bottom: 1rem;
    }

    p + ul {
        margin-top: -0.8rem;
    }

    ul.list-cirlce {
        list-style: disc;
        list-style-position: inside;
        margin-bottom: 2rem;
    }

    input.input-error,
    .has-error {
        border: $border-width solid #BC0B0B;
    }

    button,
    input {
        margin-bottom: 1rem;
    }

    button[type="submit"] {
        width: 100%;
    }

    button.light {
        opacity: 0.5;
    }

    form.is-loading button {
        opacity: 0.7;
        pointer-events: none;
    }

    .error-message {
        color: #BC0B0B;
    }

    [name="login-form"],
    [name="forgot-password-form"],
    [name="signup-form"] {
        margin-bottom: 5rem;
    }

    [data-action="login"] {
        .signup-form-area {
            display: none;
        }

        .login-form-area {
            display: block;
        }

        .forgot-password-form-area {
            display: none;
        }
    }

    [data-action="signup"] {
        .signup-form-area {
            display: block;
        }

        .login-form-area {
            display: none;
        }

        .forgot-password-form-area {
            display: none;
        }
    }

    [data-action="forgot-password"] {
        .signup-form-area {
            display: none;
        }

        .login-form-area {
            display: none;
        }

        .forgot-password-form-area {
            display: block;
        }
    }

    &.form--dark {
        .error-message {
            color: $text-inverted;
        }

        input.input-error,
        .has-error {
            border-color: $text-inverted;
        }
    }
}
