*,
*:after,
*:before {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

// Remove outline of input elements
a,
button,
input,
select,
textarea {
    outline: none;
}

// Remove X from search and textfields
input[type="search"] {
    -webkit-appearance: textfield;
    box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

input[type=text]::-ms-clear,
input[type=text]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

.cf {
    @extend %cf;
}

.clear {
    clear: both;
}

.xdebug-var-dump {
    font-size: 12px;
    font-family: Consolas, Lucida Console, monospace;
    line-height: 1.1;
    border: solid 1px #000;
    background-color: rgb(245, 245, 245);
    border-radius: 10px;
    padding: 10px;
    overflow-x: scroll;
}