.modal-window {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: map-get($z-index, modal-window);
    background: rgba($back-highlight-4, 0.8);
    transition: opacity $trans-m $trans-in;
    display: flex;
    justify-content: center;
    align-items: center;

    &[aria-hidden="true"] {
        pointer-events: none;
        opacity: 0;
    }

    &[aria-hidden="false"] {
        cursor: pointer;
        opacity: 1;
    }

    // Hide for IOS
    .ios & {
        display: none;
    }
}

.modal-window__container {
    position: relative;
    width: 80rem;
    height: 60rem;
    max-width: 90vw;
    max-height: 80vh;
    background: $back-default;
    display: flex;
    flex-direction: column;
    padding: 2rem 3rem;
    opacity: 0;
    transform: translate3d(0, 2rem, 0);
    transition: opacity $trans-m $trans-in,
                transform $trans-m $trans-in;

    [aria-hidden="false"] & {
        transform: none;
        opacity: 1;
    }

    @include breakpoint(mobile) {
        padding: 1.5rem
    }
}

.modal-window__header {
    padding-right: 3rem;
}

.modal-window__content {
    flex: 1 1 auto;
    margin-top: 1rem;
    position: relative;

    .ios & {
        overflow: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
    }
}

.modal-window__iframe {
    border: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.modal-window__close {
    position: absolute;
    top: 2rem;
    right: 3rem;
    width: auto;

    span {
        display: inline-block;
        text-indent: -999rem;
    }

    .svg-ico {
        width: 2.8rem;
        height: 2.8rem;
    }

    @include breakpoint(mobile) {
        top: 1rem;
        right: 1.5rem;
    }
}

// Disable scroll on body
.has-modal-window {
    overflow: hidden;
}