.action-panel {
    background: $back-light;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: visible;
    transform: translate3d(0, 100%, 0);
    z-index: map-get($z-index, action-panel);
}