.cart-trigger {
    background: $back-highlight-5;
    border-radius: $border-radius;

    .site-functions & {
        padding: 0 0 0 0.3rem;
    }

    .modal .modal-header & {
        background: transparent;
        padding: 0 0 0 0.3rem;
        margin-right: 1.5rem;
        transform: translateY(-0.15rem); // dirty vertical align
    }

    span:nth-child(3) {
        display: none;
    }
}

.item-count {
    display: inline-block;
    font-size: 1rem;
    margin-right: 1rem;
    transform: translate3d(0,0.2rem,0);
    letter-spacing: 0.1rem;
    @include type-bold;
}

.mini-cart {

    .modal-title {
        position: relative;
        overflow: hidden;

        h3 {
            line-height: 2.8rem;
        }

        .modal-title-wrapper {
            transition-property: transform;
            transition-duration: $trans-m;
            transition-timing-function: $trans-in;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .modal-title-wrapper--default {
            transform: translate3d(0, 0, 0);
        }

        .modal-title-wrapper--updating {
            transform: translate3d(0, 100%, 0);
        }
    }

    .modal-content {
        transition-property: opacity;
        transition-duration: $trans-m;
        transition-timing-function: $trans-in;
    }

    h3 {
        display: inline-block;
    }

    .mini-cart-actions  {
        .btn {
            width: calc(50% - 0.5rem);
        }
    }

    .cart-empty {
        text-align: left;

        h3 {
            display: block;
            margin-bottom: 0.6rem;
        }

        .btn {
            width: 100%;
        }
    }

    .product-image {
        margin-bottom: 1rem;
        background: $back-light;
        line-height: 0;
        overflow: hidden;

        img {
            border-left: 2rem solid $back-light;
            border-right: 2rem solid $back-light;
            margin: -0.5rem 0;

            @include breakpoint(mobile) {
                border-left: 3rem solid $back-light;
                border-right: 3rem solid $back-light;
            }
        }
    }

    // Mini cart product
    .mini-cart-listing-item {
        @extend %cf;
        position: relative;

        .price {
            float: right;
        }

        .brand {
            display: block;
        }

        a {
            display: block;
            transition-property: max-height, opacity, padding;
            transition-duration: $trans-s;
            transition-timing-function: $trans-in;
            opacity: 1;
            max-height: 30rem;
            overflow: hidden;
            padding-bottom: 4rem;
        }

        &.is-removed {
            a {
                max-height: 0;
                opacity: 0;
                padding-bottom: 0;
            }
        }
    }

    .tax-notice a {
        border-bottom: 1px solid rgba($text-default, 0.5);
    }

    .button-wrapper {
        padding: 0;
    }

    &.is-loading {
        .modal-title {
            .modal-title-wrapper--default {
                transform: translate3d(0, -100%, 0);
            }

            .modal-title-wrapper--updating {
                transform: translate3d(0, 0, 0);
            }
        }

        .modal-content {
            opacity: 0.4;
            pointer-events: none;

            * {
                cursor: default;
            }
        }

        .cart-empty {
            display: none;
        }
    }

}
