.checkout-container--finish {
    padding-bottom: 6rem;
}

// Main summary of date / order number etc
.checkout-finish-summary {
    display: flex;
    flex-wrap: wrap;
    background: $back-default;
    justify-content: space-around;
    padding: 3rem 2rem;
    margin-bottom: 6rem;

    address {
        margin-bottom: 2rem;
    }

    .checkout-finish-summary-item {
        width: 30%;
        text-align: center;
        padding: 0 1rem;
        margin-bottom: 1rem;

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
            margin-top: 1rem;
        }

        &.checkout-finish-summary-item--transaction-number {
            p {
                word-break: break-all;
            }
        }

        @include breakpoint(mobile) {
            width: 100%;

            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
                margin-top: 0;
            }
        }
    }
}