// Redirects from fail and cancel
.heidelpay-redirect {
    text-align: center;
    padding: 4rem 0;

    .loading-animation {
        position: relative;
        opacity: 1;
        margin-top: 2rem;
        transform: scale(0.75);

        .stripe {
            animation-play-state: running;
        }
    }

    .payment-logo-redirect {
        margin-bottom: 4rem;

        .payment-logo {
            width: 20rem;
        }
    }
}

// Heidelpay payment form
.hp-gateway-form {
    .heidelpay-payment-headline {
        text-align: center;
        margin-bottom: 4rem;

        h2 {
            margin-bottom: 1rem;
        }

        .payment-method-icon-listing {
            margin-top: 0;
            display: inline-block;
        }
    }
}

// General wrapp
.heidelpay-payment-wrapper {
    display: block;
    width: 100%;
}

// Iframe wrapper
.heidelpay-iframe-wrapper {
    display: block;
    width: 100%;
    height: 520px;

    iframe {
        width: 100%;
        height: 100%;
        border: 0;
    }
}

// Errors
.heidelpay-errors {
    p,
    h3 {
        display: none;

        &.is-visible {
            display: block;
        }
    }
}

// Sofortueberweisung form
.hp-payment-sue {
    &[data-state="noiban"] {
        .form-grid-row.form-grid-row--iban {
            opacity: 0;
            max-height: 0;

            .form-grid-cell {
                margin-bottom: 0;
            }
        }
    }

    &[data-state="iban"] {
        .form-grid-row.form-grid-row--noiban {
            opacity: 0;
            max-height: 0;

            .form-grid-cell {
                margin-bottom: 0;
            }
        }
    }
}