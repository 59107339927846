// cart

.cart-empty,
.return-to-shop {
    text-align: center;
}

.content--cart,
.content--einkaufswagen {
    padding-bottom: 6rem;
}

.cart-table {
    width: 100%;

    .cart-item {
        position: relative;
        background: $back-default;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;
    }

    .cart-thumbnail {
        width: 30%;
        line-height: 0;

        img {
            width: 100%;
            height: auto;
        }

        &.is-removed {
            opacity: 0.5;
        }
    }

    .cart-info {
        order: -1;
        width: 30%;
        text-align: center;
        line-height: 2rem;
        padding: 1rem;

        h3 {
            margin-bottom: 0;
        }
    }

    .cart-color,
    .cart-size {
        color: $text-greyed;
    }

    .cart-prices {
        width: 40%;
        padding: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .cart-quantity {
        position: relative;

        .text--c {
            position: absolute;
            top: 50%;
            margin-top: -0.8rem;
        }

        .quantity {
            margin-left: 2rem;
        }

        input {
            text-align: right;
            padding: 1.2rem 0.8rem 1.2rem 0.6rem;
            background: $back-light;
            max-width: 4.4rem;

            &:focus {
                outline: $border-width solid darken($back-light, 15%);
            }
        }

    }

    .cart-subtotal {
        text-align: right;
    }

    @include breakpoint(mobile) {

        .cart-item {
            flex-wrap: wrap;
            padding: 1rem 1rem 1.4rem 1rem;
        }

        .cart-thumbnail {
            width: 48%;
        }

        .cart-info {
            width: 52%;
        }

        .cart-prices {
            width: 100%;
            padding: 0 1rem;
            justify-content: flex-end;
        }

        .cart-subtotal {
            width: 38%;
        }

        .cart-price {
            width: 50%;
            text-align: center;
        }

        .cart-quantity {
            input {
                padding: 0.8rem 0rem 0.8rem 1rem;
                max-width: 3.4rem;
            }
        }

    }
}

.cart-remove,
.btn.btn--cart-remove {
    transition-property: opacity;
    transition-duration: $trans-s;
    transition-timing-function: $trans-in;
    position: absolute;
    top: 50%;
    left: -5.4rem;
    color: $text-default;
    background: none;
    border: $border-width solid $text-default;
    border-radius: $border-radius;
    width: 4rem;
    line-height: 3.6rem;
    text-align: center;
    margin-top: -4rem;
    padding: 0;
    word-spacing: 0;
    letter-spacing: 0;
    opacity: 1;
    cursor: pointer;
    font-weight: bold;

    .mini-cart & {
        top: 2rem;
        left: -5.4rem;
        border: $border-width solid $back-highlight-dark;
        margin-top: 0;

        @include breakpoint(tablet) {
            left: -3rem;
            width: 2rem;
            line-height: 2rem;
            bottom: auto;
        }

        @include breakpoint(mobile) {
            left: auto;
            top: 0.5rem;
            right: 0.5rem;
            bottom: auto;
            color: $text-default;
            background: $back-default;
            border: none;
            width: 4rem;
            line-height: 4rem;
        }

    }

    @include breakpoint(tablet-small) {
        left: -1.3rem;
        width: 2.6rem;
        line-height: 2.2rem;
    }

    @include breakpoint(mobile) {
        left: 1rem;
        top: auto;
        right: 0;
        bottom: 1.7rem;
        width: 2.4rem;
        line-height: 2.2rem;
        font-weight: normal;
        border: 2px solid $text-default;
    }
}

.is-removed {
    .cart-remove,
    .btn.btn--cart-remove {
        opacity: 0;
        pointer-events: none;
    }
}

.update-cart {
    input {
        width: 100%;
        padding: 2rem;
    }
}

.cart-totals {
    h2 {
        display: none;
    }
}

.cart-breakdown {
    // more definitions see _checkout

    margin-bottom: 4rem;
}

.order-total {
    small {
        display: none;
    }
}

.proceed-to-checkout {
    .button {
        width: 100%;
        padding: 2rem;
    }
}

.checkout-breakdown,
.cart-breakdown {
    background: $back-default;
    padding: 2rem;
    display: flex;
    align-items: flex-end;
    transition: 0.25s;
    position: relative;

    .checkout-totals & {
        margin-bottom: 10rem;
    }

    > div {
        width: 50%;
    }

    .order-hint {
        padding-right: 15%;
        margin-bottom: 1.4rem;
        color: $text-greyed;
    }

    @include breakpoint(mobile-small) {
        display: block;

        > div {
            width: 100%;
        }

        .order-hint {
            padding-right: 0;
            margin-bottom: 2rem;
        }
    }

    .priceChanging & {
        opacity: 0.1
    }

    .edit-cart-link {
        position: absolute;
        top: 3rem;
        left: 2rem;

        @include breakpoint(mobile-small) {
            position: relative;
            top: auto;
            left: auto;
            margin-top: 2rem;
            text-align: center;
        }
    }
}

// Store indicator
.store-indicator {
    margin-bottom: 4rem;

    .btn {
        margin-left: 2rem;
    }

    @include breakpoint(mobile) {
        .btn {
            margin-top: 0.5rem;
            margin-left: 0;
        }
    }
}