// Small mobile devices
$mobile-small-min: 1px;
$mobile-small-max: 439px;

// Larger mobile devices
$mobile-large-min: $mobile-small-max + 1px;
$mobile-large-max: 767px;

// Tables -> iPad
$tablet-min: $mobile-large-max + 1px;
$tablet-max: 1200px;
$tablet-small: 900px;

// desktops
$desktop-min: 1201px;
$desktop-max: 9999px;

// larger desktops
$desktop-large-min: 1260px;
$desktop-large-max: 1899px;

// Real big screens
$desktop-max: 1900px;

// fix for very landscaped viewports
$small-height: 760px;
$small-height-2: 800px;
$small-height-3: 960px;

/// Basic breakpoint mixin for responsiveness
/// @param {string} $bp The breakpoint to use
@mixin breakpoint($bp) {
    @if $bp == mobile {
        @media (min-width: $mobile-small-min) and (max-width: $mobile-large-max) { @content; }
    }
    @else if $bp == mobile-small {
        @media (min-width: $mobile-small-min) and (max-width: $mobile-small-max) { @content; }
    }
    @else if $bp == mobile-large {
        @media (min-width: $mobile-large-min) and (max-width: $mobile-large-max) { @content; }
    }
    @else if $bp == min-tablet {
        @media (min-width: $tablet-min) { @content; }
    }
    @else if $bp == tablet {
        @media (min-width: $tablet-min) and (max-width: $tablet-max) { @content; }
    }
    @else if $bp == tablet-small {
        @media (min-width: $tablet-min) and (max-width: $tablet-small) { @content; }
    }
    @else if $bp == max-tablet-small {
        @media (min-width: $mobile-small-min) and (max-width: $tablet-small) { @content; }
    }
    @else if $bp == desktop {
        @media (min-width: $desktop-min) and (max-width: $desktop-max) { @content; }
    }
    @else if $bp == desktop-large {
        @media (min-width: $desktop-large-min) and (max-width: $desktop-large-max) { @content; }
    }
    @else if $bp == max {
        @media (min-width: $desktop-max) { @content; }
    }
    @else if $bp == medium {
        @media (min-width: $tablet-min) and (max-width: $desktop-max) { @content; }
    }
    @else if $bp == large {
        @media (min-width: $desktop-large-min) { @content; }
    }
    @else if $bp == one-row {
        @media (min-width: $mobile-small-min) and (max-width: $tablet-small) { @content; }
    }
    @else if $bp == small-height {
        @media (max-height: $small-height) and (min-width: $tablet-min) { @content; }
    }
    @else if $bp == small-height-2 {
        @media (max-height: $small-height-2) and (min-width: $desktop-large-min) { @content; }
    }
    @else if $bp == small-height-3 {
        @media (max-height: $small-height-3) and (min-width: 1600px) { @content; }
    }
    @else if $bp == tablet-small-desktop {
        @media (min-width: $tablet-small) { @content; }
    }
    @else {
        @error "`#{$bp}` is not a valid breakpoint!!!";
    }
}