.cookie-hint {
    transform: translate3d(0, 100%, 0);
    transition-property: transform;
    transition-duration: $trans-m;
    transition-timing-function: $trans-in;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: $back-highlight;
    z-index: map-get($z-index, cookie-hint);

    .container {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
        padding: 0.8rem 0 1.2rem 0;
    }

    p,
    .cookie-hint-actions {
        display: inline-block;
    }

    p {
        margin-top: 1rem;
        margin-bottom: 1rem;
        text-align: center;
    }

    .cookie-hint-actions {
    }

    .is-loaded & {
        transform: translate3d(0, 0, 0);
    }

    &.is-dismissed {
        transform: translate3d(0, 100%, 0);
    }

    button {
        margin-left: 0.6rem;
    }

    @include breakpoint(mobile-small) {

        .cookie-hint-actions {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
        }

        button {
            margin-left: 0;
            margin-top: 0.6rem;
        }
    }
}
