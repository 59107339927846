$footerH: 44rem;

footer {
    transition-property: transform, filter;
    transition-duration: $trans-m;
    transition-timing-function: $trans-in, linear;
    z-index: map-get($z-index, footer);
    padding: 8rem 0 4rem 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    background: $back-highlight;
    min-height: $footerH;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;

    .js body:not(.has-footer-almost-inview) & {
        display: none;
    }

    a {
        transition-property: color;
        transition-duration: $trans-s;
        transition-timing-function: $trans-in;
    }

    .ico svg {
        transition-property: fill;
        transition-duration: $trans-s;
        transition-timing-function: $trans-in;
    }

    @include breakpoint(max-tablet-small) {
        position: relative;
        left: auto;
        bottom: auto;
        min-height: 1px;
        padding: 10rem 0 3rem 0;
        display: block !important;
    }

    @include breakpoint(mobile-small) {
        padding: 10rem 0 6rem 0;
    }

}

.before-footer {
    height: $footerH;
    pointer-events: none;
    background-image: linear-gradient(rgba($back-highlight, 1), rgba($back-highlight, 0) 10rem);
    z-index: map-get($z-index, main-content);
    position: relative;

    .wrapper.product-detail & {
        display: none;
    }

    @include breakpoint(max-tablet-small) {
        height: 1px;
    }
}

.mini-contact {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 6rem;

    > div {
        width: 33%;
    }

    .as-contact {
        order: 1;
    }

    .ka-contact {
        order: 3;
    }

    .service-contact {
        order: 2;
        padding-top: 6rem;
    }

    .opening-hours {
        height: 6rem;
        padding-bottom: 0;

    }

    a {
        display: block;

        span {
            display: inline-block;
            margin-left: 0.5rem;

            &:nth-child(1) {
                margin-left: 0.75rem;
            }

        }
    }

    @include breakpoint(max) {
        > div {
            width: 25%;
        }
    }

    @include breakpoint(max-tablet-small) {
        //padding-bottom: 4rem;

        > div {
            width: 50%;
        }

        .as-contact {
            order: 1;
        }

        .ka-contact {
            order: 2;
        }

        .service-contact {
            order: 3;
            padding-top: 6rem;
        }
    }

    @include breakpoint(mobile) {
        //padding-bottom: 4rem;

        > div {
            width: 100%;
            padding-bottom: 4rem;
        }

        .ka-contact .opening-hours {
            display: none;
        }

        .service-contact {
            padding-top: 0;
        }
    }

}

.social-media-panel {
    padding-bottom: 0.5rem;
    display: flex;
    justify-content: center;

    .ico {
        padding: 0 0.5rem;

        svg {
            width: 2rem;
            height: 2rem;
        }
    }

    @include breakpoint(mobile-small) {
        margin-bottom: 4rem;
    }
}

.footer__tgc {
    padding-bottom: 5rem;

    @include breakpoint(mobile-small) {
        margin-bottom: 4rem;
    }
}

.footer-menu {
    ul {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;

        @include breakpoint(mobile-small) {
            flex-direction: column;
        }

        li {
            padding: 0.5rem;
        }
    }
}
