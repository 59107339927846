.shipping-selector {
    padding-bottom: 2rem;
    text-align: left;

    h3 {
        font-size: $text-a2;
        text-align: left;
        margin: 1.5rem 0 0.75rem 0;

        small {
            font-size: $text-b;
            color: $text-inverted-2;
        }
    }

    strong {
        display: inline;
        text-align: left;
        font-weight: bold;
    }

    p {
        margin: 0 0 0.25rem 0;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        display: block;
    }

    .shipping-selector__result {
        margin-top: 1rem;
        transition: transform $trans-m $trans-in, opacity $trans-m $trans-in;
    }


    &[aria-busy="true"] {
        .shipping-selector__result {
            opacity: 0;
            transform: translate3d(0, 0.5rem, 0);
        }
    }
}