/* Humans & Machines fontface */

@font-face {
    font-family: 'NeueHaas-Medium';
    src: url('fonts/NeueHaas-Medium.eot');
    src: url('fonts/NeueHaas-Medium.woff2') format('woff2'),
        url('fonts/NeueHaas-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'NeueHaas-Regular';
    src: url('fonts/NeueHaas-Regular.eot');
    src: url('fonts/NeueHaas-Regular.woff2') format('woff2'),
        url('fonts/NeueHaas-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
