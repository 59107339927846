// Payment and Shipping listing
.methods-panel {
    padding-bottom: 2rem;

    .methods-panel-listing {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .methods-panel-listing-item {
        display: inline-block;
        padding: 2rem;
        position: relative;
        width: calc(50% - 1rem);
        text-align: left;
        background-color: $back-default;
        margin-bottom: 2rem;
    }

    .methods-panel-listing-name {
        font-size: $text-b;
    }

    .methods-panel-listing-description {
        padding-left: 2.5rem;
        font-size: $text-c;
    }

    &.methods-panel--shipping {
        .methods-panel-listing-description {
            display: flex;
            flex-direction: column;
            min-height: 11rem;

            p {
                padding-right: 2.5rem;
            }

            .nobr {
                white-space: nowrap;
            }
        }

        .shipping-method-icon-listing {
            margin-top: auto;
        }
    }

    @include breakpoint(mobile) {
        .methods-panel-listing-item {
            width: 100%;

            &:not(:last-child) {
                margin-bottom: 2rem;
            }
        }
    }
}

.account-panel--billing,
.registration-panel--billing {
    .form-grid-cell.has-border {
        margin-bottom: 0;
    }
}

.registration-panel--shipping {
    padding-top: 2rem;
}

// Listing of the payment method icons
.payment-method-icon-listing,
.shipping-method-icon-listing {
    list-style-type: none;
    display: inline-block;
    margin: 1rem 0 0 0;
    padding: 0;
    text-align: left;

    .payment-method-icon,
    .shipping-method-icon {
        display: inline-block;

        &:not(:last-child) {
            margin-right: 1rem;
        }

        img{
            width: 6rem;
        }

        svg {
            width: 6rem;
            height: 4.5rem;
        }

        &.payment-method-icon--small,
        &.shipping-method-icon--small {
            img {
                width: 6rem;
            }

            svg {
                width: 6rem;
                height: 5.5rem;
            }
        }
    }
}