.frame-notification {
    display: block;
    width: 100%;
    transition: opacity $trans-s $trans-in,
                padding $trans-s $trans-in;

    .form-grid-row {
        transition: opacity $trans-s $trans-in,
                    max-height $trans-s $trans-in;
        max-height: 64rem;
    }

    .form-grid-row + .form-grid-row {
        margin-top: 1rem;
    }

    .advanced-checkbox label {
        font-size: $text-c;
        margin-left: 2rem;
    }

    .btn {
        transition: opacity $trans-s $trans-in;
    }

    &.is-success {
        .form-grid-row {
            height: 0;
            opacity: 0;
        }

        .btn {
            opacity: 0;
            pointer-events: none;
        }
    }
}

.frame-notification__intro {
    overflow: hidden;
    max-height: 8rem;
    transition: max-height $trans-s $trans-in,
                opacity $trans-s $trans-in;

    h3 {
        font-size: $text-c;
    }
}

.frame-notification__response {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height $trans-s $trans-in,
                opacity $trans-s $trans-in,
                color $trans-s $trans-in;

    p {
        font-size: $text-b;
    }

    .is-success &,
    .is-error & {
        max-height: 10rem;
        opacity: 1;
    }

    .is-error & {
        color: $text-inverted;
    }

    .is-success & {
        background-color: $back-default;
        padding: 1.5rem;

        p {
            text-align: center;
        }
    }
}

.frame-notification__fieldset {
    margin-top: 1rem;
}

.frame-notification-overview {
    padding-bottom: 4rem;

    h2 {
        margin-top: 4rem;
    }

    table {
        width: 100%;
    }

    th {
        text-align: left;
    }

    th,
    td {
        margin: 1px;
        padding: 0.5rem;
        font-size: $text-b;
    }

    th {
        background: $back-highlight;
        color: $text-inverted;
    }

    tr {
        &:nth-child(even) td {
            background: $back-default;
        }

        &:nth-child(odd) td {
            background: rgba($back-highlight-5, 0.5);
        }
    }
}