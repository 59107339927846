.nav-head--main,
.teaser {
    a,
    button {
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: -0.2rem;
            left: 50%;
            height: 0.15rem;
            width: 0;
            opacity: 0;
            transform: translateX(-50%);
            background: $text-default;
            transition-property: width, opacity;
            transition-duration: $trans-s;
            transition-timing-function: $trans-in;
        }

        .no-touch & {
            &:hover {
                &:after {
                    width: 100%;
                    opacity: 1;
                }
            }
        }

        &:active,
        &:focus {
            &:after {
                width: 100%;
                opacity: 1;
            }
        }
    }

    .no-touch & {
        li:hover a {
            &:after {
                width: 100%;
                opacity: 1;
            }
        }
    }

    .active a,
    &:not(:hover) .is-active a {
        &:after {
            width: 100%;
            opacity: 1;
        }
    }

    button {
        &:after {
            z-index: map-get($z-index, teaser-button);
            background: #fff;
            bottom: -0.8rem;
        }
    }

    .no-touch & {
        &.box:hover {
            button {
                &:after {
                    width: 100%;
                    opacity: 1;
                }
            }
        }
    }
}

.nav-head--main {
    > ul > li:hover > a,
    > ul > li:focus > a {
        &:after {
            width: 100%;
            opacity: 1;
        }
    }
}
