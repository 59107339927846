ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.list--multi-column {
    column-count: 3;

    li {
        padding: 0 0.5rem;
    }

    .mega-dropdown & {
        column-count: 2;
    }

    @include breakpoint(tablet-small) {
        column-count: 2;
    }

    @include breakpoint(mobile) {
        column-count: 2;
    }
}

.list--large {
    line-height: 1.6;
}

.list--bullet {
    line-height: 1.5;
    // list-style-type: disc;
    font-size: $text-c;
    list-style-position: inside;

    li {

        &:before {
            content: "•";
            display: inline-block;
            padding-right: 1.6rem;
        }
    }
}
