.nav-main {
    .nav-main-main {
        flex: 3 0 auto;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 9rem 0 4rem;
    }

    ul.main {
        li {
            display: block;
            text-align: center;
            @include type-bold;
        }
    }

    .nav-main-sec {
        flex: 1 0 auto;
        padding-bottom: 2rem;
    }

    .nav-main-third {
        flex: 0 0 auto;
        @extend %cf;

        .third {
            float: left;
            width: 80%;
        }

        .language-selection {
            float: left;
            width: 20%;

            .is-active a {
                @include type-bold;
            }
        }

        ul li {
            line-height: 1.5;
        }
    }

    @include breakpoint(mobile) {
        .nav-main-main {
            padding: 7rem 0 4rem;
        }

        .nav-main-third {
            .third {
                width: 66%;
            }

            .language-selection {
                width: 33%;
            }
        }
    }
}

#nav-cover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: $back-highlight-2;
    opacity: 0;
    transition-property: opacity;
    transition-duration: $trans-m;
    transition-timing-function: $trans-in;
    pointer-events: none;

    .show-nav-main.show-login-box &,
    .has-nav-main.has-login-box &,
    .show-nav-main.show-mini-cart &,
    .has-nav-main.has-mini-cart & {
        opacity: 0.95;
        pointer-events: all;
        cursor: pointer;
    }
}

.has-login-box,
.has-mini-cart,
.has-nav-main {
    overflow: hidden;
}
