// projects overview
.product-list {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    padding-top: 4rem;

    &.product-list--slider {
        display: block;
        height: 26rem;
        margin: 6rem -5vw 8rem -5vw;
        padding: 0;
        width: 100vw;
        overflow: hidden;

        .product-list-slider-wrapper {
            display: block;
            white-space: nowrap;
            overflow: visible;
            position: relative;

        }

        article {
            display: inline-block;
            width: 30rem;
            padding-bottom: 0;

            &:first-child {
                margin-left: 5vw;
            }

            &:last-child {
                margin-right: 5vw;
            }
        }
    }
}

.mobile {
    .product-list--slider {
        height: 24rem;
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
    }
}

.no-mobile {
    .product-list--slide {
        //overflow: hidden;
    }
}

.product-overview-hero {
    padding-bottom: 0; // Overwriting section default

    .img-box {
        h2 {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex-direction: column;

            width: 40%;
            bottom: 25%;
            left: 30%;

            text-align: center;

            i {
                display: block;
            }

            span {
                width: 100%;
                border: $border-width solid $text-default;
                margin-top: 1rem;
                padding: 2rem 2rem;
            }

            @include breakpoint(one-row) {
                width: 50%;
                left: 25%;
            }

            @include breakpoint(mobile-small) {
                width: 80%;
                left: 10%;
                bottom: 1rem;
            }
        }
    }

    // Inverted headline color
    &.product-overview-hero--inverted {
        h2 {
            color: $text-inverted;

            span {
                border-color: $text-inverted;
            }
        }
    }
}

.product-teaser {
    position: relative;
    width: 33%;
    padding: 0 2rem 6rem 2rem;
    background: $back-light;

    img {
        width: 100%;
    }

    // product title
    h3 {
        text-align: center;
        position: relative;
    }

    // brand
    h4 {
        text-align: center;
        margin-top: -1.5rem;
        position: relative;
    }

    @include breakpoint(mobile-small) {
        width: 100%;
        padding: 0 0 6rem 0;
    }

    @include breakpoint(mobile-large) {
        width: 50%;
        padding: 0 1rem 6rem 1rem;
    }

    @include breakpoint(tablet-small) {
        width: 50%;
        padding: 0 1.5rem 6rem 1.5rem;
    }

    @include breakpoint(max) {
        width: 25%;
        padding: 0 2rem 6rem 2rem;
    }

}

.promotion-teaser {
    position: relative;
    width: 33%;
    padding: 0 2rem 6rem 2rem;
}
