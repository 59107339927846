.checkout-steps {
    display: inline-block;
    position: absolute;
    top: 9rem;
    left: 0;
    text-align: center;
    width: 100%;
}

.checkout-steps-list {
    display: flex;
    list-style-type: none;
    margin: 0;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.checkout-steps-item {
    position: relative;
    white-space: nowrap;
    margin: 0 1rem;

    &:after {
        content: '';
    }

    &.is-active:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -0.4rem;
        left: 50%;
        width: 100%;
        height: 0.1rem;
        opacity: 1;
        transform: translateX(-50%);
        background: $text-default;
    }

    @include breakpoint(mobile-small) {
        &:not(.is-active) {
            .checkout-steps-name {
                display: none;
            }
        }
    }
}

.checkout-steps-number,
.checkout-steps-name {
    display: inline-block;
}

.checkout-steps-number {
    margin-right: 0.5rem;
}

.checkout-steps-link {
    display: block;
    text-decoration: none;
}