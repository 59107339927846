// Nice styles for home

.content-header--home {
    margin-bottom: -16rem;

    @include breakpoint(one-row) {
        min-height: 40rem;
        margin-bottom: -30rem;
    }
}

.shop-teaser {

    .box {
        order: 1;
    }

    .box--welcome {
        margin-top: -12rem;
        width: 36%;
        order: 2;

        h4 {
            margin-bottom: 1rem;
        }

        .specs-book-appointment {
            margin-top: 2rem;
            text-align: center;
        }

        @include breakpoint(one-row) {
            order: 1;
            width: 60%;
            margin: 0 0 6rem 20%;
        }

        @include breakpoint(mobile-small) {
            width: 90%;
            margin: 0 0 4rem 5%;
        }

        @include breakpoint(desktop-large) {
            margin-top: -10rem;
            width: 34%;
            margin-left: 2%;
        }

        @include breakpoint(max) {
            margin-top: -10rem;
            width: 34%;
            margin-left: -2%;
        }

        @include breakpoint(desktop) {
            .specs-book-appointment {
                display: none;
            }
        }

        @include breakpoint(max) {
            .specs-book-appointment {
                display: none;
            }
        }
    }
}

.store-teaser {
    padding-bottom: 16rem;

    h3 {
        margin-bottom: 0.4rem;
    }

    .opening-hours {
        padding-bottom: 8rem;
    }

    .box-container {
        .box:nth-child(2) {
            width: 33%;
        }

        // Is das kunst oder kann das weg?
        //  a {
        //      position: relative;
        //      cursor: pointer;
        //
        //      &:after {
        //          content: '';
        //          display: block;
        //          position: absolute;
        //          bottom: 0;
        //          left: 50%;
        //          height: 1px;
        //          width: 0;
        //          opacity: 0;
        //          transform: translateX(-50%);
        //          background: $text-default;
        //          transition-property: width, opacity;
        //          transition-duration: $trans-s;
        //          transition-timing-function: $trans-in;
        //      }
        //  }
        //
        //.active a:after,
        //li:hover a:after {
        //    width: 100%;
        //    opacity: 1;
        //}
    }

    .text-box {
        padding-top: 4rem;
        text-align: center;

        h2 {
            margin-bottom: 1rem;
        }

        > div {
            margin-bottom: 4rem;
        }
    }

    // .img-box--port-s {
    //
    //     img {
    //         top: 0;
    //         left: 50%;
    //         width: auto;
    //         height: 100%;
    //         transform: translateY(0) translateX(-50%);
    //     }
    // }

    @include breakpoint(one-row) {
        padding-bottom: 8rem;
    }
}
