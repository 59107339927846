// forms
form {
    &[data-state="loading"] {
        input[type="submit"],
        button[type="submit"] {
            background-color: $text-greyed;
            border-color: $text-greyed;
        }
    }
}

// form components
input,
textarea,
select {
    transition-property: border;
    transition-duration: $trans-s;
    transition-timing-function: $trans-in;
    border: $border-width solid $back-default;
    border-radius: 1px;
    background-color: $back-default;
    padding: 1.1rem 1.2rem;
    line-height: 1.42rem + $border-width * 2;
    letter-spacing: inherit;
    color: $text-default;

    &:focus {
        background-color: #fff;
        border: $border-width solid $line-med;
    }

    &:-webkit-autofill { // remove yellow autofill in chrome
        -webkit-box-shadow: 0 0 0 30px $back-default inset;
    }

    .input--naked & {
        border: none;
        background: transparent;
        padding: 0;
        line-height: inherit;
    }

    // Todo: Define proper input colors for dark forms
    .form--dark & {
        background-color: rgba($back-light, 0.5);
        border: solid 2px transparent;

        &:focus {
            background-color: rgba(lighten($back-light, 2%), 0.6);
        }

        @include placeholderNested {
            line-height: inherit;
            text-transform: inherit;
            letter-spacing: inherit;
            text-align: left;
            color: rgba(0, 0, 0, 0.5);
        }
    }

    // Readony fields
    &[readonly] {
        color: $text-greyed;

        &:focus {
            border: $border-width solid lighten($back-light, 4%);
            background-color: lighten($back-light, 4%);
        }
    }

    // Error fields
    .has-error &,
    .validation-error &,
    &.has-error {
        border: $border-width solid $error;
    }
}

input[type="text"],
input[type="number"],
input[type="date"],
input[type="url"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="search"],
select,
textarea {
    width: 100%;
}

// reset ios styles
input[type="text"],
input[type="number"],
input[type="date"],
input[type="url"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="submit"],
select,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

label {
    padding: 0.4rem 0;
    display: inline-block;

    form & {
        padding: 0.4rem 0.2rem;
    }
}

input[type="checkbox"] {
    float: left;
    width: 1.6rem;
    height: 1.6rem;
    padding: 0;
}

input[type="checkbox"] + label {
    display: block;
    padding: 0;
    cursor: pointer;
    margin-left: 3.2rem;
}

input[type="radio"] {
    padding: 0;
}

select {
    background: $back-default url('../images/icons/specs-dropdown.svg') no-repeat calc(100% - 1.2rem) center;
    background-size: 1rem 1rem;
}

.select-naked select,
select.select-naked {
    padding: 0;
    background-color: transparent;
    border: none;

    &:focus {
        background-color: transparent;
        border: none;
    }

}

abbr {
    color: $text-greyed;
}

address {
    font-style: normal;
}

form p {
    margin-bottom: 0;
}

// Inherit from parent, beside color
@include placeholder {
    line-height: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    text-align: left;
	color: $text-greyed;
}

// General form display
.form-grid {
    display: block;
    width: 100%;
    padding-bottom: 6rem;
    transition: padding $trans-m $trans-in;

    // General labels in the form
    label,
    .form-grid-label {
        @extend %cf;
        display: block;
        padding: 0.6rem 0.2rem;

        // Required field modifier
        &.is-required {
            .optional-hint {
                display: none;
            }
        }

        // Optional field modifier
        &.is-optional {
            .optional-hint {
                display: inline-block;
                color: $text-greyed;
                font-size: $text-c;
                margin-left: 0.2rem;

                &:before {
                    content: '– ';
                    color: $text-default;
                }
            }
        }
    }

    // One row in a form grid
    .form-grid-row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        overflow: hidden;
        max-height: 24rem;
        opacity: 1;
        transition: max-height $trans-m $trans-in,
                    opacity $trans-m $trans-in;

        &.form-grid-headline {
            h2 {
                width: 100%;
                text-align: center;
            }
        }

        // Single cells could also be set to hidden
        &[data-state="hidden"] {
            opacity: 0;
            max-height: 0;

            .form-grid-cell {
                margin-bottom: 0;
            }
        }
    }

    // Single cell in the form grid
    .form-grid-cell {
        margin-bottom: 1rem;
        width: 48%;
        transition: margin $trans-m $trans-in;

        @include breakpoint(mobile-small) {
            width: 100%;
        }

        &.is-full-width {
            width: 100%;
        }

        &.has-border {
            border: $border-width solid $text-default;
            padding: 1.6rem 2rem;
            margin: 2rem 0 3.6rem 0;

            &.is-error,
            &.has-error {
                border-color: $error;
                color: $error;

                a,
                span {
                    border-color: inherit;
                    color: inherit;
                }
            }
        }

        &.is-centered {
            text-align: center;
        }
    }

    // Hind for an input field
    .form-grid-hint {
        padding: 0.6rem 0.2rem;
        line-height: 1.2;
        color: $text-greyed;
        font-size: $text-c;
    }

    // Error message for an input field
    .form-grid-error {
        line-height: 1.2;
        color: $error;
        margin: 0.5rem 0 0 0;
    }

    // Form is hidden
    &[data-state="hidden"] {
        padding-bottom: 0;

        .form-grid-row {
            opacity: 0;
            max-height: 0;
        }

        .form-grid-cell {
            margin-bottom: 0;
        }
    }
}

// Input groups
.input-group {
    display: flex;

    .input-group-item {
        display: inline-block;

        &.input-group-item--zip {
            width: 20rem;
            margin-right: 4%;

            @include breakpoint(mobile-small) {
                width: 10rem;
            }
        }

        &.input-group-item--city {
            flex: 1;
        }
    }
}

// Option groups
.option-group {
    display: flex;
    padding-bottom: 1rem;

    .option-group-item {
        position: relative;

        &:not(:last-child) {
            margin-right: 2rem;
        }
    }

    // Hide radio button
    input[type="radio"] {
        @include hide;
        left: 50%;
        bottom: 0;
    }

    label {
        display: inline-block;
        padding: 0;
        font-size: $text-b;
        cursor: pointer;

        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: -0.4rem;
            left: 50%;
            height: 2px;
            width: 0;
            opacity: 0;
            transform: translateX(-50%);
            background: $text-default;
            transition-property: width, opacity;
            transition-duration: $trans-s;
            transition-timing-function: $trans-in;
        }

        .no-touch & {
            &:hover {
                &:after {
                    width: 100%;
                    opacity: 1;
                }
            }
        }
    }

    // Radio button is checked
    input[type="radio"]:checked + label {
        &:after {
            width: 100%;
            opacity: 1;
        }
    }
}

// Advanced checkboxes
.advanced-checkbox {
    display: inline-block;
    position: relative;

    input[type="checkbox"] {
        @include hide;
        top: 1rem;
        left: 2rem;
    }

    label {
        cursor: pointer;
    }

    input[type="checkbox"] + label {
        position: relative;
        display: inline-block;
        padding: 0 0 0 1rem;

        &:before,
        &:after {

            display: inline-block;
            position: absolute;
        }

        &:before {
            content: '';
            top: 0.1rem;
            left: -2rem;
            width: 1.6rem;
            height: 1.6rem;
            border: solid 2px $text-default;
            border-radius: $border-radius;
        }

        &:after {
            content: '×';
            opacity: 0;
            top: 0.1rem;
            left: -2rem;
            width: 1.6rem;
            height: 1.6rem;
            text-align: center;
            line-height: 1.6rem;
            transition-property: width, opacity;
            transition-duration: $trans-s;
            transition-timing-function: $trans-in;
        }
    }

    input[type="checkbox"]:checked + label {
        &:after {
            opacity: 1;
        }
    }

    // Field contains error
    .has-error & {
        input[type="checkbox"] + label {
            color: $error;

            &:before {
                border-color: $error;
            }
        }
    }
}

// Advanced radio button
.advanced-radio {
    display: inline-block;
    position: relative;

    input[type="radio"] {
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
    }

    label {
        cursor: pointer;
    }

    input[type="radio"] + label {
        position: relative;
        display: inline-block;
        padding: 0 0 0 2.5rem;

        &:before,
        &:after {
            display: inline-block;
            position: absolute;
        }

        &:before {
            content: '';
            top: 0.3rem;
            left: 0;
            width: 1.4rem;
            height: 1.4rem;
            border: solid 2px $text-default;
            border-radius: 100%;
        }

        &:after {
            content: '';
            opacity: 0;
            top: 0.7rem;
            left: 0.4rem;
            width: 0.6rem;
            height: 0.6rem;
            text-align: center;
            line-height: 1.6rem;
            background-color: $text-default;
            border-radius: 100%;
            transition-property: width, opacity;
            transition-duration: $trans-s;
            transition-timing-function: $trans-in;
        }
    }

    input[type="radio"]:checked + label {
        &:after {
            opacity: 1;
        }
    }

    // Field contains error
    .has-error & {
        input[type="radio"] + label {
            color: $error;

            &:before {
                border-color: $error;
            }
        }
    }
}

// advanced checkboxes
$_label_ac-width: 4rem;

.toggle-checkbox {
    position: relative;

    // hide checkbox
    input[type="checkbox"] {
        @include hide;
        top: 2.2rem; // position for correct browser validate hint placement
        left: 1rem;
    }

    .toggle-checkbox__label {
        cursor: pointer;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        padding-left: $_label_ac-width * 2 + 3rem;
        font-size: $text-c;
        margin: 0;

        span {
            display: block;
        }

        &:before,
        &:after {
            content: attr(data-checked);
            display: inline-block;
            float: left;
            position: absolute;
            top: 0;
            left: 0;
            width: $_label_ac-width;
            text-align: center;
            font-size: $text-c;
            text-transform: uppercase;
            border: 2px solid $back-light;
            padding: 0.2rem;
        }

        &:before {
            content: attr(data-checked);
            border-right: none;
        }

        &:after {
            content: attr(data-unchecked);
            left: $_label_ac-width;
            border-left: none;
            background: $back-light;
            color: $text-default;
        }
    }

    // Checkbox is checked
    input[type="checkbox"]:checked + label {
        &:before {
            background: $back-light;
            color: $text-default;
        }

        &:after {
            background: none;
            color: inherit;
        }
    }

    // Checkbox is disabled
    input[disabled] + label {
        cursor: default;

        &:before {
            border-right: 2px solid $back-light;
        }

        &:after {
            visibility: hidden;
        }
    }
}

// line rows
.line-row {
    padding: 1.4rem 0;
    border-bottom: 1px solid $text-default;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    line-height: 2rem;
}

.line-row--thick {
    border-bottom: $border-width solid $text-default;
}

.line-row--inverted {
    border-top: $border-width solid $line-dark;
}

.line-row--bottom {
    border-bottom: none;

    &.line-row--inverted {
        border-bottom: none;
    }
}
